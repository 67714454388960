import { isNull } from "lodash";
import { inRange } from "./helpers";

const productInformationValidators = {
  loanTerms: {
    fieldName: "loanTerms",
    allowedFalseyValues: [0],
    message: "Either enter a Custom Term or choose a Loan Term",

    required: ({ dependencies }) => !inRange(dependencies.term, 1, 360),
  },
  standardProducts: {
    fieldName: "standardProducts",
    allowedFalseyValues: [0],
    required: ({ dependencies }) => {
      if (
        dependencies.standardProducts?.length > 0 ||
        ((dependencies.specialProducts?.length === 0 ||
          dependencies.specialProducts === null) &&
          (dependencies.standardProducts?.length === 0 ||
            dependencies.standardProducts === null))
      )
        return true;
      return false;
    },
    customValidator: (dataObj, pathValue) => {
      if (
        [2, 3, 4].filter((num) => dataObj.standardProducts.includes(num))
          .length >= 2
      ) {
        return {
          hasError: true,
          message: "Only one of FHA, VA or USDA can be selected.",
        };
      }
      if (
        (pathValue.dependencies.standardProducts?.length === 0 ||
          pathValue.dependencies.standardProducts === null) &&
        (pathValue.dependencies.specialProducts?.length === 0 ||
          pathValue.dependencies.specialProducts === null)
      )
        return { hasError: true, message: "Required field" };

      return { hasError: false };
    },
  },
  specialProducts: {
    fieldName: "specialProducts",
    allowedFalseyValues: [0],
    message: "Required field",
    required: ({ dependencies }) => {
      if (
        dependencies.specialProducts?.length > 0 ||
        ((dependencies.specialProducts?.length === 0 ||
          dependencies.specialProducts === null) &&
          (dependencies.standardProducts?.length === 0 ||
            dependencies.standardProducts === null))
      )
        return true;
      return false;
    },
    customValidator: (dataObj, pathValue) => {
      if (
        (pathValue.dependencies.standardProducts?.length === 0 ||
          pathValue.dependencies.standardProducts === null) &&
        (pathValue.dependencies.specialProducts?.length === 0 ||
          pathValue.dependencies.specialProducts === null)
      )
        return { hasError: true, message: "Required field" };

      return { hasError: false };
    },
  },
  targetTypes: {
    fieldName: "targetTypes",
    message: "Required field",
    required: ({ required }) => required,
  },
  buydownContributorTypes: {
    fieldName: "buydownContributorTypes",
    message: "Required field",
    required: ({ required, dependencies }) => {
      const { buydownType } = dependencies;
      if (buydownType) {
        return required;
      }
      return false;
    },
  },
  targetValue: {
    fieldName: "targetValue",
    required: true,
    message: "Required field",
    allowedFalseyValues: [0],
    customValidator: (data, { dependencies }) => {
      const { targetValue } = data;
      const { targetLabel } = dependencies;
      if (targetLabel) {
        if (isNull(targetValue) || targetValue === "")
          return { hasError: true, message: "Required field" };
        if (targetLabel === "Rate" && !inRange(targetValue, 0, 20)) {
          return { hasError: true, message: "Between 0% - 20%" };
        }
        if (targetLabel === "Price" && !inRange(targetValue, 80, 120)) {
          return { hasError: true, message: "Between 80 - 120" };
        }
      }
      return { hasError: false, message: "" };
    },
  },
  customTerm: {
    fieldName: "customTerm",
    customValidator: (data, { dependencies }) => {
      const { customTerm } = data;

      if (customTerm) {
        if (!inRange(customTerm, 1, 360)) {
          return {
            hasError: true,
            message: "Custom Term must be an integer value from 1 to 360",
          };
        }
      } else if (customTerm === 0) {
        return {
          hasError: true,
          message: "Custom Term must be an integer value from 1 to 360",
        };
      } else if (customTerm === null || customTerm === "") {
        if (
          dependencies.productType === null ||
          dependencies.productType?.length === 0
        ) {
          return {
            hasError: true,
            message: "Either enter a Custom Term or choose a Loan Term",
          };
        }
      }

      return { hasError: false, message: "" };
    },
  },
};

export default productInformationValidators;
