import Session, { IS_BUYSIDE } from "services/Session";

const isBuySide = Session.get(IS_BUYSIDE);

export const BUYSIDE_COLS_PROPS = {
  profitMargin: {
    width: "7.5%",
    minWidth: "60px",
  },
  allRates: {
    width: "7.5%",
    minWidth: "60px",
  },
};

export const FILTERS_INITIAL_STATE = {
  investor: [],
  product: [],
  special: [],
  term: "All",
  flagged: true,
  ...(isBuySide && { lockPeriod: [] }),
};
