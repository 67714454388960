export const OPEN_GLOBAL_ERROR_MODAL =
  "@globalErrorModal/OPEN_GLOBAL_ERROR_MODAL";
export const CLOSE_GLOBAL_ERROR_MODAL =
  "@globalErrorModal/CLOSE_GLOBAL_ERROR_MODAL";

export const setOpenErrorModalAction = (payload) => ({
  type: OPEN_GLOBAL_ERROR_MODAL,
  payload,
});

export const setCloseErrorModalAction = (payload) => ({
  type: CLOSE_GLOBAL_ERROR_MODAL,
  payload,
});
