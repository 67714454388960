import api from "utils/api";
import AuthenticationService from "view/Authentication/AuthenticationService";
import browserHistory from "utils/browserHistory";
import { PATH_LOGIN } from "routes/constants";
import Session, { JWT_TOKEN, IS_LO, USER_TYPE } from "services/Session";

const refreshJwtToken = async () => {
  const refreshed = await AuthenticationService.refreshJWT(
    Session.get(USER_TYPE).userName,
    Session.get(JWT_TOKEN)
  );
  if (refreshed) {
    if (
      refreshed === "Unauthorized" ||
      refreshed.code === "INVALID_ACCESS_TOKEN"
    ) {
      browserHistory.push(PATH_LOGIN);
    } else if (refreshed.token) {
      Session.set(JWT_TOKEN, refreshed.token);
      return true;
    }
  }
  return false;
};

const getClientSettingsV2 = async () => {
  const jwtToken = Session.get(JWT_TOKEN);

  let clientSettings = await api.getClientSettings({ jwtToken });
  if (
    clientSettings &&
    clientSettings.code !== undefined &&
    clientSettings.code === "EPPS2002"
  ) {
    const flag = await refreshJwtToken();
    if (flag) clientSettings = await getClientSettingsV2();
  }
  return clientSettings;
};

export const getClientSettings = async () => {
  let clientSettings = [];
  if (Session.get(IS_LO)) {
    clientSettings = await api.getWebhookLookups("clientsettings");
  } else {
    clientSettings = await getClientSettingsV2();
  }

  return clientSettings;
};

export const getClientSettingsData = async () => {
  try {
    const clientSettings = await getClientSettings();
    let settings = {};
    if (Array.isArray(clientSettings) && clientSettings.length) {
      const { options = {} } = clientSettings[0] || {};
      settings = options;
    }
    return settings;
  } catch (error) {
    return false;
  }
};
