import React from "react";
import moment from "moment";

export const getSummaryData = ({ rateDate, effectiveDate }) => {
  return [
    rateDate
      ? {
          title: "Rate Sheet Date",
          value: moment
            .tz(rateDate, "America/New_York")
            .format("MM/DD/YYYY [at] hh:mm A [ET]"),
        }
      : {},
    effectiveDate
      ? {
          title: "Effective Date",
          value: moment
            .tz(effectiveDate, "America/New_York")
            .format("MM/DD/YYYY [at] hh:mm A [ET]"),
        }
      : {},
  ];
};

export const marginDetailsColumns = [
  {
    property: "name",
    label: "Name",
    width: "70%",
    customRenderer: (data) => {
      const value = data.metaData.rowData.name;
      if (data.metaData.index === 0) {
        return <strong>{value}</strong>;
      }
      return value;
    },
  },
  {
    property: "margin",
    label: "Margin",
    width: "30%",
    customRenderer: (data) => {
      const value = data.metaData.rowData.margin;
      if (data.metaData.index === 0) {
        return <strong>{value}</strong>;
      }
      return value;
    },
  },
];
