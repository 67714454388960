import React from "react";
import { useSelector } from "react-redux";
import { DSLabelValue } from "@elliemae/ds-label-value";
import { getCompensationValues } from "utils/compensationFields";
import Session, { IS_BUYSIDE } from "services/Session";
import { isLOComp } from "../../../../helpers";
import {
  compensationModelSelector,
  compensationValuesSelector,
} from "./selectors";

const CompensationFields = () => {
  const compModel = useSelector(compensationModelSelector);
  const { comp, compVal } = useSelector(compensationValuesSelector);
  const [percentage, value] = getCompensationValues(compModel, comp, compVal);
  const isBuySide = Session.get(IS_BUYSIDE);
  const showComp = isLOComp() && !isBuySide;

  return (
    showComp && (
      <DSLabelValue.Group orientation="horizontal">
        <DSLabelValue>
          <DSLabelValue.Label>Compensation %</DSLabelValue.Label>
          <DSLabelValue.Value>{percentage}</DSLabelValue.Value>
        </DSLabelValue>
        <DSLabelValue>
          <DSLabelValue.Label>Compensation $</DSLabelValue.Label>
          <DSLabelValue.Value>{value}</DSLabelValue.Value>
        </DSLabelValue>
      </DSLabelValue.Group>
    )
  );
};

export default CompensationFields;
