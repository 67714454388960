import React, { useState, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { DSPillV2, DSPillGroupV2 } from "@elliemae/ds-pills";
import { DSInputMask, MASK_TYPES } from "@elliemae/ds-form";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { useLoanInformationField } from "data/useSelectors";
import { setLoanDataTargetValue } from "data/searchResult/actions";
import { isNull } from "lodash";

import { inRange } from "utils/validator/validations/helpers";
import {
  targetDropdownOptions,
  targetRateOptions,
  getTargetLabel,
  getTargetValue,
} from "./helpers";

function FilterPills() {
  const ref = useRef();
  const dispatch = useDispatch();
  const targetSelector = useLoanInformationField("target", "");
  const selectedTargetLabel = useMemo(() => getTargetLabel(targetSelector), []);
  const [targetValue, setTargetValue] = useState(
    getTargetValue(targetSelector)
  );
  const [targetLabel, setTargetLabel] = useState(selectedTargetLabel);
  const [shouldShowInput, setShouldShowInput] = useState(!targetValue);
  const [targetLabelIsOpened, setTargetLabelIsOpened] = useState(false);
  const [targetSelected, setTargetSelected] = useState({
    [selectedTargetLabel.dsId]: true,
  });

  const handleTargetOnClick = (clickedOption) => {
    if (clickedOption.dsId !== targetLabel.dsId) {
      let targetInputValue = "";
      let isShouldShowInput = true;
      if (clickedOption.label === "Target Price") {
        targetInputValue = "100";
        isShouldShowInput = false;
        dispatch(
          setLoanDataTargetValue({
            value: parseFloat(targetInputValue),
            label: "Price",
          })
        );
      }
      setTargetValue(targetInputValue);
      setShouldShowInput(isShouldShowInput);
      setTargetLabel(clickedOption);
      setTargetSelected({ [clickedOption.dsId]: true });
    }
    setTargetLabelIsOpened(false);
  };

  const handleValue = (value) => {
    const { label } = targetLabel;
    const parseValue = value.replace("%", "");
    if (value) {
      if (label === "Target Price" && parseValue >= 80 && parseValue <= 120) {
        dispatch(
          setLoanDataTargetValue({
            value: parseFloat(parseValue),
            label: "Price",
          })
        );
        setShouldShowInput(false);
      } else if (
        label === "Target Rate" &&
        parseValue >= 0 &&
        parseValue <= 20
      ) {
        dispatch(
          setLoanDataTargetValue({
            value: parseFloat(parseValue),
            label: "Rate",
          })
        );
        setShouldShowInput(false);
      }
    }
  };

  const handleTargetValueOnBlur = (el) => {
    const value = el?.target?.value;
    setTargetValue(value);
    handleValue(value);
  };

  const handleTargetValueOnEnter = (el) => {
    if (el.key === "Enter") {
      const value = el?.target?.defaultValue;
      setTargetValue(value);
      handleValue(value);
    }
  };

  const inputMaskComponent = useMemo(
    () => (props) => (
      <DSInputMask
        {...props}
        onBlur={handleTargetValueOnBlur}
        onKeyDown={handleTargetValueOnEnter}
        mask={(number) =>
          MASK_TYPES.NUMBER(targetRateOptions(targetLabel.label))(number)
        }
      />
    ),
    [targetLabel.label]
  );

  let errorMessage;
  const parseValue = targetValue.replace("%", "");

  if (targetLabel) {
    if (isNull(parseValue) || parseValue === "" || parseValue.length === 0) {
      errorMessage = "Required field";
    }

    if (
      targetLabel.label === "Target Rate" &&
      !inRange(parseValue, 0, 20) &&
      parseValue.length !== 0
    ) {
      errorMessage = "Between 0% - 20%";
    }

    if (
      targetLabel.label === "Target Price" &&
      !inRange(parseValue, 80, 120) &&
      parseValue.length !== 0
    ) {
      errorMessage = "Between 80 - 120";
    }
  }

  return (
    <div data-testid="search-results-filter-pills" style={{ display: "flex" }}>
      {/* Updated package of FormLayoutBlock requires string validationMessage, previous react component value no longer accepted */}
      <DSFormLayoutBlockItem hasError validationMessage={errorMessage}>
        <DSPillGroupV2>
          <DSPillV2
            container
            innerRef={ref}
            type="dropdown"
            label={targetLabel.label}
            dropdownProps={{
              isOpened: targetLabelIsOpened,
              options: targetDropdownOptions,
              selectedOptions: targetSelected,
              onClickOutside: () => setTargetLabelIsOpened(false),
              onOptionClick: (_, clickedOption) =>
                handleTargetOnClick(clickedOption),
            }}
            onDropdownClick={() => setTargetLabelIsOpened(true)}
          />
          {shouldShowInput && (
            <DSPillV2
              type="input"
              label={String(targetValue)}
              inputPlaceholder="Enter value"
              inputRender={inputMaskComponent}
            />
          )}
          {!shouldShowInput && (
            <DSPillV2
              type="removable"
              label={String(targetValue)}
              onRemove={() => {
                setTargetValue("");
                setShouldShowInput(true);
              }}
            />
          )}
        </DSPillGroupV2>
      </DSFormLayoutBlockItem>
    </div>
  );
}

export default FilterPills;
