import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { DSPageHeaderV2, PageHeaderTitle } from "@elliemae/ds-page-header";
import { DSButton } from "@elliemae/ds-button";
import { DSSeparator } from "@elliemae/ds-separator";
import { ChevronLeft } from "@elliemae/ds-icons";
import { resetRatesData } from "data/lockConfirm/actionCreators";
import { DataProvider } from "utils/customHooks/useAppData";
import {
  StyledEppsParentView,
  StyledEppsContainerBody,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import FooterButtons from "components/FooterButtons/FooterButtons";
import {
  useLoanInformationSelector,
  useRateSelectorLien,
} from "data/useSelectors";
import { priceUpdate } from "view/LockConfirm/utils";
import { getRateSelectorAction } from "data/lockSummary/actionCreators";
import RatesContainer from "./components/RatesContainer";
import LoanDetails from "../LoanSummaryDetails";
import StyledLockConfirmView from "../../styled";

const CompareRates = ({ handleBack }) => {
  const dispatch = useDispatch();
  const loanInfo = useLoanInformationSelector();
  const [selectedRateData, setSelectedRateData] = useState(null);
  const [selectedPriceId, setSelectedPriceId] = useState("");
  const [disableNewPrice, setdisableNewPrice] = useState(true);
  const { userLender, programName } = useRateSelectorLien(
    ["userLender", "programName"],
    ""
  );
  const program = `${userLender} ${programName}`;

  useEffect(() => {
    return () => dispatch(resetRatesData());
  }, [dispatch, resetRatesData]);

  const title = `Compare Rates: ${program}`;

  const handleSelectRate = (rateData) => {
    const { id, disablePrice, ...newPriceData } = rateData;
    setSelectedRateData(newPriceData);
    setSelectedPriceId(id);
    setdisableNewPrice(disablePrice);
  };

  const onBack = () => {
    setSelectedRateData(null);
    setSelectedPriceId("");
    handleBack();
  };

  const onApply = () => {
    priceUpdate(selectedRateData, loanInfo);
    dispatch(getRateSelectorAction());
    onBack();
  };

  const backarrow = (
    <DSButton
      buttonType="link"
      containerProps={{ "data-testid": "back-id" }}
      icon={<ChevronLeft />}
      onClick={onBack}
    />
  );
  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <StyledLockConfirmView>
            <DSPageHeaderV2
              backarrow={backarrow}
              breadcrumbs={<div>Lock and Confirm Pricing</div>}
              pageTitle={<PageHeaderTitle>{title}</PageHeaderTitle>}
              containerProps={{ style: { marginTop: "5px" } }}
            />
            <LoanDetails isCompareRatesView />
            <DSSeparator
              position="initial"
              type="non-form"
              style={{ marginTop: 0 }}
            />
            <DataProvider value={selectedPriceId}>
              <RatesContainer onSelect={handleSelectRate} />
            </DataProvider>
          </StyledLockConfirmView>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterButtons
        buttons={[
          { type: `secondary`, text: `Back`, onClick: onBack },
          {
            type: `primary`,
            text: `Apply New Price`,
            onClick: onApply,
            disabled: disableNewPrice,
          },
        ]}
      />
    </StyledEppsParentView>
  );
};

CompareRates.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default CompareRates;
