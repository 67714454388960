import api from "utils/api";
import { loanData } from "common/constants/SearchSampleData";
import Session, { JWT_TOKEN } from "services/Session";

class QualificationService {
  async getRates(loanInfo) {
    const Rates = await api.getRates({
      body: JSON.stringify(loanInfo !== undefined ? loanInfo : loanData),
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: "Bearer",
    });
    return Rates;
  }
}

export default new QualificationService();
