import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@elliemae/ds-grid";
import { DSControlledCheckbox } from "@elliemae/ds-controlled-form";
import { useRateSelectorLoan, useHedgeLoanSelector } from "data/useSelectors";
import { setIsLoanHedged } from "data/lockSummary/actions";
import { setLoanDataValue } from "components/Form/formCommons";

const HedgeLoanCheckbox = () => {
  const dispatch = useDispatch();
  const isLoanHedged = useRateSelectorLoan("hedged", "");
  const hedgeLoanIndicator = useHedgeLoanSelector();
  const [hedgeCheckbox, setHedgeCheckbox] = useState();

  useEffect(() => {
    if (hedgeLoanIndicator === undefined || hedgeLoanIndicator === "") {
      setHedgeCheckbox(isLoanHedged);
      dispatch(setIsLoanHedged(isLoanHedged));
    } else {
      setHedgeCheckbox(hedgeLoanIndicator);
    }
  }, [isLoanHedged, hedgeLoanIndicator]);

  const handleOnHedgeChange = () => {
    dispatch(setIsLoanHedged(!hedgeLoanIndicator));
    dispatch(
      setLoanDataValue(
        !hedgeLoanIndicator ? "Y" : "N",
        "loanInformation.hedged"
      )
    );
  };

  return (
    <Grid>
      <DSControlledCheckbox
        data-testid="custom-data-testid"
        checked={hedgeCheckbox ?? false}
        name="hedgeLoan"
        label="Hedge Loan"
        id="hedgeLoan"
        onChange={handleOnHedgeChange}
      />
    </Grid>
  );
};

export default HedgeLoanCheckbox;
