import React from "react";
import {
  useRateSelectorLien,
  useRateSelectorLienInfo,
  useRateSelectorLoanInfo,
} from "data/useSelectors";
import { formatNumberWithCommas } from "utils/shared";
import { DSLabelValue } from "@elliemae/ds-label-value";
import { GridContainer, GridItem } from "@elliemae/ds-grid";
import { InfoCircle } from "@elliemae/ds-icons";
import { DSTooltipV2, tooltipPositions } from "@elliemae/ds-tooltip";
import { setLoanInformation, setConditionalLoanInformation } from "./helpers";
import { StyledDSLabel } from "./styled";

const GeneralLoanInformation = () => {
  const loan = useRateSelectorLoanInfo();
  const lien = useRateSelectorLienInfo();
  const loanInformation = setLoanInformation(lien, loan);
  let conditionalLoanInformation = [];
  const isArm = useRateSelectorLien("arm", "");
  const programTypeName = useRateSelectorLien("programTypeName", []);
  const isHeloc = programTypeName.includes("HELOC");

  if (isArm || isHeloc) {
    conditionalLoanInformation = setConditionalLoanInformation(lien);
  }

  const cellRenderer = (cellInfo) => {
    return (
      <StyledDSLabel>
        <DSLabelValue>
          <DSLabelValue.Label>
            {cellInfo.label}
            {cellInfo.label === "Estimated APR" && (
              <DSTooltipV2
                placement={tooltipPositions.TOP}
                title={`APR is an estimated calculation based on closing costs of $${formatNumberWithCommas(
                  loan.aprClosingCost
                )}`}
                triggerComponent={<InfoCircle color={["brand-primary", 600]} />}
              />
            )}
          </DSLabelValue.Label>
          <DSLabelValue.Value>{cellInfo.value}</DSLabelValue.Value>
        </DSLabelValue>
      </StyledDSLabel>
    );
  };

  return (
    <GridContainer cols={6}>
      {loanInformation.map((cellInfo, index) => (
        <GridItem
          data-testid={`${
            index === 0 ? "GeneralLoanInformation-all-components" : ""
          }`}
          span={1}
        >
          {cellRenderer(cellInfo)}
        </GridItem>
      ))}
      {(isArm || isHeloc) &&
        (conditionalLoanInformation
          ? conditionalLoanInformation.map((cellInfo, index) => (
              <GridItem
                data-testid={`${
                  index === 0
                    ? "GeneralLoanInformationIsArm-all-components"
                    : ""
                }`}
                span={1}
              >
                {cellRenderer(cellInfo)}
              </GridItem>
            ))
          : null)}
    </GridContainer>
  );
};

export default GeneralLoanInformation;
