export const updateEncompassElementsColumnsDef = [
  {
    property: "fieldName",
    label: "Field",
    visible: true,
  },
  {
    property: "oldValue",
    label: "Old Value",
    visible: true,
  },
  {
    property: "newValue",
    label: "New Value",
    visible: true,
  },
];
