import React from "react";
import { DSTruncatedTooltipText } from "@elliemae/ds-truncated-tooltip-text";
import { PopperPositions as Positions } from "@elliemae/ds-popper";
import { numberFieldRenderer } from "./helpers";

export const columns = [
  {
    accessor: "period",
    Header: () => (
      <DSTruncatedTooltipText
        containerProps={{ id: "cell-annual-income-id" }}
        tooltipPlacement={Positions.TOP}
        value=""
      />
    ),
  },
  {
    Header: "30 DAYS",
    Cell: ({ row, handleChange }) =>
      numberFieldRenderer("30Days", row.original, handleChange),
  },
  {
    Header: "60 DAYS",
    Cell: ({ row, handleChange }) =>
      numberFieldRenderer("60Days", row.original, handleChange),
  },
  {
    Header: "90 DAYS+",
    Cell: ({ row, handleChange }) =>
      numberFieldRenderer("90Days", row.original, handleChange),
  },
];
