import React from "react";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { DSRadio } from "@elliemae/ds-form";
import { DSTooltipV3, tooltipPositions } from "@elliemae/ds-tooltip";
import { DSButton } from "@elliemae/ds-button";
import {
  Comments,
  EditPencil,
  ArrowblockN,
  ArrowblockS,
} from "@elliemae/ds-icons";
import { truncateText } from "utils/helpers";
import { StyledCell, StyledJustifyContentItem } from "../styled";

const ApprovalTooltip = ({ approvedBy, approvedReason }) => {
  const approvedByText = approvedBy?.trim() && (
    <p style={{ fontWeight: "bold", margin: "0" }}>Approved by {approvedBy}</p>
  );
  const approvedReasonText = approvedReason?.trim() && (
    <p style={{ margin: "0" }}>{truncateText(approvedReason, 256)} </p>
  );
  const tooltipText = (approvedByText || approvedReasonText) && (
    <>
      {approvedByText}
      {approvedReasonText}
    </>
  );
  return tooltipText ? (
    <span
      style={{
        paddingLeft: "8px",
      }}
    >
      <DSTooltipV3
        startPlacementPreference={tooltipPositions.TOP}
        text={tooltipText}
        withoutPortal={false}
      >
        <Comments
          color={["brand-primary", "600"]}
          size="s"
          style={{ margin: "0 auto" }}
        />
      </DSTooltipV3>
    </span>
  ) : null;
};

ApprovalTooltip.defaultProps = {
  approvedBy: "",
  approvedReason: "",
};

ApprovalTooltip.propTypes = {
  approvedBy: string,
  approvedReason: string,
};

export const NameCell = ({ row }) => {
  const { approvedBy, approvedReason, type, description } = row?.original;

  return (
    <StyledCell
      textXl={!row.index}
      textBold={
        !row.index || type === "groupTitle" || description === "FINAL PRICE"
      }
      padLeft={
        row.index &&
        type === "regular" &&
        !(row.original.description === "FINAL PRICE")
      }
    >
      {description}
      <ApprovalTooltip
        approvedBy={approvedBy}
        approvedReason={approvedReason}
      />
    </StyledCell>
  );
};

NameCell.defaultProps = {
  row: {
    original: {},
    index: 0,
  },
};

NameCell.propTypes = {
  row: shape({
    original: shape({
      approvedBy: string,
      approvedReason: string,
      type: string,
      description: string,
    }),
    index: number,
  }),
};

export const CellArrowUpDown = ({
  row: { index, original },
  column: { accesor = null },
}) => {
  const isFinalPriceOrRate = index === 0;
  const namePriceOrRate = accesor.replace("current", "").toLowerCase();
  const variation = original[accesor] - original[namePriceOrRate];

  return (
    <StyledCell
      textXl={isFinalPriceOrRate}
      textBold={isFinalPriceOrRate}
      rightAlign
    >
      {isFinalPriceOrRate && (
        <>
          {variation > 0 && (
            <ArrowblockN data-testid={`arrow-up-${namePriceOrRate}`} />
          )}
          {variation < 0 && (
            <ArrowblockS data-testid={`arrow-down-${namePriceOrRate}`} />
          )}
        </>
      )}
      {original?.type === "regular" && (original[accesor] || "-")}
    </StyledCell>
  );
};

CellArrowUpDown.defaultProps = {
  row: {
    original: {},
    index: 0,
  },
  column: {},
};

CellArrowUpDown.propTypes = {
  row: shape({
    original: shape({
      type: string,
    }),
    index: number,
  }),
  column: shape({
    accesor: string,
  }),
};

export const StandardCell = ({ row, column }) => (
  <StyledCell
    textXl={row.index === 0}
    textBold={row.index === 0 || row.original.description === "FINAL PRICE"}
    rightAlign
  >
    {row.original?.type === "regular" && (row.original[column?.accesor] || "-")}
  </StyledCell>
);

StandardCell.defaultProps = {
  row: {
    original: {},
    index: 0,
  },
  column: {},
};

StandardCell.propTypes = {
  row: shape({
    original: shape({
      type: string,
    }),
    index: number,
  }),
  column: shape({
    accesor: string,
  }),
};

export const EditIconCell = ({ row, isRowSelected, cell, column }) => {
  const { editable, name, handlerName } = row?.original || {};
  const { onEditClick } = column;
  return editable ? (
    <DSButton
      aria-label={`Edit ${name}`}
      buttonType="text"
      leftIcon={<EditPencil />}
      size="s"
      onClick={() => onEditClick(handlerName)}
      tabIndex={isRowSelected ? 0 : -1}
      innerRef={cell?.ref}
    />
  ) : null;
};

EditIconCell.defaultProps = {
  row: {
    original: {},
    index: 0,
  },
  column: {},
  isRowSelected: false,
  cell: {},
};

EditIconCell.propTypes = {
  row: shape({
    original: shape({
      editable: bool,
      name: string,
      handlerName: string,
    }),
    index: number,
  }),
  column: shape({
    onEditClick: func,
  }),
  isRowSelected: bool,
  cell: shape({
    ref: oneOfType([func, shape({})]),
  }),
};

export const PriceChangeSelectCell = ({ row, column }) => {
  const { onSelectPrice, selectedPrice, accesor } = column;
  return (
    <>
      {!row.index ? (
        <StyledJustifyContentItem
          justifyContent="flex-end"
          onClick={() => onSelectPrice(accesor)}
        >
          <DSRadio
            style={{ paddingBottom: "0px" }}
            checked={selectedPrice === accesor}
            containerProps={{ style: { paddingBottom: "0px" } }}
          />
          <CellArrowUpDown row={row} column={column} />
        </StyledJustifyContentItem>
      ) : (
        <StandardCell row={row} column={column} />
      )}
    </>
  );
};
PriceChangeSelectCell.defaultProps = {
  row: {
    original: {},
    index: 0,
  },
  column: {},
};

PriceChangeSelectCell.propTypes = {
  row: shape({
    index: number,
  }),
  column: shape({
    onSelectPrice: func,
    selectedPrice: string,
    accesor: string,
  }),
};
