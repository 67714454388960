import React from "react";
import { func } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { WarningTriangle } from "@elliemae/ds-icons";
import { DSButtonV2, BUTTON_TYPES } from "@elliemae/ds-button";
import { StyledEmptyCard, StyledEmptyText, StyledCenteredText } from "./styled";

const EmptyCard = ({ handleAddCard }) => {
  return (
    <StyledEmptyCard>
      <Grid style={{ textAlign: "center" }}>
        <StyledCenteredText>
          <WarningTriangle size="xxl" color={["neutral", 400]} />
        </StyledCenteredText>
      </Grid>
      <Grid style={{ textAlign: "center" }} pt="16px">
        <StyledEmptyText>No Items Found</StyledEmptyText>
      </Grid>
      <Grid pt="16px">
        <StyledCenteredText>
          <DSButtonV2 buttonType={BUTTON_TYPES.OUTLINE} onClick={handleAddCard}>
            Add An Item
          </DSButtonV2>
        </StyledCenteredText>
      </Grid>
    </StyledEmptyCard>
  );
};

EmptyCard.defaultProps = {
  handleAddCard: () => {},
};

EmptyCard.propTypes = {
  handleAddCard: func,
};

export default EmptyCard;
