export const handleReadOnlyLiquidAsset = ({ customDependencies }, index) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue = initialBorrowers[index]?.borrowerFinanancial?.liquidAsset;
  return !["", null, undefined, 0].includes(getValue);
};

export const handleReadOnlyFirstName = ({ customDependencies }, index) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue = initialBorrowers[index]?.firstName;
  return !["", null, undefined, 0].includes(getValue);
};

export const handleReadOnlyLastName = ({ customDependencies }, index) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue = initialBorrowers[index]?.lastName;
  return !["", null, undefined, 0].includes(getValue);
};

export const handleReadOnlyRetirementAsset = (
  { customDependencies },
  index
) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue =
    initialBorrowers[index]?.borrowerFinanancial?.retirementAsset;
  return !!(getValue > 0);
};

export const handleReadOnlyAnualIncome = ({ customDependencies }, index) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue = initialBorrowers[index]?.borrowerFinanancial?.income;
  return !!(getValue > 0);
};

export const handleReadOnlyMonthlyDebt = ({ customDependencies }, index) => {
  const { initialBorrowers = [] } = customDependencies;
  const getValue = initialBorrowers[index]?.borrowerFinanancial?.monthlyDebt;
  return !!(getValue > 0);
};
