import styled from "styled-components";

export const GuidelineLinkStyled = styled.div`
  margin: ${({ showInAccordeon }) =>
    showInAccordeon ? "0 0 0 16px" : "0 25px 0 25px"};
`;

export const NoProgramDetailsStyled = styled.div`
  margin-top: 48px;
  text-align: center;
`;

export const StyledProductNotesList = styled.div`
  ul {
    padding-right: 20px;
  }
`;

export const PLinkContainer = styled.p`
  color: #1e79c2;
  cursor: pointer;
  text-decoration: underline;
  margin: ${({ showInAccordeon }) => (showInAccordeon ? "0" : "revert")};
`;
