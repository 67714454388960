import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSControlledDateTimePicker } from "@elliemae/ds-controlled-form";
import { searchFormActions } from "store/searchForm";
import { useLoanDataFieldSelector } from "../utils/customHooks";
import {
  getValidator,
  customOnChange,
  formatDateTime,
  formatTime,
  formatDate,
} from "../utils/common";
import { getDefaultFocusedDate } from "./utils";

const DateTimePickerForm = ({
  name,
  defaultValue,
  dateFormat,
  labelText,
  required,
  dependencies,
  feedbackMessage,
  pathValue,
  ...rest
}) => {
  const dispatch = useDispatch();
  let customDependencies = useLoanDataFieldSelector(dependencies);
  if (name === "effectiveDate") {
    customDependencies = dependencies;
  }
  const fieldValue = useLoanDataFieldSelector(pathValue).value;
  const [error, setError] = useState(false);
  const [validator, setValidator] = useState({
    hasError: false,
    errorMessage: "",
    required,
  });

  const [localDateTimeValue, setLocalDateTimeValue] = useState(
    formatDateTime(fieldValue || defaultValue)
  );
  const [localDateValue, setLocalDateValue] = useState(
    formatDate(fieldValue || defaultValue)
  );
  const [localTimeValue, setLocalTimeValue] = useState(
    formatTime(fieldValue || defaultValue)
  );

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (newValidator?.errorMessage !== validator?.errorMessage) {
      setValidator(newValidator);
    }
  };

  useEffect(() => {
    handleValidator(fieldValue);
  }, [localDateTimeValue, customDependencies]);

  useEffect(() => {
    if (validator.hasError !== undefined && error !== validator.hasError) {
      const { hasError } = validator;
      setError((prev) => !prev);
      dispatch(searchFormActions.setFormHasError({ name, hasError }));
    }
  }, [validator]);

  useEffect(() => {
    return () => {
      if (validator.hasError)
        dispatch(searchFormActions.setFormHasError({ name, hasError: false }));
    };
  }, [error]);

  const validateEvent = (event) => {
    const { replace } = rest;
    const customEvent = rest[event];
    const eventValues = {
      value: localDateTimeValue,
      pathValue,
      customEvent,
      replace,
      customDependencies,
    };
    return customOnChange({ event, ...eventValues });
  };

  const handleOnDateChange = (newDate) => {
    const strDateTime = `${newDate} ${localTimeValue}`;
    setLocalDateValue(strDateTime);
    const { onChange } = rest;
    onChange({ value: strDateTime, pathValue });
  };

  const handleOnTimeChange = (newTime) => {
    const strDateTime = `${localDateValue} ${newTime}`;
    setLocalTimeValue(strDateTime);
    const { onChange } = rest;
    onChange({ value: strDateTime, pathValue });
  };

  const handleOnDateTimeChange = (newDateTime) => {
    const [prevDate] = localDateTimeValue.split(" ");
    const [newDate, newTime] = newDateTime.split(" ");
    const isDefaultTime =
      newDate &&
      prevDate !== newDate &&
      !newDate.includes("_") &&
      !newTime.includes("12:00 AM");
    const actualDateTime = isDefaultTime ? `${newDate} 12:00 AM` : newDateTime;
    setLocalDateTimeValue(actualDateTime);
    const { onChange } = rest;
    onChange({ value: actualDateTime, pathValue });
  };

  return (
    <DSFormLayoutBlockItem
      feedbackMessage={feedbackMessage || validator.feedbackMessage}
      validationMessage={validator.errorMessage}
      hasError={validator.hasError}
      inputID={`epps-field-id-${name}`}
      label={labelText}
    >
      <DSControlledDateTimePicker
        {...rest}
        required={required}
        dateTime={localDateTimeValue}
        minWidth="100%"
        onDateChange={(newDate) => handleOnDateChange(newDate)}
        onTimeChange={(newTime) => handleOnTimeChange(newTime)}
        onDateTimeChange={(newDateTime) => handleOnDateTimeChange(newDateTime)}
        onCalendarOpenFocusedDay={getDefaultFocusedDate(localDateValue)}
        // custom props
        {...validateEvent("onChange")}
        {...validateEvent("onDateChange")}
        {...validateEvent("onTimeChange")}
        {...validateEvent("onDateTimeChange")}
        {...validateEvent("onBlur")}
        {...validateEvent("onKeyUp")}
      />
    </DSFormLayoutBlockItem>
  );
};

DateTimePickerForm.defaultProps = {
  name: "",
  defaultValue: null,
  dateFormat: "MM/DD/YYYY",
  labelText: "Select Date",
  required: false,
  dependencies: [],
  feedbackMessage: "MM/DD/YYYY",
  pathValue: "",
};

DateTimePickerForm.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  dateFormat: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  dependencies: PropTypes.instanceOf(Array),
  feedbackMessage: PropTypes.string,
  pathValue: PropTypes.string,
};

export default memo(DateTimePickerForm);
