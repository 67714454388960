const INITIAL_STATE = {
  tab: "SearchForProducts",
  tabId: "/search-results",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_TAB_KEY":
      return {
        ...state,
        tabId: action.payload,
      };
    default:
      return state;
  }
};
