import { put, select, takeLatest } from "redux-saga/effects";
import {
  lookupsSelector,
  searchFormSelector,
  loanDataFieldSelector,
} from "store/selectors";
import { initialDepends } from "./utils/constants";
import { parseInitialValues, formValidations } from "./utils/helpers";
import { searchFormActions } from "./index";

const { setFormValidations, getFormValidationsAction } = searchFormActions;

// ----- WORKERS ------

/* Getting the initial values from the store and setting them to the form. */
function* getInitialValues(initialValues = {}) {
  const formInitialValues = yield select(searchFormSelector("initialValues"));
  if (Object.keys(formInitialValues).length) {
    const parsingInitialValueResult = parseInitialValues(initialValues);
    yield put(searchFormActions.setInitialValues(parsingInitialValueResult));
  }
}

/* Getting field validation to set a flow in the form and being able to display or hide some fields. */
function* getFormValidation() {
  const lookupsLoanTerms = yield select(lookupsSelector("loanTerms"));
  const initialValues = yield select(loanDataFieldSelector(initialDepends));
  const validations = formValidations({ lookupsLoanTerms, ...initialValues });
  yield getInitialValues(initialValues);
  yield put(setFormValidations(validations));
}

// ----- WATCHERS ------

/* A watcher function that is listening to for the action `getFormValidationsAction` to be dispatched. */
export default function* watchers() {
  yield takeLatest(getFormValidationsAction.type, getFormValidation);
}
