import api from "utils/api";
import Common from "common/services/Common";
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_VALIDATE,
  JWT_TOKEN,
  USER_TYPE,
} from "services/Session";
import Rights from "common/services/Rights";

class AdjustmentDetailsService {
  async getAdjustmentDetails(
    loanId,
    lienPosition,
    programId,
    rateDataId,
    eppsUserName
  ) {
    const isBuyside = !Session.get(IS_BUYSIDE) || null;
    const isSellside = !Session.get(IS_SELLSIDE) || null;
    const isValidate = !Session.get(IS_VALIDATE) || null;
    const showLoanOfficer =
      Rights.AssignLoanOfficer && (isBuyside || isSellside || isValidate);
    const bodyData = showLoanOfficer
      ? JSON.stringify({
          loanId,
          lienPosition,
          specificRateDataID: rateDataId,
          programId,
          eppsUserName,
        })
      : JSON.stringify({
          loanId,
          lienPosition,
          specificRateDataID: rateDataId,
          programId,
        });
    let details = {};
    const user = Session.get(USER_TYPE);
    if (user && user.userType === "StandAlone") {
      details = await api.getAdjustmentDetails(programId, {
        body: bodyData,
        customToken: { value: Session.get(JWT_TOKEN) },
        tokenType: "Bearer",
      });
    } else {
      details = await api.getWebhookData(
        {
          headers: { "X-Elli-PAT": await Common.getPATToken() },
          body: bodyData,
        },
        await Common.getOriginId(),
        "AdjustmentDetails"
      );
    }

    return details;
  }
}

export default new AdjustmentDetailsService();
