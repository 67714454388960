import { useEffect, useState } from "react";
import {
  useLookupOptions,
  useLoanDataFieldSelector,
} from "components/Form/utils/customHooks";
import { isEmpty } from "utils/shared";
import { DOCUMENTATION_LEVEL } from "./dependencies";

export const useDocumentationTypes = () => {
  const [asset, setAsset] = useState();
  const [income, setIncome] = useState();
  const [employment, setEmployment] = useState();
  const [documentationValue, setDocumentationValue] = useState();
  const documentationTypes = useLookupOptions("documentationTypes");
  const dependencies = useLoanDataFieldSelector(DOCUMENTATION_LEVEL);

  useEffect(() => {
    if (!isEmpty(documentationTypes)) {
      const {
        documentationLevel,
        selfEmployedBorrower,
        selfEmployedCoBorrower,
      } = dependencies;
      const documentationType = documentationTypes.filter(
        (type) => type.documentationId === documentationLevel
      );
      const isDocumentationType = isEmpty(documentationType)
        ? documentationTypes.filter((type) => type.documentationId === 1)
        : documentationType;
      if (!isEmpty(isDocumentationType)) {
        const parseEmployment =
          selfEmployedBorrower || selfEmployedCoBorrower ? 2 : 1;
        setEmployment(
          isDocumentationType[0].employment === 100 ? parseEmployment : 0
        );
        setIncome(isDocumentationType[0].income);
        setAsset(isDocumentationType[0].assets);
      }
      if (isEmpty(documentationType)) {
        setDocumentationValue(isDocumentationType[0].documentationId);
      }
    }
  }, [documentationTypes]);

  return {
    asset,
    income,
    employment,
    documentationValue,
    documentationTypes,
  };
};
