import styled from "styled-components";

export const StyledBorrowerTitles = styled.span`
  padding-bottom: ${({ theme: { space = {} } }) => space.xs};
  font-weight: ${({ theme: { fontWeights = {} } }) => fontWeights.semibold};
`;

export const StyledRowsAlignment = styled.div`
  width: 100%;
  text-align: right;
`;
