export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_FIELD = "UPDATE_FIELD";
export const CLEAN_ALL_ERRORS = "CLEAN_ALL_ERRORS";
export const SET_EXTRA_FIELDS = "SET_EXTRA_FIELDS";

export const setExtraFields = (payload) => ({
  type: SET_EXTRA_FIELDS,
  payload,
});

export const cleanAllErrors = () => ({
  type: CLEAN_ALL_ERRORS,
});
