export const GET_RELOCK_DATA_SUCCESS = "@lockConfirm/GET_RELOCK_DATA_SUCCESS";
export const GET_RELOCK_DATA_SUCCESS_ALL =
  "@lockConfirm/GET_RELOCK_DATA_SUCCESS_ALL";
export const GET_RATES_DATA = "@lockConfirm/GET_RATES_DATA";
export const GET_RATES_DATA_SUCCESS = "@lockConfirm/GET_RATES_DATA_SUCCESS";
export const RESET_RATES_DATA = "@lockConfirm/RESET_RATES_DATA";
export const RESET_GRID_DATA = "@lockConfirm/RESET_GRID_DATA";
export const SET_VALIDATE_SUBMIT_BUTTON_STATUS =
  "@lockSummary/SET_VALIDATE_SUBMIT_BUTTON_STATUS";

export const setRelockDataSuccessAction = (payload) => ({
  type: GET_RELOCK_DATA_SUCCESS,
  payload,
});

export const setAllRelockDataSuccessAction = (payload) => ({
  type: GET_RELOCK_DATA_SUCCESS_ALL,
  payload,
});

export const getRatesDataAction = () => ({
  type: GET_RATES_DATA,
});

export const getRatesDataSuccessAction = (payload) => ({
  type: GET_RATES_DATA_SUCCESS,
  payload,
});

export const resetGridDataAction = (payload) => ({
  type: RESET_GRID_DATA,
  payload,
});

export const resetRatesDataAction = {
  type: RESET_RATES_DATA,
};
export const setValidateSubmitButtonEnabledStatus = (payload) => ({
  type: SET_VALIDATE_SUBMIT_BUTTON_STATUS,
  payload,
});
