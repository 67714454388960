import React from "react";
import { string } from "prop-types";
import { InfoCircle } from "@elliemae/ds-icons";
import { NoProgramDetailsStyled, StyledProductNotesList } from "./styled";

const Notes = ({ program, notes }) => (
  <div>
    {notes ? (
      <React.Fragment>
        {program && (
          <span
            data-testid="Notes-all-components"
            style={{ marginLeft: "16px" }}
          >
            {program}
          </span>
        )}
        <StyledProductNotesList
          data-testid="program-notes"
          dangerouslySetInnerHTML={{ __html: notes }}
        />
      </React.Fragment>
    ) : (
      <NoProgramDetailsStyled>
        <InfoCircle
          size="xxl"
          color={["neutral", "500"]}
          style={{ margin: "0 auto" }}
        />
        <p>There are no details for this program.</p>
      </NoProgramDetailsStyled>
    )}
  </div>
);

Notes.defaultProps = { program: "", notes: "" };

Notes.propTypes = { program: string, notes: string };

export default Notes;
