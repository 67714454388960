import React, { useEffect, useState } from "react";
import { arrayOf, string } from "prop-types";
import { DSTooltipV3, tooltipPositions } from "@elliemae/ds-tooltip";
import { DataTable } from "@elliemae/ds-data-table";
import { useLookup } from "data/useSelectors";
import { DSButton } from "@elliemae/ds-button";
import { InfoCircleFill } from "@elliemae/ds-icons";
import { ineligibilityGridColumns } from "./utils/constants";
import { TooltipGrid } from "./TooltipGrid";

const ExpandableIneligibilityGrid = (props) => {
  const { data, programID } = props;
  const [rowData, setRowData] = useState([]);

  const purposes = useLookup("untransformedLoanUsage", []);
  const propertyTypes = useLookup("untransformedPropertyTypes", []);
  const propertyUses = useLookup("untransformedPropertyUse", []).filter(
    ({ key }) => !key.match("Pending Sale/Sold")
  );

  useEffect(() => {
    if (data?.length) {
      const mappingData = data.map((row, index) => ({
        ...row,
        id: `${programID}-${index}`,
      }));
      setRowData(mappingData);
    }
  }, []);

  return (
    <div style={{ padding: "33px 16px 44px 16px" }}>
      <span style={{ float: "left", width: "auto" }}>
        <DSButton
          buttonType="text"
          labelText="Ineligibility Grid"
          size="s"
          style={{
            textTransform: "none",
            color: "black",
            padding: "8px",
            fontSize: "16px",
            marginBottom: "10px",
          }}
        />
      </span>
      <span style={{ padding: "left", color: "#1e79c2" }}>
        <DSTooltipV3
          withoutArrow
          zIndex={999}
          startPlacementPreference={tooltipPositions.TOP_START}
          text={
            <TooltipGrid items={{ purposes, propertyUses, propertyTypes }} />
          }
        >
          <DSButton
            buttonType="text"
            icon={<InfoCircleFill />}
            size="s"
            style={{
              textTransform: "none",
              color: "black",
              padding: "8px",
              fontSize: "16px",
              marginBottom: "10px",
            }}
          />
        </DSTooltipV3>
      </span>
      <DataTable
        autoHeight
        paginated
        data={rowData}
        colsLayoutStyle="auto"
        columns={ineligibilityGridColumns}
        rowKey="id"
      />
    </div>
  );
};

ExpandableIneligibilityGrid.defaultProps = {
  data: [],
  programID: "",
};

ExpandableIneligibilityGrid.propTypes = {
  data: arrayOf(),
  programID: string,
};

export default ExpandableIneligibilityGrid;
