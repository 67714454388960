import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import browserHistory from "utils/browserHistory";
import Login from "view/Login";
import SearchResult from "view/SearchResult";
import LockConfirmWrapper from "view/LockConfirm";
import LockSummary from "view/LockSummary";
import LockExtension from "view/LockExtension";
import Landing from "view/Landing";
import SearchForm from "view/SearchForm";
import { TooltipTextProvider } from "@elliemae/ds-truncated-tooltip-text";
import { StyledEppsContainerBody } from "view/global/styles/styled";
import Session, {
  IS_BUYSIDE,
  IS_SELLSIDE,
  IS_LO,
  IS_JWT_TOKEN,
  IS_SECONDARY_LOCK,
  IS_LOCK_REQUEST_FORM_LOCK_EXTENSION,
} from "services/Session";
import {
  PATH_LOGIN,
  PATH_LOCK_SUMMARY,
  PATH_SEARCH_RESULTS,
  PATH_SEARCH_FOR_PRODUCTS,
  PATH_LOCK_EXTENSION,
} from "routes/constants";

const Routes = () => {
  const isBuyside = Session.get(IS_BUYSIDE);
  const isSellside = Session.get(IS_SELLSIDE);
  const isSecondaryLock = Session.get(IS_SECONDARY_LOCK);
  const isLockRequestForm = Session.get(IS_LOCK_REQUEST_FORM_LOCK_EXTENSION);
  const isLO = Session.get(IS_LO);
  const isJWTToken = Session.get(IS_JWT_TOKEN);

  const getInitialRedirect = () => {
    if (isSecondaryLock || isLockRequestForm) {
      return <Redirect to={PATH_LOCK_EXTENSION} />;
    }

    return <Redirect to={PATH_SEARCH_RESULTS} />;
  };

  const checkLogin = (Component) =>
    !isLO && !isJWTToken ? <Redirect to={PATH_LOGIN} /> : <Component />;

  return (
    <StyledEppsContainerBody>
      <Switch>
        <Route
          exact
          name="SearchForProducts"
          path={PATH_SEARCH_FOR_PRODUCTS}
          component={() => checkLogin(SearchForm)}
        />
        <Route
          exact
          name="LockSummary"
          path={PATH_LOCK_SUMMARY}
          component={() =>
            checkLogin(
              isBuyside || isSellside ? LockConfirmWrapper : LockSummary
            )
          }
        />
        <Route
          exact
          name="LockExtension"
          path={PATH_LOCK_EXTENSION}
          component={() => checkLogin(LockExtension)}
        />
        <Route
          exact
          name="SearchResults"
          path={PATH_SEARCH_RESULTS}
          component={() => checkLogin(SearchResult)}
        />
        {getInitialRedirect()}
      </Switch>
    </StyledEppsContainerBody>
  );
};

export const RouteContainer = () => {
  return (
    <TooltipTextProvider>
      <Router history={browserHistory}>
        <Switch>
          <Route exact name="LoginPage" path={PATH_LOGIN} component={Login} />
          <Route name="Landing" path="/" component={Landing} />
        </Switch>
      </Router>
    </TooltipTextProvider>
  );
};

export default Routes;
