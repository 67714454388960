import { get } from "lodash";
import { recursivePropAccess } from "components/Form/InputMaskForm/helpers";

// TODO this file needs to be removed once EPPS store is refactorized
export const setLoanDataValue = (value, route) => (dispatch, state) => {
  const loanData = { ...(get(state(), "EPPS.loanInfo") || {}) };
  dispatch({
    type: "UPDATE_LOANINFO",
    payload: recursivePropAccess(loanData, route, value),
  });
};
