export const TWELVE_INTEGERS_NO_DECIMAL_PLACES = {
  allowNegative: true,
  allowDecimal: false,
  integerLimit: 14,
  prefix: "$",
};

export const DECIMAL_PLACES = {
  allowNegative: true,
  allowDecimal: true,
  integerLimit: 9,
  decimalLimit: 2,
  prefix: "$",
};

export const DECIMAL_PLACES_NOT_PREFIX = {
  allowNegative: true,
  allowDecimal: true,
  integerLimit: 9,
  decimalLimit: 2,
  prefix: "",
};

export const THREE_DECIMAL_PLACES = {
  allowNegative: true,
  allowDecimal: true,
  integerLimit: 9,
  decimalLimit: 3,
  prefix: "$",
};

export const NOT_DECIMAL_PLACES = {
  allowNegative: true,
  integerLimit: 9,
  decimalLimit: 0,
  prefix: "$",
};

export const NOT_NEGATIVE_VALUE = {
  integerLimit: 9,
  decimalLimit: 0,
  prefix: "$",
};

export const NOT_NEGATIVE_VALUE_EIGHT_DIGITS = {
  integerLimit: 8,
  decimalLimit: 0,
  prefix: "$",
};

export const NOT_NEGATIVE_DECIMAL_PLACES = {
  allowNegative: false,
  allowDecimal: true,
  integerLimit: 9,
  decimalLimit: 2,
  prefix: "$",
};

export const NEGATIVE_VALUE = {
  allowNegative: true,
  integerLimit: 9,
  prefix: "$",
};

export const PERCENT_VALUES = {
  allowNegative: true,
  allowDecimal: true,
  integerLimit: 9,
  decimalLimit: 3,
  prefix: "",
};

export const LTV_PERCENT = {
  ...PERCENT_VALUES,
  allowNegative: false,
  integerLimit: 3,
};

export const NO_DECIMAL_NO_PREFIX = {
  prefix: "",
  allowDecimal: false,
  allowNegative: true,
};

export const NO_DECIMAL_NO_PREFIX_NOT_NEGATIVE = {
  prefix: "",
  allowDecimal: false,
  allowNegative: false,
};

export const INTEGER_LIMIT_ONE = {
  prefix: "",
  suffix: "",
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 1,
};
