import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { formatDate } from "services/helpers";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSInputText } from "@elliemae/ds-form-input-text";
import { Grid } from "@elliemae/ds-grid";

const CalculatedExtensionDetails = ({ lockDetails, lockDateCalculation }) => {
  const details = useMemo(() => {
    return [
      {
        label: "Current Lock Expiration Date",
        value: formatDate(lockDetails?.lockExpirationDate, "Calendar") || "",
      },
      {
        label: "Price Adjustments",
        value: lockDateCalculation.priceAdjustment || "",
      },
      {
        label: "New Lock Expiration Date",
        value:
          formatDate(lockDateCalculation.lockExpirationDate, "Calendar") || "",
      },
      { label: "# Days", value: lockDateCalculation.numberOfDays || "" },
    ];
  }, [lockDetails, lockDateCalculation]);

  return (
    details && (
      <Grid cols={[1 / 2, 1 / 2]} alignContent="flex-start">
        {details
          .filter(({ value }) => value)
          .map(({ label, value }, index) => (
            <DSFormLayoutBlockItem
              key={label}
              m="0 0 s 0"
              fitContent
              label={label}
              inputID={`DetailItem_${index}`}
            >
              <DSInputText id={`DetailItem_${index}`} value={value} readOnly />
            </DSFormLayoutBlockItem>
          ))}
      </Grid>
    )
  );
};

CalculatedExtensionDetails.propTypes = {
  lockDetails: PropTypes.shape({
    lockExpirationDate: PropTypes.string.isRequired,
  }).isRequired,
  lockDateCalculation: PropTypes.shape({
    numberOfDays: PropTypes.number.isRequired,
    priceAdjustment: PropTypes.number.isRequired,
    lockExpirationDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default CalculatedExtensionDetails;
