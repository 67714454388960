import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { ComboBoxForm, InputMaskForm } from "components/Form";
import { searchFormActions } from "store/searchForm";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import { setLoanDataValue } from "store/searchForm/actions";
import { useLookupOptions } from "components/Form/utils/customHooks";
import { hideAllButtonComboboxStyles } from "../../utils/handlers";
import * as dependencies from "../../utils/dependencies";
import * as masks from "../../../../utils/constants";

const LoanTermsField = ({ handleSetLoanDataValue }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const termRange = useLookupOptions("untransformedTermRange") || [];

  const dispatch = useDispatch();

  const handleLoanTermValue = (data) => {
    const { value, pathValue, options: lookupsLoanTerms } = data;

    if (value && value.length > 0) {
      dispatch(setLoanDataValue("", "loanInformation.term"));
      const arrayHasHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => elem.heloc);

      const arrayHasNonHELOC = lookupsLoanTerms
        .filter((elem) => value.includes(elem.value))
        .some((elem) => !elem.heloc);

      if (arrayHasHELOC && arrayHasNonHELOC) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "HELOC can not be selected with any other loan term.",
            onCloseCallback: () => setIsMenuOpen(true),
          })
        );
      }
      if (value.length > 8) {
        setIsMenuOpen(false);
        value.pop();
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "Please specify 8 or fewer loan terms.",
            onCloseCallback: () => setIsMenuOpen(true),
          })
        );
      }
    }

    handleSetLoanDataValue({ value, pathValue });
    dispatch(
      searchFormActions.setIsHELOC({ productType: value, lookupsLoanTerms })
    );
  };

  const handleCustomTermChange = (data) => {
    const { value, pathValue } = data;

    if (
      !Number.isNaN(Number.parseInt(value, 10)) &&
      value >= 1 &&
      value <= 360
    ) {
      dispatch(setLoanDataValue([], "productType"));
      dispatch(setLoanDataValue(value, pathValue));

      const matchingLoanTerms = termRange.filter(
        (elem) => value >= elem.minTerm && value <= elem.maxTerm
      );

      if (matchingLoanTerms && matchingLoanTerms.length === 0) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: "No match found for Custom Term",
            onCloseCallback: () => setIsMenuOpen(true),
          })
        );

        dispatch(setLoanDataValue("", "loanInformation.term"));
      }
    }
  };

  return (
    <>
      <ComboBoxForm
        isMulti
        maxOptions={8}
        label="Loan Terms"
        name="loanTerms"
        pathValue="productType"
        onChangeV2={handleLoanTermValue}
        componentsStyle={hideAllButtonComboboxStyles()}
        onMenuChange={{ onMenuChange: setIsMenuOpen }}
        isMenuOpen={{ isMenuOpen }}
        dependencies={dependencies.CUSTOM_TERM}
      />

      <InputMaskForm
        name="customTerm"
        label="Custom Term"
        maskOpts={masks.NO_DECIMAL_NO_PREFIX_NOT_NEGATIVE}
        maxLength={3}
        clearable
        pathValue="loanInformation.term"
        onBlur={handleCustomTermChange}
        dependencies={dependencies.LOAN_TERM}
      />
    </>
  );
};

LoanTermsField.defaultProps = {
  handleSetLoanDataValue: () => {},
};

LoanTermsField.propTypes = {
  handleSetLoanDataValue: PropTypes.func,
};

export default memo(LoanTermsField);
