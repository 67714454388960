import React, { useMemo } from "react";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import PropTypes from "prop-types";
import { AdjustmentTable } from "./adjustmentTable";

const AdjustmentTableSectionWrapper = ({ lockDetails }) => {
  const rateTableData = useMemo(() => {
    const [rate, price] = [
      {
        adjustments: lockDetails?.rateAdjustments || [],
        typeLabel: "Rate",
        base: lockDetails?.baseRate || 0,
      },
      {
        adjustments: lockDetails?.priceAdjustments || [],
        typeLabel: "Price",
        base: lockDetails?.basePrice || 0,
      },
    ].map((tableData) => {
      tableData.totalAdjustments =
        tableData.adjustments?.reduce(
          (sum, curr) => sum + curr?.amount || 0,
          0
        ) || 0;
      tableData.total =
        Number(tableData.base + tableData.totalAdjustments) || 0;
      return tableData;
    });

    const maxAdjustments = Math.max(
      rate.adjustments.length,
      price.adjustments.length
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const { adjustments } of [rate, price]) {
      for (let i = adjustments.length; i < maxAdjustments; i += 1) {
        adjustments.push({ description: " ", amount: null });
      }
    }

    return { price, rate };
  }, [lockDetails]);

  return (
    rateTableData && (
      <Grid
        cols={{
          small: [1 / 1, 0, 1 / 1],
          medium: ["1fr", "auto", "1fr"],
        }}
      >
        <AdjustmentTable {...rateTableData.rate} />
        <DSSeparatorV2 m="0 s" isVertical isDotted />
        <AdjustmentTable {...rateTableData.price} />
      </Grid>
    )
  );
};

AdjustmentTableSectionWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lockDetails: PropTypes.object.isRequired,
};

export default AdjustmentTableSectionWrapper;
