import { customActiveLockValueColumn } from "./helpers";

export const columns = [
  {
    Header: "Field Name",
    accessor: "label",
  },
  customActiveLockValueColumn,
  {
    Header: "CHANGED VALUE",
    accessor: "proposed",
  },
];
