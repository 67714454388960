export const LOCKDESK_STATUS_TITLES = {
  closed: "Lock Desk Closed",
  closing: "Lock Desk Closing Soon",
  open: "Lock Desk Open",
  tempClosed: "Lock Desk Temporarily Closed",
};
export const COUNTDOWM_TOAST_TEXT =
  "Once lock desk closes you will be unable to request a lock.";
export const SHUTDOWN_TOAST_TEXT =
  "If you have any questions, please contact the Lock Desk.";
export const SHUTDOWN_TOAST_TITLE = "Lock Desk is temporarily closed.";
export const CLOSED_TOAST_TITLE = "Lock Desk is closed.";
export const CLOSED_TOAST_TEXT =
  "You can save loan changes, but you can't request a lock.";
export const CLOSED_ONRP_TOAST_TEXT =
  "Lock desk is closed but Rate Protection is available.";
export const WARNING_TEXT = "Warning";
