/**
 * Array of Initial form values
 *
 * Each object has a path and values.
 * The values will contain all the elements of "getOrigin" that we want to store as initial values.
 * These values will never change
 *
 * @type {Array<object>}
 */

export const initialDepends = [
  {
    path: "",
    values: ["productType", "standardProducts", "specialProducts", "borrowers"],
  },
  {
    path: "property",
    values: ["zip", "state", "county", "city", "otherRealEstateOwned"],
  },
  {
    path: "loanInformation",
    values: [
      "loanPurpose",
      "otherHELOCDraws",
      "otherHELOCLines",
      "giftForDownPayment",
      "secondMortgageAmount",
      "sellerConsessionForDownPayment",
    ],
  },
];
