import React from "react";
import { Grid } from "@elliemae/ds-grid";
import { ArrowblockN, ArrowblockS } from "@elliemae/ds-icons";
import { currencyWithDecimals, formatAprValue } from "utils/shared";
import {
  currencyWithTwoDecimalArray,
  percentageWithThreeDecimalArray,
  integerArray,
  booleanArray,
} from "./constants";
import { StyledCell } from "./styled";

export const customActiveLockValueColumn = {
  id: "custom-active-lock-value-column",
  Header: "Active Lock Value",
  Cell: ({ row }) => {
    const { current, proposed, isNumber } = row.original;
    if (isNumber) {
      return (
        <Grid cols={["min-content", "auto"]} gutter="xxs">
          {parseFloat(current) > parseFloat(proposed) ? (
            <ArrowblockS
              size="s"
              color={["brand-primary", 600]}
              style={{ marginTop: 0 }}
            />
          ) : (
            <ArrowblockN
              size="s"
              color={["brand-primary", 600]}
              style={{ marginTop: 0 }}
            />
          )}
          <div>{current}</div>
        </Grid>
      );
    }
    return <StyledCell>{current}</StyledCell>;
  },
};

export const customChangedValueColumn = {
  id: "custom-changed-value-column",
  Header: "Changed Value",
  Cell: ({ row }) => {
    const { current, proposed, isNumber } = row.original;
    if (isNumber) {
      return (
        <Grid cols={["min-content", "auto"]} gutter="xxs">
          {parseFloat(current) > parseFloat(proposed) ? (
            <ArrowblockS
              size="s"
              color={["brand-primary", 600]}
              style={{ marginTop: 0 }}
            />
          ) : (
            <ArrowblockN
              size="s"
              color={["brand-primary", 600]}
              style={{ marginTop: 0 }}
            />
          )}

          <div>{proposed}</div>
        </Grid>
      );
    }
    return <StyledCell>{proposed}</StyledCell>;
  },
};

const parseElement = (value, id) => {
  if (value === null || value === undefined) {
    return "-";
  }
  if (currencyWithTwoDecimalArray.includes(id)) {
    return currencyWithDecimals(value, 2);
  }
  if (percentageWithThreeDecimalArray.includes(id)) {
    return formatAprValue(value);
  }
  if (integerArray.includes(id)) {
    return Number(value);
  }
  if (typeof value === "boolean" && booleanArray.includes(id)) {
    return value === true ? "Y" : "N";
  }
  if (!value) {
    return "-";
  }
  return value;
};

export const generateRows = (array) =>
  array.map(({ current, proposed, label, id }) => ({
    current: parseElement(current, id),
    proposed: parseElement(proposed, id),
    isNumber: typeof current === "number" && typeof proposed === "number",
    label,
  }));
