export const openLoader = (message, type) => ({
  type: "@SCREEN_LOADER@",
  payload: {
    show: true,
    ...(type && { type }),
    ...(message && { message }),
  },
});

export const closeLoader = () => ({
  type: "@SCREEN_LOADER@",
  payload: {
    show: false,
    message: "",
    type: "",
  },
});
