import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ToastType, toast, DSToast } from "@elliemae/ds-toast";
import { useLoanInformationField } from "data/useSelectors";
import {
  StyledEppsParentView,
  StyledEppsContainerBody,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { getCompensationValues } from "utils/compensationFields";
import Session, { IS_BUYSIDE } from "services/Session";
import AllRatesRibbon from "./components/AllRatesRibbon";
import AllRatesHeader from "./components/AllRatesHeader";
import AllRatesToolBar from "./components/AllRatesToolBar";
import AllRatesGrid from "./components/AllRatesGrid";
import { setAllRatesRowData } from "../../../../Qualification/QualificationActions";
import ProgramDetailsModal from "../../../LockSummary/components/ProgramDetailsModal/ProgramDetailsModal";
import { isLOComp } from "../../helpers";

const percent = (val) => +(val * 100).toFixed(3);

function AllRatesView({ allRatesData, loanData, qualifyPrograms }) {
  const { ltv, cltv, hcltv, comp, compVal } = allRatesData;
  const {
    program,
    rateDate,
    rateDetails = [],
    programID,
    target,
  } = allRatesData;
  const { lockDays, compensation } = loanData;
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const loanIdLoanData = useLoanInformationField("loanId");

  const [lockPeriodValue, setLockPeriodValue] = useState((lockDays || [30])[0]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const dispatch = useDispatch();

  const isBuySide = Session.get(IS_BUYSIDE);
  const showComp = isLOComp() && !isBuySide;

  const [compPercentage, compValue] = getCompensationValues(
    compensation.model,
    comp,
    compVal
  );

  const ribbonCardsOptions = [
    { label: `${lockPeriodValue} Day Rate As Of`, value: rateDate },
    {
      label: "LTV/CLTV/HCLTV",
      value: `${percent(ltv)}/${percent(cltv)}/${percent(hcltv)}`,
    },
    ...(showComp
      ? [
          { label: "Compensation %", value: compPercentage },
          { label: "Compensation $", value: compValue },
        ]
      : []),
    ...(selectedProduct && selectedProduct.bRatio
      ? [
          {
            label: "DTI",
            value: Number(selectedProduct.bRatio).toFixed(3),
          },
        ]
      : []),
  ];

  const handleLockPeriodChange = async ({ id: lockPeriod }) => {
    const newLoanData = { ...loanData, lockDays: [lockPeriod] };
    setLockPeriodValue(lockPeriod);
    await qualifyPrograms(newLoanData).then(() =>
      toast({
        type: ToastType.SUCCESS,
        autoClose: 5000,
        messageTitle: "Search Criteria Updated",
        messageText: `All search results now have a ${lockPeriod} Day Lock Period.`,
      })
    );
  };

  const [programSlide, setProgramSlide] = useState(false);
  const toggleprogramSlide = () => setProgramSlide((prev) => !prev);

  const handleSelectRate = (selection) => {
    setSelectedProduct(selection);
  };

  useEffect(() => {
    return () => dispatch(setAllRatesRowData(null));
  }, []);

  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper paddingBottom="0">
          <AllRatesHeader
            onClickProgramDetails={toggleprogramSlide}
            name={program}
            rate={rateDetails.length}
          />
          <AllRatesRibbon ribbonCardsOptions={ribbonCardsOptions} />
          <div
            style={{
              width: Session.get(IS_BUYSIDE) ? "50%" : "100%",
              overflow: "hidden",
            }}
          >
            <AllRatesToolBar
              lockPeriodValue={lockPeriodValue}
              onLockPeriodChange={handleLockPeriodChange}
              selectedProduct={selectedProduct}
              ratesData={allRatesData}
              loanId={loanId || loanIdLoanData}
            />
            <AllRatesGrid
              rates={rateDetails}
              programId={programID}
              onSelectRate={handleSelectRate}
              targetRow={target}
              selectedProduct={selectedProduct}
            />
          </div>
          <DSToast position="top-right" />
          {programSlide && (
            <ProgramDetailsModal
              isOpen={programSlide}
              toggleModalSlide={toggleprogramSlide}
            />
          )}
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
    </StyledEppsParentView>
  );
}

AllRatesView.propTypes = {
  allRatesData: PropTypes.instanceOf(Object).isRequired,
  loanData: PropTypes.instanceOf(Object).isRequired,
  qualifyPrograms: PropTypes.func.isRequired,
};

export default AllRatesView;
