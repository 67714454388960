export const currencyWithTwoDecimalArray = [
  "firstMortgageAmount",
  "secondMortgageAmount",
  "totalLoanAmount",
  "cashOut",
  "totSubClosedEndMort",
  "loanAmountMaxLine",
  "usdaGuarantee",
  "liquidAsset",
  "income",
  "monthlyDebt",
  "value",
  "rentalIncome",
];

export const percentageWithThreeDecimalArray = ["rate"];

export const integerArray = [
  "creditScore",
  "30DaysMortgageLatestin12Months",
  "60DaysMortgageLatestin12Months",
  "90DaysMortgageLatestin12Months",
  "30DaysMortgageLatestin24Months",
  "60DaysMortgageLatestin24Months",
  "90DaysMortgageLatestin24Months",
  "120DaysMortgageLatestin12Months",
  "noticeOfDefaultForeclosure",
  "bankruptcyInMonths",
];

export const booleanArray = [
  "condotel",
  "non_warrantable_Condo",
  "selfEmployedBorrower",
  "selfEmployedCoBorrower",
  "firstTimeHomeBuyers",
];
