import React, { useEffect, useState, useMemo } from "react";
import { useAppData } from "utils/customHooks/useAppData";
import IneligibilityGrid from "../components/IneligibilityGrid";
import LoanLimitsGrid from "../components/LoanLimits";

export const splitByEligible = (products) =>
  products.reduce(
    ({ eligible, ineligible }, elem) =>
      elem.deleted === 0
        ? { ineligible, eligible: [...eligible, elem] }
        : { eligible, ineligible: [...ineligible, elem] },
    {
      eligible: [],
      ineligible: [],
    }
  );

const matchInvestor = (program, investors) => {
  const lenderID = program.customLenderID || program.lenderID;
  if (investors.some((item) => item.id === 0)) {
    return program;
  }
  return investors.some(({ id }) => id === lenderID);
};

const matchProduct = (program, products) => {
  const { flagFilter } = program;
  return products.some(({ id }) => id === Math.abs(flagFilter));
};

export const filterPrograms = (programs, activeFilters) => {
  const { investor, product, special, term, flagged } = activeFilters || [];
  let filteredPrograms = programs;
  const products = product.concat(special);
  if (investor.length) {
    filteredPrograms = filteredPrograms.filter((program) =>
      matchInvestor(program, investor)
    );
  }
  if (product.length || special.length) {
    filteredPrograms = filteredPrograms.filter((program) =>
      matchProduct(program, products)
    );
  }
  if (term?.label) {
    if (term.label === "All") {
      filteredPrograms = filteredPrograms.filter(
        (program) => program.programTypeID
      );
    } else {
      filteredPrograms = filteredPrograms.filter(
        (program) => program.programTypeID === term.value
      );
    }
  }
  if (flagged) {
    filteredPrograms = filteredPrograms.filter(
      (program) => typeof program.nonOptions === "string"
    );
  } else {
    filteredPrograms = filteredPrograms.filter(
      (program) => program.nonOptions === ""
    );
  }
  return filteredPrograms;
};

export const useIneligibleProductsGridHooks = (
  loanPrograms,
  loanLimitsData,
  activeFilters
) => {
  const refIneligible = useAppData("refIneligible");
  const setIneligibleProducts = useAppData("setIneligibleProducts");
  const [newRows, setNewRows] = useState([]);
  const { ineligible } = useMemo(
    () =>
      Array.isArray(loanPrograms) && loanPrograms.length
        ? splitByEligible(loanPrograms)
        : { ineligible: [] },
    [loanPrograms]
  );

  useEffect(() => {
    if (ineligible.length) {
      const filteredIneligible = filterPrograms(ineligible, activeFilters);
      const mappingData = filteredIneligible.map((row) => ({
        ...row,
        notes: row.notes.replace(/\{.*?\}/g, ""),
        tableRowDetails: (props) => (
          <>
            <IneligibilityGrid
              {...props}
              data={row.eligibilityDetails}
              programID={row.programID}
            />
            <LoanLimitsGrid
              {...props}
              data={loanLimitsData}
              programID={row.programID}
            />
          </>
        ),
      }));
      setNewRows(mappingData);

      if (mappingData.length > 0 && !refIneligible.current) {
        setIneligibleProducts(mappingData.length);
        refIneligible.current = true;
      }
    }
    return () => {
      refIneligible.current = false;
    };
  }, [ineligible, loanLimitsData, activeFilters]);

  return {
    newRows,
  };
};
