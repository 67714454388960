import React from "react";
import { DSRibbon } from "@elliemae/ds-ribbon";
import { shape, arrayOf, string, oneOfType, number } from "prop-types";

const AllRatesRibbon = ({ ribbonCardsOptions }) => (
  <React.Fragment>
    <DSRibbon.RibbonWrapper>
      <DSRibbon.RibbonRow alignLeft>
        {ribbonCardsOptions.map((item) => (
          <DSRibbon.RibbonCol key={`${item.label}`}>
            <DSRibbon.RibbonCard
              solid={false}
              additionalLabel={item.label}
              value={item.value}
            />
          </DSRibbon.RibbonCol>
        ))}
      </DSRibbon.RibbonRow>
    </DSRibbon.RibbonWrapper>
  </React.Fragment>
);

AllRatesRibbon.propTypes = {
  ribbonCardsOptions: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    })
  ).isRequired,
};

export default AllRatesRibbon;
