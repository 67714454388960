import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLockedProgramSelector } from "data/useSelectors";
import { setLockedProgramData } from "data/origin/actions";
import { MODAL_BODIES } from "./constants";

const ModalBody = () => {
  const dispatch = useDispatch();
  const {
    programName = "",
    notes = "",
    status = "eligible",
  } = useLockedProgramSelector();
  const viewDetails = useCallback((event) => {
    event.preventDefault();
    dispatch(setLockedProgramData({ focusIneligible: true, modalOpen: false }));
  }, []);

  if (status === "ineligible")
    return (
      <div>
        {programName} :<br /> {notes}
        <a
          href="#"
          onClick={viewDetails}
          style={{ marginLeft: "4px", whiteSpace: "nowrap" }}
        >
          View Details
        </a>
      </div>
    );
  return (
    <>
      {programName}
      {MODAL_BODIES[status]}
    </>
  );
};

export default ModalBody;
