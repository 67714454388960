import React, { useMemo } from "react";
import { DSTabs, DSTab } from "@elliemae/ds-tabs";
import { elementType } from "prop-types";
import {
  useCompareDataSelector,
  useLoanDataFieldSelector,
} from "data/useSelectors";

import LoanFieldChangesView from "./components/LoanFieldChangesView";

const Validate = ({ buySidePriceSection }) => {
  const { changeCounter: pricingChangesCount = 0 } = useCompareDataSelector();
  const loanFieldChanges = useLoanDataFieldSelector(
    "additionalLockInfo.lockCompareResults",
    {}
  );
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges]
  );

  return (
    <DSTabs onlyRenderActiveTab>
      <DSTab
        tabId="PRICING_CHANGES"
        title={`Pricing Changes (${pricingChangesCount})`}
      >
        {buySidePriceSection}
      </DSTab>
      <DSTab
        tabId="LOAN_FIELD_CHANGES"
        title={`Loan Field Changes (${loanFieldChangesCount})`}
      >
        <LoanFieldChangesView />
      </DSTab>
    </DSTabs>
  );
};
Validate.propTypes = {
  buySidePriceSection: elementType.isRequired,
};

export default Validate;
