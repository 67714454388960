import React from "react";
import Routes from "routes/index";
import Header from "components/Header";
import {
  StyledEppsParentContainer,
  StyledEppsContainerHeader,
} from "../global/styles/styled";

const Landing = () => {
  return (
    <StyledEppsParentContainer>
      <StyledEppsContainerHeader>
        <Header />
      </StyledEppsContainerHeader>
      <Routes />
    </StyledEppsParentContainer>
  );
};

export default Landing;
