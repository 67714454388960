import React from "react";
import { useDispatch } from "react-redux";
import { number, func, shape } from "prop-types";
import { DSToolbar } from "@elliemae/ds-toolbar";
import { DSFilterBar } from "@elliemae/ds-filterbar";
import { DSButton } from "@elliemae/ds-button";
import { setRedirectTabAction } from "data/landing/actions";
import { PATH_LOCK_SUMMARY } from "routes/constants";
import QuoteInsuranceButtonWrapper from "view/SearchResult/components/QuoteInsuranceButton";
import Session, { ALL_SUMMARY_PARAMS } from "services/Session";
import { useLookupOptions } from "components/Form/utils/customHooks";
import { getLockPeriod } from "./helpers";
import StyledAllRatesToolbar from "./styled";

function AllRatesToolBar({
  lockPeriodValue,
  onLockPeriodChange,
  selectedProduct,
  ratesData,
  loanId,
}) {
  const lockdays = useLookupOptions("lockdays");
  const dispatch = useDispatch();

  const lockPeriod = getLockPeriod(
    lockPeriodValue,
    lockdays,
    onLockPeriodChange,
    ratesData.locksAvailable
  );

  const handleSelectProduct = () => {
    Session.set(ALL_SUMMARY_PARAMS, {
      loanId,
      programId: ratesData.programID,
      rateDataId: selectedProduct.rateDataID,
    });
    dispatch(setRedirectTabAction(PATH_LOCK_SUMMARY));
  };

  return (
    <StyledAllRatesToolbar>
      <DSToolbar
        withDepth={false}
        style={{ justifyContent: "space-between", marginTop: "16px" }}
        containerProps={{ id: "toolbar-all-rates-view" }}
      >
        <DSFilterBar filters={[lockPeriod]} renderToolbar={() => null} />
        <div style={{ display: "flex" }}>
          <QuoteInsuranceButtonWrapper />
          <DSButton
            buttonType="primary"
            labelText="Select Product"
            onClick={handleSelectProduct}
            disabled={!selectedProduct}
          />
        </div>
      </DSToolbar>
    </StyledAllRatesToolbar>
  );
}

AllRatesToolBar.propTypes = {
  lockPeriodValue: number.isRequired,
  onLockPeriodChange: func.isRequired,
  selectedProduct: shape().isRequired,
  ratesData: shape({
    programId: number,
    rateDateId: number,
  }).isRequired,
  loanId: number.isRequired,
};

export default AllRatesToolBar;
