import { optionsLOmode, optionsSecondaryMode } from "./options";

export const getMenuOptions = (
  tabId,
  isSecondary,
  disablePricingPage,
  disableRevised
) => {
  return !isSecondary
    ? optionsLOmode(tabId)
    : optionsSecondaryMode(disablePricingPage, disableRevised);
};
