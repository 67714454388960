import React, { useMemo } from "react";
import { DSAccordion, DSAccordionItem } from "@elliemae/ds-accordion";
import { DSTextWrapper } from "@elliemae/ds-text-wrapper";
import { useRateSelector, useRateSelectorLien } from "data/useSelectors";
import ProgramDetails from "../ProgramDetails";
import BorrowersSection from "./BorrowersSection";
import getLoanInfoFields from "./helpers";
import SummarySection from "./SummarySection";
import {
  usePropertySectionData,
  useEstimatesSectionData,
  useArmSectionData,
  useHelocSectionData,
} from "./hooks";
import { useProgramDetails } from "../../hooks";

function InformationGroupSection() {
  const rateData = useRateSelector();
  const { loan = {}, lien = {} } = rateData;
  const loanInfo = {
    qualificationType: loan.qualificationType,
    qualificationTypeId: loan.qualificationTypeId,
    purpose: loan.purpose,
    lienPosition: lien.lienPosition,
    estimatedClosingCosts: loan.estimatedClosingCosts,
    commissionDollar: lien.commissionDollar,
    firstMortgageAmount: loan.amount,
    secondMortgageAmount: loan.otherMtgAmount,
    propertyValue: loan.property?.value,
    prePayPenaltyTerm: lien.ppp,
    sellerConcession: loan.concessions,
    aprClosingCost: loan.aprClosingCost,
  };
  const loanInfoFields = useMemo(() => getLoanInfoFields(loanInfo), [loanInfo]);
  const propertyInfoFields = usePropertySectionData();
  const estimatesInfoFields = useEstimatesSectionData();
  const isArm = lien.arm || false;
  const isHeloc = (lien.programTypeName || []).includes("HELOC");
  const armInfoFields = useArmSectionData(isArm);
  const helocInfoFields = useHelocSectionData(isHeloc);
  const { programName, userLender, note, programId } = useRateSelectorLien(
    ["programName", "userLender", "note", "programId"],
    ""
  );
  const loanProgramName = `${userLender || ""} ${programName || ""}`;
  const programInfo = {
    notes: note?.join("<br/><li>"),
    program: loanProgramName,
    programID: programId,
  };
  const {
    program,
    productNotes: notes,
    activeGuidelineLink,
    formPostAllRegs,
  } = useProgramDetails(programInfo);

  return (
    <>
      <DSTextWrapper
        className="section-title"
        style={{
          paddingTop: 20,
          paddingBottom: 5,
          fontSize: 16,
          fontWeight: "Bold",
        }}
        text="Program Details"
      />
      <ProgramDetails
        program={program}
        notes={notes}
        activeGuidelineLink={activeGuidelineLink}
        onLinkClick={formPostAllRegs}
      />
      <DSAccordion allowMultipleOpen>
        {isHeloc ? (
          <DSAccordionItem title="HELOC Details" value="helocDetails">
            <SummarySection fields={helocInfoFields} />
          </DSAccordionItem>
        ) : (
          <></> // TODO is this needed?
        )}
        {isArm ? (
          <DSAccordionItem title="ARM Details" value="armDetails">
            <SummarySection fields={armInfoFields} />
          </DSAccordionItem>
        ) : (
          <></> // TODO is this needed?
        )}
        <DSAccordionItem title="Loan Details" value="loanDetails">
          <SummarySection fields={loanInfoFields} />
        </DSAccordionItem>
        <DSAccordionItem title="Subject Property" value="subjectProperty">
          <SummarySection fields={propertyInfoFields} />
        </DSAccordionItem>
        <DSAccordionItem title="Estimated Payments" value="estimatedPayments">
          <SummarySection fields={estimatesInfoFields} />
        </DSAccordionItem>
        <DSAccordionItem title="Borrower(s)" value="borrowers">
          <BorrowersSection />
        </DSAccordionItem>
      </DSAccordion>
    </>
  );
}

export default InformationGroupSection;
