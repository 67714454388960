import React, { useContext } from "react";
import { get } from "lodash";

const DataContext = React.createContext(null);

export const DataProvider = DataContext.Provider;

export const useAppData = (key = null, defaultValue) => {
  const appData = useContext(DataContext);
  const finalDefaultValue =
    typeof defaultValue !== "undefined" ? defaultValue : appData;
  return get(appData, key, finalDefaultValue);
};
