import React from "react";
import { shape, func } from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";
import { DSCard, DSCardHeader, DSCardBody } from "@elliemae/ds-card";
import Common from "common/services/Common";
import { formatNumberWithCommas } from "utils/shared";
import { getAdjustmentDetails as getAdjustmentDetailsAction } from "./AdjustmentDetailsAction";
import { StyledModalContainer } from "./styles";
import { compare } from "./utils";

class AdjustmentDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: undefined,
    };
  }

  async componentDidMount() {
    const { getAdjustmentDetails, data } = this.props;
    const details = await getAdjustmentDetails(
      data.loanId,
      data.programId,
      data.rateDataId,
      data.lienPosition
    );
    this.setState({ details });
  }

  render() {
    const programs = get(this.props, "EPPS.loanPrograms") || {};
    const { details } = this.state;

    if (details === undefined) return <StyledModalContainer />;
    if (details.adjustments === undefined || details.adjustments.length === 0)
      return <div>No Adjustments Found</div>;

    const data = programs.loanPrograms.filter((p) => {
      return p.programID === details.programID;
    })[0];
    if (data === undefined) return null;

    return (
      <StyledModalContainer>
        <div className="grid-x grid-margin-x">
          <div className="cell large-7">
            {data.program}
            <br />
            {data.documentation}
          </div>
          <div className="cell large-5">
            {data.lockDays}-day rates
            <br /> as of {data.rateDate}
          </div>
        </div>
        <div className="grid-y grid-padding-y">
          <div
            className="cell large-2"
            style={{ fontWeight: "bold", background: "#edeff1" }}
          >
            <div className="grid-x grid-margin-x">
              <div className="cell large-8">Adjustment Details</div>
              <div className="cell large-2">Rate</div>
              <div className="cell large-2">Price</div>
            </div>
          </div>
          {details.adjustments.sort(compare).map((d) => {
            return (
              <div className="cell large-4">
                <div className="grid-x grid-margin-x">
                  <div
                    className="cell large-8"
                    style={
                      d.displayOrder === 0 || d.displayOrder === 999
                        ? { fontWeight: "bold" }
                        : {}
                    }
                  >
                    {d.description}
                  </div>
                  <div className="cell large-2">
                    {d.rate !== 0 ? `${d.rate}%` : ""}
                  </div>
                  <div className="cell large-2">{d.price.toFixed(3)}</div>
                </div>
              </div>
            );
          })}
          <div className="cell large-2">
            <DSCard>
              <DSCardBody>
                <div>
                  APR is an Estimated Calculation using Estimated Closing Costs
                  set by the Client.
                </div>
                <div>
                  Estimated Closing Costs calculated in APR:{" "}
                  {`$${formatNumberWithCommas(details.estimatedClosingCost)}`}
                </div>
              </DSCardBody>
            </DSCard>
          </div>
          <div className="cell large-2">
            {details.bonaFideTestResults !== undefined &&
              details.bonaFideTestResults !== null && (
                <DSCard>
                  <DSCardHeader title="Bona Fide Discount Point Assessment" />{" "}
                  <DSCardBody>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={Common.createMarkup(
                        details.bonaFideTestResults.toString()
                      )}
                    />
                  </DSCardBody>
                </DSCard>
              )}
          </div>
        </div>
      </StyledModalContainer>
    );
  }
}

AdjustmentDetailsView.propTypes = {
  data: shape({}).isRequired,
  getAdjustmentDetails: func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdjustmentDetails: bindActionCreators(
      getAdjustmentDetailsAction,
      dispatch
    ),
  };
};
const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdjustmentDetailsView);
