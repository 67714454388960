import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import rootReducer from "common/reducers/index";
import logger from "utils/middleware/logger";
import rootSaga from "./rootSagas";

const configureStore = (preloadedState) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunk, sagaMiddleware, logger];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  if (
    process.env.NODE_ENV !== "prod" &&
    process.env.NODE_ENV !== "uat" &&
    module.hot
  ) {
    module.hot.accept("common/reducers", () =>
      store.replaceReducer(rootReducer)
    );
  }
  sagaMiddleware.run(rootSaga);
  return store;
};

export default configureStore;
