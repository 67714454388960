export const SET_LOCK_DESK_STATUS_DATA = "SET_LOCK_DESK_STATUS_DATA";
export const SET_LOCK_DESK_REQUEST_LOCK_BUTTON_STATUS =
  "SET_LOCK_DESK_REQUEST_LOCK_BUTTON_STATUS";
export const SHOW_ONRP_LIMIT_NOTIFICATION = "SHOW_ONRP_LIMIT_NOTIFICATION";
export const SET_LOCK_DESK_MODAL = "SET_LOCK_DESK_MODAL";
export const SET_ALLOW_LOCK_UPDATE = "SET_ALLOW_LOCK_UPDATE";
export const SET_LOCK_UNDER_ONRP = "SET_LOCK_UNDER_ONRP";

export const setLockDeskStatusData = (payload) => ({
  type: SET_LOCK_DESK_STATUS_DATA,
  payload,
});

export const setLockDeskRequestLockButtonStatus = (payload) => ({
  type: SET_LOCK_DESK_REQUEST_LOCK_BUTTON_STATUS,
  payload,
});

export const showOnrpLimitNotification = (payload) => ({
  type: SHOW_ONRP_LIMIT_NOTIFICATION,
  payload,
});

export const setLockDeskModal = (payload) => ({
  type: SET_LOCK_DESK_MODAL,
  payload,
});

export const setAllowLockUpdateInShutdown = (payload) => ({
  type: SET_ALLOW_LOCK_UPDATE,
  payload,
});

export const setLockRequestUnderOnrp = (payload) => ({
  type: SET_LOCK_UNDER_ONRP,
  payload,
});
