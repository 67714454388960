import React, { useState, useEffect } from "react";
import { bool, func, string, arrayOf } from "prop-types";
import Session, { APPLICATION_NAME, ORIGIN_SOURCE } from "services/Session";
import {
  DSDialog,
  DSDialogBody,
  DSDialogSeparator,
  DSDialogDefaultLayout,
  DSDialogHeader,
  DSDialogTitle,
  DSDialogFooter,
} from "@elliemae/ds-dialog";
import { MASK_TYPES, DSInputMask, DSCheckbox } from "@elliemae/ds-form";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { Grid } from "@elliemae/ds-grid";
import { DSButtonV2 } from "@elliemae/ds-button";
import { TextBoxForm } from "components/Form";
import { StyledTitle, StyledCard1, StyledCard2, StyledDiv } from "./styled";
import {
  handlePriceChange,
  handleRateChange,
  handleDaysChange,
  handleEmailChange,
} from "./helpers";

const RateAlertModal = ({
  isModalOpen,
  borrower,
  rate,
  price,
  loanInfoLine1Col1,
  loanInfoLine2Col1,
  loanInfoLine2Col2,
  options,
  email,
  handleCancel,
  handleCreate,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [rateValue, setRateValue] = useState(rate);
  const [priceValue, setPriceValue] = useState(price);
  const [emailValue, setEmailValue] = useState();
  const [daysValue, setDaysValue] = useState("15");
  const [primaryEmail, setPrimaryEmail] = useState(true);
  const [secondaryEmail, setSecondaryEmail] = useState(false);
  const [notify, setNotify] = useState(false);
  const [comboOptions, setComboOptions] = useState([]);
  const [validator, setValidator] = useState({
    daysError: false,
    emailError: false,
    rateError: [undefined, null, ""].includes(rate),
    priceError: [undefined, null, ""].includes(price),
    rateErrorMsg: "",
    priceErrorMsg: "",
    daysErrorMsg: "",
    emailErrorMsg: "",
  });
  const alertText = `${"Alert me at my default address of "} ${email}`;

  useEffect(() => {
    setRateValue(rate);
    setPriceValue(price);
    if (options?.length) {
      const mappingData = options.map((data) => ({
        ...data,
      }));
      setComboOptions(mappingData);
      setSelectedValue(mappingData[0]);
    }
  }, [rate, price, primaryEmail, secondaryEmail, notify, emailValue]);

  const priceChange = (e) => {
    setPriceValue(e.target.value);
    const priceValidation = handlePriceChange(e);
    setValidator({
      priceError: priceValidation.hasError,
      priceErrorMsg: priceValidation.errorMessage,
    });
  };

  const rateChange = (e) => {
    setRateValue(e.target.value);
    const rateValidation = handleRateChange(e);
    setValidator({
      rateError: rateValidation.hasError,
      rateErrorMsg: rateValidation.errorMessage,
    });
  };

  const daysChange = (e) => {
    setDaysValue(e.target.value.toString());
    const daysValidation = handleDaysChange(e);
    setValidator({
      daysError: daysValidation.hasError,
      daysErrorMsg: daysValidation.errorMessage,
    });
  };

  const emailChange = (e) => {
    setEmailValue(e.value);
    const emailValidation = handleEmailChange(e);
    setValidator({
      emailError: emailValidation.hasError,
      emailErrorMsg: emailValidation.errorMessage,
    });
  };

  const handleCreateEvent = () => {
    handleCreate({
      sourceApplicationName: Session.get(APPLICATION_NAME) || "ENCW",
      sourceApplicationFormName: Session.get(ORIGIN_SOURCE) || "lockrequest",
      eppsUserName: "",
      facadeUserId: "",
      program: selectedValue.label,
      rate: rateValue,
      price: priceValue,
      usePrimaryEmail: primaryEmail,
      altEmail: emailValue,
      setAltEmailAsDefault: secondaryEmail,
      expireInDays: daysValue,
      notifyIfExpires: notify,
      status: 0,
    });
  };

  return (
    <Grid height="100%">
      <DSDialog
        isOpen={isModalOpen}
        onClickOutside={handleCancel}
        centered
        style={{ width: "800px", height: "500px" }}
      >
        <DSDialogHeader>
          <DSDialogTitle>Create Rate Alert</DSDialogTitle>
        </DSDialogHeader>
        <DSDialogSeparator />
        <DSDialogBody>
          <DSDialogDefaultLayout>
            <DSDialogSeparator />
            <StyledTitle>
              Loan Information <br />
              <br />
              <StyledDiv>
                <StyledCard1>Borrower {borrower}</StyledCard1>
                <StyledCard2>{options[0].label}</StyledCard2>
              </StyledDiv>
              <StyledDiv>
                <StyledCard1>{loanInfoLine1Col1}</StyledCard1>
                <StyledCard2>{`${rate.toFixed(
                  3
                )} % ${" @"} ${price}`}</StyledCard2>
              </StyledDiv>
              <StyledDiv>
                <StyledCard1>{loanInfoLine2Col1}</StyledCard1>
                <StyledCard2>{loanInfoLine2Col2}</StyledCard2>
              </StyledDiv>
            </StyledTitle>
            <StyledTitle>
              <br />
              Alert Settings <br />
              <br />
              <StyledDiv>
                <Grid cols={["1fr", "1fr", "1fr"]} gutter="xxs">
                  <DSFormLayoutBlockItem
                    label="Alert me when"
                    inputID="programs"
                  >
                    <DSSingleComboBox
                      name="programs"
                      id="programs"
                      allOptions={comboOptions}
                      onChange={(item) => {
                        setSelectedValue(item);
                      }}
                      selectedValue={selectedValue}
                      required
                    />
                  </DSFormLayoutBlockItem>

                  <DSFormLayoutBlockItem
                    label="prices at or better than the rate of"
                    inputID="rateAt"
                    hasError={validator.rateError}
                    validationMessage={validator.rateErrorMsg}
                  >
                    <DSInputMask
                      useSubfix="%"
                      placeholder="0%"
                      mask={(num) =>
                        MASK_TYPES.NUMBER({
                          prefix: "",
                          integerLimit: 12,
                          allowDecimal: true,
                          allowNegative: true,
                          decimalLimit: 3,
                        })(num)
                      }
                      name="rateAt"
                      value={rateValue.toString()}
                      onBlur={rateChange}
                    />
                  </DSFormLayoutBlockItem>
                  <DSFormLayoutBlockItem
                    label="and the price of"
                    inputID="priceAt"
                    hasError={validator.priceError}
                    validationMessage={validator.priceErrorMsg}
                  >
                    <DSInputMask
                      mask={(num) =>
                        MASK_TYPES.NUMBER({
                          prefix: "",
                          integerLimit: 12,
                          allowDecimal: true,
                          allowNegative: true,
                          decimalLimit: 3,
                        })(num)
                      }
                      name="priceAt"
                      value={priceValue.toString()}
                      placeholder=""
                      onBlur={priceChange}
                    />
                  </DSFormLayoutBlockItem>
                </Grid>
                <br />
                <Grid>
                  <DSCheckbox
                    labelText={alertText}
                    checked={primaryEmail}
                    name="primaryEmail"
                    id="primaryEmail"
                    onChange={(value) => setPrimaryEmail(value.target.checked)}
                  />
                </Grid>
                <br />
                <Grid cols={["1fr", "1fr"]} gutter="xs" alignItems="center">
                  <DSFormLayoutBlockItem
                    label="Send Alert Email Address"
                    inputID="email"
                    hasError={validator.emailError}
                    validationMessage={validator.emailErrorMsg}
                  >
                    <TextBoxForm
                      name="email"
                      value={emailValue}
                      placeholder=""
                      onBlur={emailChange}
                    />
                  </DSFormLayoutBlockItem>
                  <DSCheckbox
                    labelText="Make this my default email address"
                    checked={secondaryEmail}
                    name="altEmail"
                    id="altEmail"
                    onChange={(value) =>
                      setSecondaryEmail(value.target.checked)
                    }
                  />
                </Grid>
              </StyledDiv>
            </StyledTitle>
            <StyledTitle>
              Alert Expiration <br />
              <StyledDiv>
                <Grid cols={["1fr", "1fr"]} gutter="xs" alignItems="center">
                  <DSFormLayoutBlockItem
                    label="Alert Expiration in (days)"
                    inputID="days"
                    hasError={validator.daysError}
                    validationMessage={validator.daysErrorMsg}
                  >
                    <DSInputMask
                      mask={(num) =>
                        MASK_TYPES.NUMBER({
                          prefix: "",
                          integerLimit: 2,
                          allowDecimal: false,
                          allowNegative: false,
                        })(num)
                      }
                      name="days"
                      placeholder=""
                      value={daysValue.toString()}
                      onBlur={daysChange}
                    />
                  </DSFormLayoutBlockItem>
                  <DSCheckbox
                    labelText="Notify me if this alert expires"
                    checked={notify}
                    name="notify"
                    id="notify"
                    onChange={(value) => setNotify(value.target.checked)}
                  />
                </Grid>
              </StyledDiv>
            </StyledTitle>

            <DSDialogSeparator />
          </DSDialogDefaultLayout>
        </DSDialogBody>
        <DSDialogSeparator />
        <DSDialogFooter>
          <DSButtonV2 buttonType="outline" onClick={handleCancel}>
            Cancel
          </DSButtonV2>
          <DSButtonV2 onClick={() => handleCreateEvent()}>Create</DSButtonV2>
        </DSDialogFooter>
      </DSDialog>
    </Grid>
  );
};

RateAlertModal.defaultProps = {
  isModalOpen: false,
  borrower: "",
  rate: "",
  price: "",
  loanInfoLine1Col1: "",
  loanInfoLine2Col1: "",
  loanInfoLine2Col2: "",
  email: "",
  options: [],
};

RateAlertModal.propTypes = {
  isModalOpen: bool,
  borrower: string,
  rate: string,
  price: string,
  loanInfoLine1Col1: string,
  loanInfoLine2Col1: string,
  loanInfoLine2Col2: string,
  options: arrayOf(),
  email: string,
  handleCancel: func.isRequired,
  handleCreate: func.isRequired,
};

export default RateAlertModal;
