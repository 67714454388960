import React from "react";
import { DSToolbar } from "@elliemae/ds-toolbar";
import FilterPills from "view/SearchResult/components/FilterPills";

function BestExToolbar() {
  return (
    <DSToolbar
      withDepth={false}
      style={{
        alignItems: "flex-start",
        height: "auto",
        justifyContent: "space-between",
        marginBottom: 10,
        paddingBottom: "10px",
      }}
    >
      <FilterPills />
    </DSToolbar>
  );
}

export default BestExToolbar;
