import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy } from "lodash";
import { DSLabelValue } from "@elliemae/ds-label-value";
import { getRelockDataAction } from "data/lockConfirm/actionCreators";
import { stopLog } from "utils/logger";
import { pricingAnalyzerLaunched } from "utils/constants/ErrorCodes";
import RatesHeaderToolbar from "./RatesHeaderToolbar";
import LockUpdateBoxesWrapper from "./LockUpdateBoxContainer";
import {
  generateLockUpdateEvent,
  generateRateFilters,
  getDefaultRate,
  getAvailableRates,
  checkDuplicateInObject,
  formatRate,
} from "./helpers";

const loanEventDataSelector = ({ epps }) =>
  (epps && epps.lockConfirm.loanRelockData) || [];
const loanDataSelector = ({ EPPS }) => (EPPS && EPPS.loanInfo) || [];

const PriceAnalyzerView = ({ onCompareRates, onApplyChange }) => {
  const relockSnapShotData = useSelector(loanEventDataSelector);
  const relockEventData = relockSnapShotData.relockData || [];
  const relockEventDates = relockSnapShotData.eventList || [];
  const originLoanData = useSelector(loanDataSelector);
  const uniqAvailableRates = uniqBy(getAvailableRates(relockEventData), "rate");
  const availableRates = getAvailableRates(relockEventData);
  const [defaultRate, setDefaultRate] = useState(
    getDefaultRate(
      availableRates,
      originLoanData.relockRequest.specificRateDataID
    )
  );

  const dispatch = useDispatch();
  const [relockLoanData, setRelockLoanData] = useState(null);

  const [relockEventBoxData, setRelockEventBoxData] = useState(null);

  const populateLoanEventData = (data) => {
    setRelockLoanData(data);
  };

  const initPageData = async () => {
    await dispatch(getRelockDataAction());
    await populateLoanEventData(relockEventData);
    stopLog(pricingAnalyzerLaunched);
  };

  const flushSavedData = async () => {
    await populateLoanEventData(null);
  };

  useEffect(() => {
    initPageData();
  }, []);

  const updateRelockEventBoxex = (selRate) => {
    setRelockEventBoxData(
      generateLockUpdateEvent(relockLoanData, selRate, relockEventDates)
    );
  };

  const updatedefaultRate = () => {
    let startRate = 0;
    if (originLoanData) {
      startRate = getDefaultRate(
        availableRates,
        originLoanData.relockRequest.specificRateDataID
      );
      setDefaultRate(startRate);
    }
  };

  useEffect(() => {
    if (relockLoanData) {
      setRelockLoanData(relockEventData);
      updateRelockEventBoxex(defaultRate);
      updatedefaultRate();
    }
    return () => {
      flushSavedData();
    };
  }, [relockLoanData]);

  const onChangeRate = (val) => {
    setDefaultRate(val);
  };

  useEffect(() => {
    updateRelockEventBoxex(defaultRate);
  }, [defaultRate]);

  return (
    <div className="grid-x" style={{ overflow: "hidden" }}>
      <div className="cell large-12">
        <div className="cell large-12">
          <DSLabelValue>
            <DSLabelValue.Label>
              The prices below take your current pricing scenario and applies it
              to your historical rate sheets.
            </DSLabelValue.Label>
          </DSLabelValue>
        </div>
        <div className="cell large-12">
          <RatesHeaderToolbar
            onCompareRates={onCompareRates}
            availableRates={generateRateFilters(uniqAvailableRates)}
            onChangeRate={onChangeRate}
            defaultRate={
              defaultRate != null
                ? { id: defaultRate, label: formatRate(defaultRate) }
                : { id: 0, label: formatRate(0) }
            }
          />
        </div>
        <div className="cell large-12">
          <LockUpdateBoxesWrapper
            relockData={relockEventBoxData}
            relockEventDateList={checkDuplicateInObject(relockEventDates)}
            defaultRate={defaultRate}
            selectedLockBox={onApplyChange}
          />
        </div>
      </div>
    </div>
  );
};

PriceAnalyzerView.propTypes = {
  onCompareRates: PropTypes.func.isRequired,
  onApplyChange: PropTypes.func.isRequired,
};

export default PriceAnalyzerView;
