import React, { useMemo } from "react";
import { DataTable } from "@elliemae/ds-data-table";
import { useLoanDataFieldSelector } from "data/useSelectors";
import { StyledGridWrapper } from "./styled";
import { generateRows } from "./helpers";
import { columns } from "./columns";

const LoanFieldChangesView = () => {
  const loanFieldChangesRaw = useLoanDataFieldSelector(
    "additionalLockInfo.lockCompareResults",
    {}
  );
  const loanFieldChangesArray = useMemo(
    () => Object.values(loanFieldChangesRaw).flat(),
    [loanFieldChangesRaw]
  );

  const parsedData = useMemo(() => generateRows(loanFieldChangesArray), [
    loanFieldChangesRaw,
  ]);

  return (
    <StyledGridWrapper>
      <DataTable
        colsLayoutStyle="auto"
        columns={columns}
        data={parsedData}
        noResultsMessage="No Field Changes"
        height={loanFieldChangesArray.length === 0 && 200}
      />
    </StyledGridWrapper>
  );
};

export default LoanFieldChangesView;
