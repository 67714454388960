import { combineReducers } from "redux";
import screenLoader from "data/screenLoader/reducer";

import globalErrorModal from "data/globalErrorModal/reducer";
import origin from "data/origin/reducer";
import lockSummaryOld from "data/lockSummary/reducer";
import lockConfirm from "data/lockConfirm/reducer";
import EPPS from "common/reducers/AppReducer";
import landing from "data/landing/reducer";
import searchFormReducer from "data/searchForm/reducer";
import personaAccess from "data/personaAccess/reducer";
import lockDeskStatus from "data/lockDeskStatus/reducer";
// New reducers flow -> in progress
import searchForm from "store/searchForm";
import lookups from "store/lookups";

const rootReducer = combineReducers({
  globalErrorModal,
  screenLoader,
  landing,
  EPPS,
  epps: combineReducers({
    origin,
    lockSummary: lockSummaryOld,
    lockConfirm,
    searchForm: searchFormReducer,
  }),
  personaAccess,
  lockDeskStatus,
  eppsData: combineReducers({
    searchForm,
    lookups,
  }),
});

export default rootReducer;
