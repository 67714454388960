import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import queryParams from "utils/queryParams";
import ScreenLoader from "components/ScreenLoader";
import GlobalErrorModal from "components/GlobalErrorModal";
import { startLog } from "utils/logger";
import { buysideRequested } from "utils/constants/ErrorCodes";
import Session, { ALL_SUMMARY_PARAMS } from "services/Session";
import {
  dispatchOriginData,
  dispatchUserData,
  getInitData,
  IS_INVALID_CREDENTIAL,
  IS_INVALID_TOKEN,
  IS_RECONCILE,
  setMockInitData,
} from "app/helpers";
import {
  openNoLoanAmountErrorModal,
  openGlobalErrorModalAction,
} from "data/globalErrorModal/actionCreators";
import Common from "common/services/Common";
import { WarningTriangle } from "@elliemae/ds-icons";
import { lookupsActions } from "store/lookups";
import { RouteContainer } from "../routes/index";

const App = () => {
  const [moveNext, setMoveNext] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initData, setInitData] = useState(undefined);

  const { current: isLO } = useRef(
    queryParams(window.location.search)?.App === "LO"
  );
  const dispatch = useDispatch();

  const fetchData = async (isRefresh) => {
    const data = await getInitData(
      dispatch,
      isRefresh ?? false,
      setMoveNext,
      setIsError
    );
    if (data.code === undefined) {
      dispatchUserData(dispatch);
      await setUserClientRoles();
      dispatch(lookupsActions.getLookupsDataAction({ dispatch }));
    }
    await setInitData(data);
  };

  useEffect(() => {
    startLog(buysideRequested);
    Session.set(ALL_SUMMARY_PARAMS, {});

    if (isLO) {
      fetchData();
    }
    if (!isLO) {
      setMockInitData(dispatch);
      setMoveNext(true);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: Common.closeApplication,
        })
      );
    }
  }, [isError]);

  const setUserClientRoles = async () => {
    const [, rightsResponse] = await Promise.all([
      Common.setUserRoles(),
      Common.setClientRoles(),
    ]);
    if (!rightsResponse) {
      dispatch(
        openGlobalErrorModalAction({
          title: "You have not been assigned a role!",
          errorMessage:
            "You will have limited access to any features until you have been assigned a role. Please contact your administrator.",
          icon: <WarningTriangle size="xxl" color={["warning", "600"]} />,
        })
      );
    }
  };

  useEffect(() => {
    if (initData) {
      const {
        notTokenCredResource,
        errorLoanAmountBlank,
        hasLoanAmount,
        loanAmountBlank,
        lienPosition,
        originData,
        code,
        isRedirectToError,
      } = initData;
      if (!notTokenCredResource) {
        if (errorLoanAmountBlank || !hasLoanAmount) {
          dispatch(openNoLoanAmountErrorModal());
        }
        if (!loanAmountBlank && lienPosition && hasLoanAmount) {
          dispatchOriginData(dispatch, originData, setMoveNext);
        }
      }
      if (initData[IS_RECONCILE] === IS_RECONCILE) {
        fetchData(true);
      }
      if (isRedirectToError) {
        setMoveNext(true);
        setIsError(true);
      }
      if (code && code !== IS_INVALID_TOKEN && code !== IS_INVALID_CREDENTIAL) {
        throw new Error();
      }
    }
  }, [initData]);

  return (
    <>
      <GlobalErrorModal />
      {moveNext && <RouteContainer />}
      <ScreenLoader />
    </>
  );
};
export default App;
