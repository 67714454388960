import validators from "utils/validator/validations/index";
import validate from "utils/validator/index";
import moment from "moment";

export const isString = (property) => typeof property === "string";
export const isObject = (property) => typeof property === "object";
export const isFunction = (property) => typeof property === "function";

// function to........

export const getLabel = (label, customDependencies) => {
  return typeof label === "function" ? label({ customDependencies }) : label;
};

// function to......

export const getValue = (property, data) => {
  if (property && typeof property === "function") {
    return property(data);
  }
  return data;
};

// function to......

export const getInitialValue = (property, data) => {
  if (property && typeof property === "function") {
    return property(data);
  }
  return data;
};

// function to......

export const getParseValue = (property, data) => {
  if (property && typeof property === "function") {
    return property(data);
  }
  return data;
};

// function to......

export const getCustomProperty = (property, data) => {
  if (isFunction(property)) {
    return property(data);
  }
  return isObject(data) ? data.value : data;
};

// function to........

export const replaceValue = (value, replace) => {
  return value
    ? value.replace(`${replace}`, "").replace("%", "").replace(/,/g, "")
    : value;
};

// function to........

export const getMaxLength = (value = "", defaultLength = 0) => {
  if (typeof value === "string") {
    if (value.split(".").length > 1) return defaultLength + 1;
  }
  return defaultLength;
};

// function to......

export const getFeedbackMessage = (isFeedbackMessage, condition) => {
  if (isFeedbackMessage && typeof isFeedbackMessage === "function") {
    return isFeedbackMessage(condition);
  }
  return isFeedbackMessage;
};

// function to.......

export const parseValue = (value, comboBox) => {
  const val = comboBox && (value === null || value === undefined) ? "none" : "";
  return ![undefined, null, "", "-"].includes(value) ? parseFloat(value) : val;
};

// Parses the given value to be used inside a combobox.
export const parseComboboxValue = (value, isMulti) => {
  let newValue = value;
  const forbiddenValues = [undefined, null, "", "-"];

  // Default empty value for non multi
  if (!isMulti && forbiddenValues.includes(value)) {
    newValue = { value: "none", label: "none", dsId: "none", type: "option" };
  }

  // Default empty value for multi
  if (!isMulti && forbiddenValues.includes(value)) {
    newValue = [];
  }

  // Put the multi value inside an array if the
  // given value is not an array.
  if (isMulti && !Array.isArray(value)) {
    newValue = [value];
  }

  return newValue;
};

// function to........

export const getReplace = (value, replace) => {
  const selectReplace = replace ? replace(value) : replaceValue(value, "$");
  return parseValue(selectReplace);
};

// function to........

export const getValidator = (values) => {
  const { value, required, name, customDependencies } = values;
  const getName = String(name).replace(/[0-9]/g, "").replace("-", "");
  const isValidator = validators[getName];
  return isValidator
    ? validate({ [getName]: value }, [isValidator], {
        dependencies: customDependencies,
        required,
      })
    : {};
};

// function to......

export const customOnChange = ({ event, replace, customEvent, ...rest }) =>
  customEvent
    ? {
        [event]: ({ target, value }) =>
          customEvent({
            value: getReplace(value || target?.value, replace),
            ...rest,
          }),
      }
    : {};

// function to......
export const customOnTextBoxChange = ({ event, customEvent, ...rest }) =>
  customEvent
    ? {
        [event]: ({ target, value }) =>
          customEvent({
            value: value || target.value,
            ...rest,
          }),
      }
    : {};

// function to......
export const customOnDateChange = ({
  event,
  dateFormat,
  customEvent,
  ...rest
}) =>
  customEvent
    ? {
        [event]: (newDate) => {
          const strDate = moment(newDate).format(dateFormat);
          return customEvent({
            value: strDate,
            ...rest,
          });
        },
      }
    : {};

// function to.......

export const recursivePropAccess = (obj, propAccess, propValue) => {
  let objRef = { ...obj };
  propAccess.split(".").forEach((prop, i, arr) => {
    if (i < arr.length - 1) objRef = objRef[prop];
    else objRef[prop] = propValue;
  });
  return obj;
};

// function to......

export const getContainerProps = (name, hasError) => ({
  id: `epps-field-id-${name}`,
  "data-field-haserror": !!hasError,
  "data-testid": `epps-field-testid-${name}`,
});

// Takes a JS Date as input and returns HH:MM AM/PM

export const formatTime = (date) => {
  const parsedDate = new Date(date);
  if (date) {
    let hours = parsedDate.getHours();
    let minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    if (hours === 0 || hours === "0") {
      hours = 12; // the hour '0' should be '12'
    }
    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes} ${ampm}`;
  }
  return "";
};

// Takes a JS Date as input and returns MM/DD/YYYY

export const formatDate = (date) => {
  if (date) {
    const parsedDate = new Date(date);
    const year = parsedDate?.getFullYear();
    let month = (1 + parsedDate?.getMonth())?.toString();
    if (month < 10) {
      month = `0${month}`;
    }
    let day = parsedDate.getDate().toString();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${month}/${day}/${year}`;
  }
  return "";
};

export const formatDateTime = (date) => {
  const formatedDate = formatDate(date);
  const formatedTime = formatTime(date);
  if (formatedDate !== "" && formatTime !== "")
    return `${formatedDate} ${formatedTime}`;
  return "";
};
