import React, { useState, useMemo } from "react";
import { isEmpty } from "utils/shared";
import { useRateSelectorLien } from "data/useSelectors";
import { DSPageHeaderV2, PageHeaderTitle } from "@elliemae/ds-page-header";
import ProgramDetailsModal from "view/LockSummary/components/ProgramDetailsModal";
import Session, {
  ORIGIN_TRANSACTION_TYPE,
  IS_VALIDATE,
  ALL_SUMMARY_PARAMS,
} from "services/Session";
import LoanDetails from "../LoanSummaryDetails";
import ProgramDetailsButton from "./ProgramDetailsButton";
import Styled from "./styled";

const SummaryHeader = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { programName, userLender, note, programId } = useRateSelectorLien(
    ["programName", "userLender", "note", "programId"],
    ""
  );
  const loanProgramName = `${userLender || ""} ${programName || ""}`;
  const programInfo = {
    notes: note?.join("<br/><li>"),
    program: loanProgramName,
    programID: programId,
  };
  const isValidate = Session.get(IS_VALIDATE);
  const transactionType = Session.get(ORIGIN_TRANSACTION_TYPE);

  const statusTitle = useMemo(() => {
    const userWentToSearchResult = !isEmpty(Session.get(ALL_SUMMARY_PARAMS));
    let title = "New Lock Request";
    if (isValidate) {
      if (transactionType === "validatelock") title = "Validate";
      else title = "Accept Lock Update";
      if (userWentToSearchResult) {
        title = "Lock Update Request";
      }
    }
    return title;
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const title = loanProgramName && (
    <PageHeaderTitle>{`${statusTitle}: ${loanProgramName}`}</PageHeaderTitle>
  );
  const toolbar = <ProgramDetailsButton onClick={openModal} />;

  return (
    <Styled>
      <DSPageHeaderV2 pageTitle={title} toolbar={toolbar} />
      <LoanDetails />
      {isModalOpen && (
        <ProgramDetailsModal
          isOpen
          toggleModalSlide={toggleModal}
          programInformation={programInfo}
        />
      )}
    </Styled>
  );
};

export default SummaryHeader;
