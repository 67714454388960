import "isomorphic-fetch";
import { TOKEN_KEY } from "common/constants/AppGlobals";
import Session from "../services/Session";

const defaultOpts = {
  tokenType: "Basic",
  token: true,
  external: false,
};

const tokenize = (url, tokens) => {
  let tokenizedUrl = url;
  Object.keys(tokens).forEach((k) => {
    tokenizedUrl = tokenizedUrl.replace(`:${k}`, tokens[k]);
  });
  return tokenizedUrl;
};

const buildHeaders = async (opts, defaults) => {
  const token = opts.customToken;
  const tokenType =
    opts.tokenType !== undefined ? opts.tokenType : defaultOpts.tokenType;

  let authTokenString = "";

  if (token) authTokenString = `${tokenType} ${token.value}`;
  return {
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...defaults.headers,
      ...opts.headers,
      Authorization: authTokenString,
      "Access-Control-Allow-Origin": "*",
    },
  };
};

const isInvalidToken = (result) => {
  if (result.status === 401) {
    return true;
  }
  return false;
};

export default async (
  url,
  opts = defaultOpts,
  tokens = {},
  defaults = {},
  responseFilters = [],
  ignore401 = false
) => {
  let requestHeaders = {};
  let optsMerged = {};
  const urlTokenized = tokenize(url, tokens);

  if (!opts.external) {
    requestHeaders = await buildHeaders(opts, defaults);
    // urlTokenized = `${isEPPS ? eppsBase : defaultBase}${urlTokenized}`
  }
  optsMerged = Object.assign(defaults, opts, requestHeaders);

  let result = await fetch(urlTokenized, optsMerged);
  if (isInvalidToken(result) && !ignore401) {
    if (!opts.external) {
      Session.remove(TOKEN_KEY);
      requestHeaders = await buildHeaders(opts, defaults);
    }
    optsMerged = Object.assign(defaults, opts, requestHeaders);
    result = await fetch(urlTokenized, optsMerged);
  }
  // AnalyticsService.httpResponse(result)
  // passing fetch response through the chain of filters
  if (url.match(/ratesheet/i)) return result;
  return responseFilters.reduce(
    (prevResult, filter) => filter(prevResult),
    result
  );
};
