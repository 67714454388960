import moment from "moment";
import { orderBy } from "lodash";

export const getAvailableRates = (relockData) => {
  const availableRates = [];
  if (relockData == null || relockData === undefined) return null;
  relockData.forEach((item) => {
    if (item != null) {
      availableRates.push(...item.ratesBestEx);
    }
  });
  return orderBy(availableRates, ["rate"], ["asc"]);
};

export const getDefaultRate = (rateList, specificRateDataID) => {
  const filteredDefultRate = rateList.filter(
    (item) => item.rateDataID === specificRateDataID
  );
  return filteredDefultRate.length > 0 ? filteredDefultRate[0].rate : null;
};

export const formatDate = (dateStr) => {
  return moment.tz(dateStr, "America/New_York").format("MM/DD/YYYY");
};

export const formatTime = (date) => {
  return moment.tz(date, "America/New_York").format("hh:mm A");
};

export const formatRate = (p) => {
  if (p === null || p === undefined || Number.isNaN(p)) return "--";
  if (p === false) return "0.000%";
  return `${p.toFixed(3)}%`;
};

export const checkDuplicateInObject = (originDates) => {
  const eventLockDates = [];

  if (originDates) {
    const cloneOriginDates = [...originDates];
    cloneOriginDates.sort((a, b) => {
      return (
        moment.tz(b.requestedDate, "America/New_York") -
        moment.tz(a.requestedDate, "America/New_York")
      );
    });
    const testObject = {};
    if (cloneOriginDates && cloneOriginDates.length > 0) {
      cloneOriginDates.forEach((item) => {
        const copyitem = { ...item };
        const itemPropertyName = formatDate(copyitem.requestedDate);
        if (itemPropertyName in testObject) {
          copyitem.duplicate = true;
          copyitem.date = itemPropertyName;
          copyitem.time = formatTime(copyitem.requestedDate);
        } else {
          testObject[itemPropertyName] = copyitem;
          copyitem.duplicate = false;
          copyitem.date = itemPropertyName;
          copyitem.time = formatTime(copyitem.requestedDate);
        }
        eventLockDates.push(copyitem);
      });
    }
  }
  return eventLockDates;
};
export const generateRateFilters = (rateList) => {
  const rateOptionList = [];
  if (rateList != null) {
    rateList.map((item) =>
      rateOptionList.push({ id: item.rate, label: formatRate(item.rate) })
    );
  }
  return rateOptionList;
};

export const filterRates = (rates, selectedRate) => {
  return rates.filter((item) => item.rate === selectedRate);
};

export const generateLockUpdateEvent = (
  relockData,
  selRate,
  relockEventDates
) => {
  if (relockData != null && relockData.length > 0) {
    const lockUpdateEvent = {};
    relockData.forEach((item, index) => {
      if (item === null) {
        lockUpdateEvent[relockEventDates[index].requestedDate] = null;
      }
      if (item != null) {
        const filteredEventData = filterRates(item.ratesBestEx, selRate);
        if (filteredEventData.length > 0) {
          lockUpdateEvent[
            relockEventDates[index].requestedDate
          ] = filteredEventData;
        } else {
          lockUpdateEvent[relockEventDates[index].requestedDate] = null;
        }
      }
    });
    return lockUpdateEvent;
  }
  return null;
};
