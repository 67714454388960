import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { func, arrayOf, number, shape } from "prop-types";
import {
  useLoanInformationField,
  useSummaryDetailsField,
} from "data/useSelectors";
import { getAdjustmentDetails } from "view/AdjustmentDetails/AdjustmentDetailsAction";
import { transformRateSelectorDataToSlide } from "view/SearchResult/components/GridPriceDetails/helpers";
import { ModalHeader } from "@elliemae/ds-modal-slide";
import ModalSlide from "components/ModalSlide";
import GridPriceDetails from "view/SearchResult/components/GridPriceDetails/GridPriceDetails";
import { DataTable } from "@elliemae/ds-data-table";
import { StyledDataTableWrapper } from "view/global/styles/styled";
import { getAllRatesColumns } from "./helpers";

function AllRatesGrid({
  rates,
  onSelectRate,
  programId,
  targetRow,
  selectedProduct,
}) {
  const dispatch = useDispatch();
  const lienPosition = useLoanInformationField("lienPosition", 1);
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const {
    lien: { armIndexValue = 0, arm = false } = {},
    loan: { mortgageType = "" } = {},
  } = useSummaryDetailsField();
  const heloc = useMemo(() => mortgageType === "HELOC", [mortgageType]);
  const [slide, setSlide] = useState({
    open: false,
    data: null,
    rowData: null,
  });

  const onClickPrice = (rowData) =>
    setSlide({
      rowData,
      open: false,
      data: null,
    });

  const { relockRequest } = useSelector(({ EPPS }) => EPPS.loanInfo || {});
  const columns = useMemo(
    () =>
      getAllRatesColumns({
        relockRequest,
        programId,
        targetRow,
        onClickPrice,
      }),
    [relockRequest, targetRow]
  );
  useEffect(() => {
    if (slide.rowData && !slide.open) {
      const { rateDataID: rateDataId } = slide.rowData;
      const searchParams = { loanId, programId, rateDataId, lienPosition };

      dispatch(getAdjustmentDetails(searchParams)).then((response) => {
        if (response && response.adjustments) {
          const dataSlide = transformRateSelectorDataToSlide(
            response.adjustments,
            armIndexValue
          );
          dataSlide.showMarginTable = arm || heloc;
          dataSlide.bonaFideTestResults = response.bonaFideTestResults;
          setSlide({
            open: true,
            data: dataSlide,
            rowData: null,
          });
        }
      });
    }
  }, [slide]);

  const selection = selectedProduct && { [selectedProduct?.rateDataID]: true };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "85%",
        paddingTop: "8px",
      }}
    >
      <ModalSlide
        isOpen={slide.open}
        header={
          <ModalHeader
            title="Pricing Details"
            onClose={() => setSlide({ open: false, data: null, rowData: null })}
          />
        }
      >
        {slide.data && (
          <div style={{ padding: 16 }}>
            <GridPriceDetails isSlide data={slide.data} />
          </div>
        )}
      </ModalSlide>
      <StyledDataTableWrapper isAllRatesGrid>
        <DataTable
          columns={columns}
          data={rates}
          uniqueRowAccessor="rateDataID"
          textWrap="wrap-all"
          colsLayoutStyle="auto"
          selection={selection}
          noSelectionColumn
          onRowClick={onSelectRate}
        />
      </StyledDataTableWrapper>
    </div>
  );
}

AllRatesGrid.defaultProps = {
  targetRow: null,
  selectedProduct: null,
};

AllRatesGrid.propTypes = {
  rates: arrayOf().isRequired,
  onSelectRate: func.isRequired,
  programId: number.isRequired,
  targetRow: shape({
    apr: number,
    bRatio: number,
    basePrice: number,
    bonaFideDiscountPointsFactor: number,
    bonaFideDiscountPointsTest: number,
    comp: number,
    compVal: number,
    delta: number,
    holdback: number,
    margin: number,
    pnI: number,
    price: number,
    priceVal: number,
    rate: number,
    rateDataID: number,
  }),
  selectedProduct: shape({
    apr: number,
    bRatio: number,
    basePrice: number,
    bonaFideDiscountPointsFactor: number,
    bonaFideDiscountPointsTest: number,
    comp: number,
    compVal: number,
    delta: number,
    holdback: number,
    margin: number,
    pnI: number,
    price: number,
    priceVal: number,
    rate: number,
    rateDataID: number,
  }),
};

export default AllRatesGrid;
