import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  StyledEppsWrapper,
  StyledEppsParentView,
  StyledEppsFormWrapper,
  StyledEppsContainerBody,
  StyledEppsContainerHeader,
} from "view/global/styles/styled";
import { lookupsActions } from "store/lookups";
import LoanInformationForm from "./components/LoanInformationForm";
import ProductInformationForm from "./components/ProductInformationForm";
import FinanceInformationForm from "./components/FinanceInformationForm";
import SubjectPropertyInformationForm from "./components/SubjectPropertyInformationForm";
import CustomFieldsForm from "./components/CustomFieldsForm";
import ErrorBanner from "./components/ErrorBanner";
import HeaderForm from "./components/HeaderForm";
import FooterForm from "./components/FooterForm";

const SearchForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(lookupsActions.getGeocodesDataAction({ dispatch }));
    dispatch(lookupsActions.getLookupsDataAction({ dispatch }));
  }, []);

  return (
    <StyledEppsParentView>
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <StyledEppsContainerHeader>
            <ErrorBanner />
          </StyledEppsContainerHeader>
          <HeaderForm />
          <StyledEppsFormWrapper>
            <LoanInformationForm />
            <CustomFieldsForm />
            <ProductInformationForm />
            <FinanceInformationForm />
            <SubjectPropertyInformationForm />
          </StyledEppsFormWrapper>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterForm />
    </StyledEppsParentView>
  );
};

export default SearchForm;
