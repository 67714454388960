import React from "react";
import { useLookup } from "data/useSelectors";
import Session, { IS_BUYSIDE } from "services/Session";
import QuoteInsuranceButton from "./components/QuoteInsuranceButton";

const QuoteInsuranceButtonWrapper = () => {
  const clientSettings = useLookup("clientSettings") || [];
  const isBuyside = Session.get(IS_BUYSIDE);
  const isMIQuotesEnabled =
    clientSettings[0]?.options?.isMIQuotesEnabled || false;

  return isMIQuotesEnabled && !isBuyside && <QuoteInsuranceButton />;
};

export default QuoteInsuranceButtonWrapper;
