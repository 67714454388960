export const getDefaultFocusedDate = (date) => {
  /* TODO work around for a dimsum bug not accepting day 31,
   * this should be removed as soon as we update to the latest dimsum version
   */
  if (date && !date.includes("_")) {
    // TODO please delete me :)
    if (date.includes("/31/")) {
      return date.replace("/31/", "/30/");
    }
    return date;
  }
  const newDate = new Date();
  // TODO please delete me :)
  if (newDate.getDate() === 31) {
    newDate.setDate(30);
  }
  const parsedCurrentDateValue = newDate.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return parsedCurrentDateValue;
};
