import { useSelector, shallowEqual } from "react-redux";
import { isShallowEqual } from "utils/shared";
import * as selector from "utils/selectors";

// LoanData useSelectors

export const useLoanDataFieldSelector = (field, defaultValue = null) =>
  useSelector(selector.selectLoanDataField(field, defaultValue), shallowEqual);

export const useLoanInformationField = (fieldName, defaultValue) =>
  useSelector(
    selector.selectLoanInformationField(fieldName, defaultValue),
    shallowEqual
  );

export const useSummaryDetailsField = () =>
  useSelector(selector.selectSummaryDetailsField(), shallowEqual);

export const useLoanDataSelector = (fieldName, defaultValue) =>
  useSelector(selector.selectLoanInfo(fieldName, defaultValue), shallowEqual);

export const useLoanPropertiesSelector = () =>
  useSelector(
    ({ EPPS: { loanProperties = {} } }) => loanProperties,
    shallowEqual
  );

export const useErrorsSelector = () =>
  useSelector(({ EPPS: { errors = [] } }) => errors, shallowEqual);

export const useOriginalLoanInformationSelector = (fieldName, defaultValue) =>
  useSelector(
    selector.selectOriginalLoanInformation(fieldName, defaultValue),
    isShallowEqual
  );

export const useRateSelector = () =>
  useSelector(
    ({ epps: { lockSummary: { rateSelector } = {} } = {} }) => rateSelector,
    shallowEqual
  );

export const useClientSettingsDataSelector = () =>
  useSelector(
    ({ epps: { lockSummary: { clientSettingsDataSelector } = {} } = {} }) =>
      clientSettingsDataSelector,
    shallowEqual
  );

export const useSelectedProductSelector = () =>
  useSelector(
    ({ epps: { lockSummary } }) => lockSummary.selectedProduct,
    shallowEqual
  );

export const usePersonaAllowedActionsSelector = () =>
  useSelector(
    ({ personaAccess }) => personaAccess.allowedActions,
    shallowEqual
  );

export const useLockDeskStatusActionsSelector = () =>
  useSelector(({ lockDeskStatus }) => lockDeskStatus, shallowEqual);

export const useBorrowerFinancialHistory = (fieldName, defaultValue) =>
  useSelector(
    selector.selectborrowerFinancialHistory(fieldName, defaultValue),
    shallowEqual
  );

export const useRateSelectorLoan = (fieldName, defaultValue) =>
  useSelector(
    selector.selectRateSelectorLoan(fieldName, defaultValue),
    shallowEqual
  );

export const useRateSelectorLien = (fieldName, defaultValue) =>
  useSelector(
    selector.selectRateSelectorLien(fieldName, defaultValue),
    shallowEqual
  );

export const useCompareDataSelector = () =>
  useSelector(({ epps: { lockSummary: { pricingChanges = {} } } }) => {
    return pricingChanges;
  }, isShallowEqual);

export const useLookup = (lookup, defaultValue) =>
  useSelector(selector.selectLookups(lookup, defaultValue), shallowEqual);

export const useRateSelectorLoanInfo = () =>
  useSelector(
    ({
      epps: {
        lockSummary: { rateSelector: { loan = {} } = {} },
      },
    }) => {
      return loan;
    },
    isShallowEqual
  );

export const useRateSelectorLienInfo = () =>
  useSelector(
    ({
      epps: {
        lockSummary: { rateSelector: { lien = {} } = {} },
      },
    }) => {
      return lien;
    },
    isShallowEqual
  );

export const useSearchFormSelector = (name, def) =>
  useSelector(selector.selectSearchForm(name, def));

export const useExtraFieldsSelector = () =>
  useSelector(({ epps: { searchForm } }) => searchForm.extraFields);

export const useFilters = (index) =>
  useSelector(({ EPPS: { filters = [] } }) => {
    return filters[index] || [];
  }, shallowEqual);

export const useUserData = () =>
  useSelector(({ EPPS: { userData = {} } }) => {
    return userData;
  });

export const useLoanProgramsSelector = () =>
  useSelector(({ EPPS: { loanPrograms = [] } }) => loanPrograms, shallowEqual);

export const useLockConfirmFieldSelector = (fieldName, defaultValue) =>
  useSelector(({ epps: { lockConfirm } }) => {
    return lockConfirm[fieldName] || defaultValue;
  }, shallowEqual);

export const useLoanInformationSelector = () =>
  useSelector(selector.selectLoanData());

export const useLockedProgramSelector = () =>
  useSelector(
    ({
      epps: {
        origin: { lockedProgram },
      },
    }) => lockedProgram
  );

export const useTemplateData = () =>
  useSelector(
    ({
      EPPS: {
        userData = {},
        loanPrograms = {},
        loanInfo: { borrowers = [] },
        originalLoanInformation: { lockRequests = [] },
      } = {},
    }) => ({
      userData,
      loanPrograms,
      borrowers,
      lockRequests,
    }),
    isShallowEqual
  );

export const useOriginalLoanLockRequests = () =>
  useSelector(
    ({ EPPS: { originalLoanLockRequest } = {} }) =>
      originalLoanLockRequest?.lockRequests
  );

export const useHedgeLoanSelector = () =>
  useSelector(
    ({ epps: { lockSummary } }) => lockSummary.isLoanHedged,
    shallowEqual
  );

export const useAdjustmentDetailsCache = () =>
  useSelector(
    ({ EPPS: { adjustmentDetailsCache } }) => adjustmentDetailsCache,
    shallowEqual
  );

export const usePropertyDetailsCache = () =>
  useSelector(
    ({ EPPS: { propertyDetailsCache } }) => propertyDetailsCache,
    shallowEqual
  );

export const useLockRequestComments = () =>
  useSelector(
    ({ EPPS: { originalLoanInformation } }) =>
      originalLoanInformation.rateLock?.requestComments,
    shallowEqual
  );
