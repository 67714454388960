import { safeSum, isValueNaN } from "utils/shared";
import { isCashOutRefinance } from "./validations";

export const isFirstMortgage = ({ customDependencies }) => {
  const { lienPosition } = customDependencies;
  return lienPosition === 1;
};

export const isSubordinateHELOC = ({ customDependencies }) => {
  const {
    lienPosition,
    initialOtherHELOCDraws,
    initialOtherHELOCLines,
  } = customDependencies;
  if (
    (!isValueNaN(initialOtherHELOCDraws) ||
      !isValueNaN(initialOtherHELOCLines)) &&
    lienPosition === 1
  ) {
    return false;
  }
  return lienPosition === 1;
};

export const isFirstMortgageAndHeloc = ({ customDependencies }) => {
  const { lienPosition, isHELOC } = customDependencies;
  return !(lienPosition === 1 && isHELOC);
};

export const isSecondMortgage = ({ customDependencies }) => {
  const { lienPosition } = customDependencies;
  return lienPosition === 2;
};

export const getLoanAmountLabel = ({ customDependencies }) => {
  const { lienPosition, isHELOC } = customDependencies;
  let label = "Loan Amount";
  if (lienPosition === 1 && isHELOC) {
    label = "Loan Amount (Initial Draw)";
  }
  if (lienPosition === 2) {
    label = "1st Mortgage Balance";
  }
  return label;
};

export const getPropertyValueLabel = ({ customDependencies }) => {
  const { lienPosition, loanPurpose } = customDependencies;
  let label = "Property Value";
  if (lienPosition === 1 && loanPurpose === 1) {
    label = "Purchase Price/Property Value";
  }
  return label;
};

export const isGovtLoan = ({ customDependencies }) => {
  const { standardProducts } = customDependencies;
  return (
    standardProducts.includes(2) ||
    standardProducts.includes(3) ||
    standardProducts.includes(4)
  );
};

export const getUpfrontGovtFeeLabel = (customDependencies, labelNum) => {
  const { standardProducts } = customDependencies.customDependencies;
  const labelMappings = {
    1: {
      2: "UFMIP Factor",
      3: "Funding Fee Factor",
      4: "Guarantee Fee Factor",
      default: "Description 1",
    },
    2: {
      2: "UFMIP Amount",
      3: "Funding Fee Amount",
      4: "Guarantee Fee Amount",
      default: "Description 2",
    },
    3: {
      2: "UFMIP Financed Amount",
      3: "Funding Fee Financed Amount",
      4: "Guarantee Fee Financed Amount",
      default: "Description 3",
    },
    4: {
      2: "UFMIP Paid in Cash",
      3: "Funding Fee Paid in Cash",
      4: "Guarantee Fee Paid in Cash",
      default: "Description 4",
    },
  };
  const labels = labelMappings[labelNum];
  const labelKey = standardProducts.find((product) => labels[product]);
  return labelKey ? labels[labelKey] : labels.default;
};

export const getFloredValueTextInput = (val) => {
  const value = !["", null].includes(val) ? Math.floor(val) : "";
  return value === null || value === undefined ? "" : value;
};

export const calculateTotalLoanAmount = (
  firstMortgageAmount,
  financedAmount,
  govtFeeFinancedAmount
) => {
  let result = 0;
  const ParsedLoanAmount = parseInt(firstMortgageAmount, 10) || 0;
  const ParsedFinancedAmount = parseInt(financedAmount, 10) || 0;
  const ParsedGovtFeeFinancedAmount = parseFloat(govtFeeFinancedAmount) || 0;
  result = safeSum(
    ParsedLoanAmount,
    Math.floor(ParsedFinancedAmount),
    ParsedGovtFeeFinancedAmount
  );
  return result;
};

export const handleTotalAmountValue = ({ customDependencies }) => {
  const {
    firstMortgageAmount,
    financedAmount,
    feeAmountFinanced,
  } = customDependencies;
  const value = calculateTotalLoanAmount(
    firstMortgageAmount,
    financedAmount,
    feeAmountFinanced
  );
  return value;
};

export const isHCLTVHidden = ({ customDependencies }) => {
  const { isHELOC, lienPosition } = customDependencies;
  return !(isHELOC || lienPosition === 2);
};

export const getCheckboxesSpan = (customDependencies) => {
  const isHcltvHidden = isHCLTVHidden({ customDependencies });
  const isCashoutHidden = isCashOutRefinance({ customDependencies });
  if (isHcltvHidden && isCashoutHidden) return 8;
  if (isHcltvHidden || isCashoutHidden) return 7;
  return 6;
};

export const calculateGovtFeeAmount = (baseLoanAmount, govtFeeFactor) => {
  return parseFloat((baseLoanAmount * (govtFeeFactor / 100)).toFixed(2));
};

export const getUpfrontFeeValue = (customDependencies, other) => {
  const govtUpfrontFees = other.govtUpfrontFees || [];
  // 11,12,13 are the fee ids received from lookups api
  const valueMapping = {
    2: "FHA", // FHA
    3: "VA", // va
    4: "USDA", // USDA
  };

  const standardProducts = customDependencies?.standardProducts || [];
  const latestStandardProduct = standardProducts.find(
    (product) => valueMapping[product]
  );

  // if VA
  if (latestStandardProduct === 3) return getVAFeeAmount(other);

  const upfrontFee = govtUpfrontFees.find(
    (fee) => fee.feeName === valueMapping[latestStandardProduct]
  );

  if (upfrontFee?.value) return upfrontFee.value;
  return 0;
};

const getVAFeeAmount = (dependencies) => {
  const loanPurposeMap = {
    1: "Purchase",
    2: "VA IRRRL",
    3: "C/O Refinance",
    4: "Construction",
    5: "Construction-Perm",
  };
  const { govtUpfrontFees, loanPurpose, vaFirstTimeUse, ltv } = dependencies;

  if (govtUpfrontFees && govtUpfrontFees.length === 0) return 0;
  // get the filtered values for the loan purpose
  const vaFilteredValues = govtUpfrontFees.filter(
    (fee) => fee.feeName === loanPurposeMap[loanPurpose]
  );
  if (!vaFilteredValues || vaFilteredValues.length === 0) return 0;
  const downPaymentPerc = convertLTVToDownPayment(ltv);
  let vaIsInRange = [];
  // for VA IRRRL and C/O Refinance, there is no min down and max down
  if (loanPurpose === 2 || loanPurpose === 3)
    vaIsInRange.push(vaFilteredValues[0]);
  else
    vaIsInRange = vaFilteredValues.filter(
      (fee) => downPaymentPerc >= fee.minDown && downPaymentPerc < fee.maxDown
    );

  if (!vaIsInRange || vaIsInRange.length === 0) return 0;
  return vaFirstTimeUse
    ? vaIsInRange[0]?.firstUse
    : vaIsInRange[0]?.afterFirstUse;
};

const convertLTVToDownPayment = (ltv) => {
  return 100 - ltv || 0;
};
