import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getBuysideDataAction,
  getValidateDataAction,
  getRateSelectorAction,
  getSettingAction,
} from "data/lockSummary/actionCreators";
import { stopLog } from "utils/logger";
import { buysideRequested } from "utils/constants/ErrorCodes";
import {
  StyledEppsParentView,
  StyledEppsContainerBody,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import { isEmpty } from "utils/shared";
import Session, { IS_VALIDATE, ALL_SUMMARY_PARAMS } from "services/Session";
import CompareRates from "./components/CompareRates";
import FooterActionButtons from "./components/FooterActionButtons";
import LockConfirm from "./components/LockConfirm";

const LockConfirmWrapper = () => {
  const dispatch = useDispatch();
  const [isCompareRatesView, setIsCompareRatesView] = useState(false);
  const isValidate = Session.get(IS_VALIDATE);
  const { current: userWentToSearchResult } = useRef(
    !isEmpty(Session.get(ALL_SUMMARY_PARAMS))
  );
  const initData = async () => {
    if (!userWentToSearchResult) {
      await dispatch(getSettingAction());
      if (isValidate) {
        await dispatch(getValidateDataAction());
      }
      if (!isValidate) {
        dispatch(getBuysideDataAction());
      }
    }
    if (userWentToSearchResult) {
      dispatch(getRateSelectorAction());
    }
  };

  useEffect(() => {
    initData();
    stopLog(buysideRequested);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isCompareRatesView && (
        <CompareRates handleBack={() => setIsCompareRatesView(false)} />
      )}
      {!isCompareRatesView && (
        <StyledEppsParentView>
          <StyledEppsContainerBody>
            <StyledEppsWrapper>
              <LockConfirm setIsCompareRatesView={setIsCompareRatesView} />
            </StyledEppsWrapper>
          </StyledEppsContainerBody>
          <FooterActionButtons />
        </StyledEppsParentView>
      )}
    </>
  );
};

export default LockConfirmWrapper;
