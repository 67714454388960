import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { setStatusFail } from "data/lockSummary/actions";
import StyledStatusFailMessage from "./styled";

const useSearchFormSelector = () =>
  useSelector(
    ({ epps: { lockSummary: { getStatus = {} } = {} } }) => getStatus
  );

const StatusFailMessage = () => {
  const dispatch = useDispatch();
  const getStatusData = useSearchFormSelector();

  return (
    <StyledStatusFailMessage>
      <DSBanner
        body={getStatusData.message}
        containerProps={{ id: "ls-status-banner" }}
        isOpen={getStatusData.error}
        label="Error"
        onClose={() =>
          dispatch(
            setStatusFail({
              code: "",
              message: "",
              error: false,
            })
          )
        }
        type={BANNER_TYPES.DANGER}
      />
    </StyledStatusFailMessage>
  );
};

export default StatusFailMessage;
