import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { useLockedProgramSelector } from "data/useSelectors";
import { setLockedProgramData } from "data/origin/actions";
import { PATH_SEARCH_RESULTS } from "routes/constants";
import { setRedirectTabAction } from "data/landing/actions";

const bodyText = {
  unavailable: " is no longer available. Please select a new product.",
  ineligible: " is ineligible.",
  ineligibleRate:
    ": The interest rate that you selected for this program is no longer available.",
};

const WarningProgramStatus = () => {
  const dispatch = useDispatch();
  const { status = "", programName = "" } = useLockedProgramSelector();
  const tabId = useSelector(({ landing }) => landing.tabId, shallowEqual);

  const viewDetails = (event) => {
    event.preventDefault();
    dispatch(setLockedProgramData({ focusIneligible: true }));
    if (tabId !== PATH_SEARCH_RESULTS)
      dispatch(setRedirectTabAction(PATH_SEARCH_RESULTS));
  };

  return (
    <DSBanner
      body={`${programName}${bodyText[status]}`}
      showCloseButton={false}
      isOpen={bodyText[status] !== undefined}
      label="Warning"
      data-testid="WarningProgramStatus-all-components"
      type={BANNER_TYPES.WARNING}
      actionLink={
        status === "ineligible"
          ? {
              label: "View Details",
              onClick: viewDetails,
            }
          : {}
      }
    />
  );
};

export default WarningProgramStatus;
