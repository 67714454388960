import React, { useState, memo, useMemo } from "react";
import moment from "moment";
import { get } from "lodash";
import { DSRibbon } from "@elliemae/ds-ribbon";
import { Property, HelpCircleSmall } from "@elliemae/ds-icons";
import { DSTooltipV2 } from "@elliemae/ds-tooltip";
import { currencyWithDecimals } from "utils/shared";
import {
  useLookup,
  useLoanDataSelector,
  useLoanPropertiesSelector,
  useOriginalLoanInformationSelector,
  useLoanInformationField,
} from "data/useSelectors";
import { calculateTotalLoanAmount } from "view/SearchForm/components/LoanInformationForm/utils/helpers";
import { getLookupValue, getLockStatusDetails } from "./helpers";
import { StyledRibbon } from "./styled";

function TopRibbon() {
  const [loanData] = useState(useLoanDataSelector());
  const [loanProperties] = useState(useLoanPropertiesSelector());
  const [originalLoanInformation] = useState(
    useOriginalLoanInformationSelector()
  );
  const financedAmount = useLoanInformationField("financedAmount", 0);
  const firstMortgageAmount = useLoanInformationField("firstMortgageAmount", 0);

  const totalLoanAmount = useMemo(
    () => calculateTotalLoanAmount(firstMortgageAmount, financedAmount),
    []
  );

  const { property, borrowers, loanInformation } = loanData;
  const { loanAmortizationType, purchasePriceAmount } = loanProperties;

  const {
    loanProgramName,
    applications: originalLoanInfoApplications,
    ltv,
    combinedLtv,
    hcltvHtltv,
    loanProductData = {},
    requestedInterestRatePercent,
  } = originalLoanInformation;
  const { lockStatusValue, lockIcon } = getLockStatusDetails();

  let ficoValue = get(borrowers, "[0].borrowerFinanancial.creditScore");
  if (!ficoValue) {
    ficoValue = "---";
  }

  let address = "";
  if (property.streetAddress) {
    address += property.streetAddress;
  }
  if (property.city) {
    address += ` ${property.city},`;
  }
  if (property.state) {
    address += ` ${property.state}`;
  }
  if (property.zip) {
    address += ` ${property.zip}`;
  }
  if (address === "") {
    address = "No Subject Property";
  }

  const loanPurpose = get(loanInformation, "loanPurpose");
  const estValue = get(property, "value");

  let topRatioPercent = get(
    originalLoanInfoApplications,
    "[0].topRatioPercent"
  );
  if (topRatioPercent) {
    topRatioPercent = Number(topRatioPercent).toFixed(3);
  } else {
    topRatioPercent = "0.000";
  }

  let bottomRatioPercent = get(
    originalLoanInfoApplications,
    "[0].bottomRatioPercent"
  );
  if (bottomRatioPercent) {
    bottomRatioPercent = Number(bottomRatioPercent).toFixed(3);
  } else {
    bottomRatioPercent = "0.000";
  }

  let lienPosition = "Lien Position";
  if (loanInformation.lienPosition === 1) {
    lienPosition = "First";
  } else if (loanInformation.lienPosition === 2) {
    lienPosition = "Second";
  }

  const InfoIcon = () => (
    <span>
      Info{" "}
      <HelpCircleSmall
        size="s"
        color={["brand-primary", "600"]}
        style={{ margin: "0 auto" }}
      />
    </span>
  );

  return (
    <StyledRibbon>
      <DSRibbon.RibbonWrapper>
        <DSRibbon.RibbonRow alignLeft>
          <DSRibbon.RibbonCol>
            <DSTooltipV2
              title="Loan data to the right reflects current loan data in Encompass"
              zIndex={5000}
              triggerComponent={<DSRibbon.RibbonCard value={<InfoIcon />} />}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard value={ficoValue} additionalLabel="FICO" />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              icon={<Property style={{ paddingTop: "4px" }} size="m" />}
              value={address}
              additionalLabel={`${getLookupValue(
                useLookup("unitTypes"),
                get(property, "unitTypeId"),
                "Property Type"
              )} | ${getLookupValue(
                useLookup("propertyUse"),
                get(property, "use"),
                "Occupancy"
              )}`}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={currencyWithDecimals(estValue, 2) || "$0.00"}
              additionalLabel="Est. Value"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${
                requestedInterestRatePercent
                  ? requestedInterestRatePercent.toFixed(3)
                  : "0.000"
              }%`}
              additionalLabel="Note Rate"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              icon={lockIcon}
              value={lockStatusValue}
              additionalLabel="Lock Status"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={loanProgramName || "No Loan Program"}
              additionalLabel={loanAmortizationType || "Amortization"}
            />
          </DSRibbon.RibbonCol>
          {loanPurpose === 1 && (
            <DSRibbon.RibbonCol data-testid="TopRibbon-all-components">
              <DSRibbon.RibbonCard
                value={currencyWithDecimals(purchasePriceAmount, 2) || "$0.00"}
                additionalLabel="Purchase Price"
              />
            </DSRibbon.RibbonCol>
          )}
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={currencyWithDecimals(totalLoanAmount, 2) || "$0.00"}
              additionalLabel="Total Loan Amount"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${topRatioPercent}/${bottomRatioPercent}`}
              additionalLabel="DTI"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={`${ltv ? Number(ltv).toFixed(3) : "0.000"}/${
                combinedLtv ? Number(combinedLtv).toFixed(3) : "0.000"
              }/${hcltvHtltv ? Number(hcltvHtltv).toFixed(3) : "0.000"}`}
              additionalLabel="LTV / CLTV / HCLTV"
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={getLookupValue(
                useLookup("loanUsage"),
                get(loanInformation, "loanPurpose"),
                "Loan Purpose"
              )}
              additionalLabel={lienPosition}
            />
          </DSRibbon.RibbonCol>
          <DSRibbon.RibbonCol>
            <DSRibbon.RibbonCard
              value={
                loanProductData.loanScheduledClosingDate
                  ? moment(loanProductData.loanScheduledClosingDate).format(
                      "MM/DD/YYYY"
                    )
                  : "--"
              }
              additionalLabel="Est Closing Date"
            />
          </DSRibbon.RibbonCol>
        </DSRibbon.RibbonRow>
      </DSRibbon.RibbonWrapper>
    </StyledRibbon>
  );
}

export default memo(TopRibbon);
