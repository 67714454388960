export const SET_RATE_SELECTOR = "@lockSummary/SET_RATE_SELECTOR";
export const SET_CLIENT_SETTINGS_DATA_SELECTOR =
  "@lockSummary/SET_CLIENT_SETTINGS_DATA_SELECTOR";
export const SET_CURRENT_RATE_SELECTOR =
  "@lockSummary/SET_CURRENT_RATE_SELECTOR";
export const SET_COMPARE_DATA = "@lockSummary/SET_COMPARE_DATA";
export const SET_DIFF_ELEMENTS = "@lockSummary/SET_DIFF_ELEMENTS";
export const SET_ENCOMPASS_ELEMENTS = "@lockSummary/SET_LOAN_ELEMENTS";
export const SET_STATUS_FAIL = "@lockSummary/SET_STATUS_FAIL";
export const SET_SETTINGS = "@lockSummary/SET_SETTINGS";
export const SELECT_PRODUCT = "@lockSummary/SELECT_PRODUCT";
export const SELECT_PRICE = "@lockSummary/SELECT_PRICE";
// TODO aren't these actions below misplaced?
export const SET_GEOCODES = "@searchResult/SET_GEOCODES";
export const SET_LOAN_ID = "@searchResult/SET_LOAN_ID";
export const SET_COMMENTS = "@searchResult/SET_COMMENTS";
export const SET_PRICE_TABLE = "@searchResult/SET_PRICE_TABLE";
export const SET_LOAN_LIMITS = "@searchResult/SET_LOAN_LIMITS";
export const SET_IS_LOAN_HEDGED = "@searchResult/SET_IS_LOAN_HEDGED";

export const setRateSelectorAction = (payload) => ({
  type: SET_RATE_SELECTOR,
  payload,
});

export const setClientSettingsDataAction = (payload) => ({
  type: SET_CLIENT_SETTINGS_DATA_SELECTOR,
  payload,
});

export const setCurrentRateSelectorAction = (payload) => ({
  type: SET_CURRENT_RATE_SELECTOR,
  payload,
});

export const setCompareDataAction = (payload) => ({
  type: SET_COMPARE_DATA,
  payload,
});

export const setDiffElementsAction = (payload) => ({
  type: SET_DIFF_ELEMENTS,
  payload,
});

export const setEncompassElementsAction = (payload) => ({
  type: SET_ENCOMPASS_ELEMENTS,
  payload,
});

export const setGeoCodesAction = (payload) => ({
  type: SET_GEOCODES,
  payload,
});

export const setStatusFail = (payload) => ({
  type: SET_STATUS_FAIL,
  payload,
});

export const setLoanId = (payload) => ({
  type: SET_LOAN_ID,
  payload,
});

export const setComments = (payload) => ({
  type: SET_COMMENTS,
  payload,
});

export const setPriceTable = (payload) => ({
  type: SET_PRICE_TABLE,
  payload,
});

export const selectProduct = (payload) => ({
  type: SELECT_PRODUCT,
  payload,
});

export const selectPrice = (payload) => ({
  type: SELECT_PRICE,
  payload,
});

export const setSettings = (payload) => ({
  type: SET_SETTINGS,
  payload,
});

export const setLoanLimits = (payload) => ({
  type: SET_LOAN_LIMITS,
  payload,
});

export const setIsLoanHedged = (payload) => ({
  type: SET_IS_LOAN_HEDGED,
  payload,
});
