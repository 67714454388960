export const GIFT_AMOUNT = [
  {
    path: "property",
    values: ["value"],
  },
];

export const GIFT_PERCENT = [
  {
    path: "loanInformation",
    values: ["giftForDownPayment"],
  },
  {
    path: "property",
    values: ["value"],
  },
];

export const EXTRA_GIFT_AMOUNT = [
  {
    path: "initialValues",
    values: ["initialGiftForDownPayment"],
  },
];

export const SELLER_AMOUNT = [
  {
    path: "property",
    values: ["value"],
  },
];

export const SELLER_PERCENT = [
  {
    path: "loanInformation",
    values: ["sellerConsessionForDownPayment"],
  },
  {
    path: "property",
    values: ["value"],
  },
];

export const EXTRA_SELLER_AMOUNT = [
  {
    path: "initialValues",
    values: ["initialSellerConsessionForDownPayment"],
  },
];
