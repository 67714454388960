import React, { useEffect, useState, memo, useRef } from "react";
import { ToastType, toast, DSToast } from "@elliemae/ds-toast";
import { Grid } from "@elliemae/ds-grid";
import { useDispatch } from "react-redux";
import Common from "common/services/Common";

import {
  InfoCircle,
  WarningSquare,
  AlertsDetailFill,
} from "@elliemae/ds-icons";
import {
  useLockDeskStatusActionsSelector,
  useLoanDataFieldSelector,
} from "data/useSelectors";
import {
  setRequestLockButtonStatus,
  setLockDeskData,
} from "data/lockDeskStatus/actionCreators";
import {
  showOnrpLimitNotification,
  setLockDeskModal,
  setAllowLockUpdateInShutdown,
} from "data/lockDeskStatus/actions";
import Countdown from "./components/Countdown";
import { minutesToMilliS, timeDayInRange } from "./utils/helpers";
import {
  LOCKDESK_STATUS_TITLES,
  COUNTDOWM_TOAST_TEXT,
  SHUTDOWN_TOAST_TITLE,
  SHUTDOWN_TOAST_TEXT,
  CLOSED_TOAST_TITLE,
  CLOSED_TOAST_TEXT,
  CLOSED_ONRP_TOAST_TEXT,
} from "./utils/constants";
import LockDeskModal from "./components/LockDeskModal";
const LockDeskStatus = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState("closed");
  const timeouts = useRef([]);
  const { lockDeskData, isLockDeskModal } = useLockDeskStatusActionsSelector();
  const isAlertText = status === "closed" || status === "tempClosed";
  const loanChannel = useLoanDataFieldSelector(
    "loanInformation.loanChannel",
    1
  );

  const openCountDownToast = (countdownTime) =>
    toast({
      toastId: "countdownToast",
      type: ToastType.WARNING,
      autoClose: countdownTime,
      messageTitle: <Countdown milliseconds={countdownTime} />,
      messageText: COUNTDOWM_TOAST_TEXT,
    });

  const refreshStatus = () => {
    const { timeToClose, timeToOpen, timeInRange } = timeDayInRange(
      lockDeskData
    );
    const {
      shutdownLockDesk,
      allowLockUpdate,
      remainingAmountUnderOnrp,
    } = lockDeskData;

    if (shutdownLockDesk) {
      setStatus("tempClosed");
      if (!allowLockUpdate) {
        dispatch(setRequestLockButtonStatus(true));
      }
      if (allowLockUpdate) {
        dispatch(setAllowLockUpdateInShutdown(true));
      }
      toast({
        type: ToastType.ERROR,
        autoClose: 5000,
        messageTitle: SHUTDOWN_TOAST_TITLE,
        messageText: SHUTDOWN_TOAST_TEXT,
      });
      return;
    }
    if (timeToClose && !timeInRange.isOnrp) {
      setStatus("open");
      if (
        timeToClose < minutesToMilliS(30) &&
        timeInRange?.isEffectiveEndTime
      ) {
        setStatus("closing");
        if (timeToClose <= minutesToMilliS(5)) {
          openCountDownToast(
            timeToClose < minutesToMilliS(5) ? timeToClose : minutesToMilliS(5)
          );
          timeouts.current.push(setTimeout(() => refreshStatus(), timeToClose));
        } else {
          timeouts.current.push(
            setTimeout(() => refreshStatus(), timeToClose - minutesToMilliS(5))
          );
        }
      } else {
        timeouts.current.push(
          setTimeout(() => refreshStatus(), timeToClose - minutesToMilliS(30))
        );
      }
    }
    if (timeToClose && timeInRange.isOnrp) {
      setStatus("closed");
      toast.dismiss("countdownToast");
      if (![null, undefined].includes(remainingAmountUnderOnrp)) {
        dispatch(
          showOnrpLimitNotification({
            isUnderOnrp: true,
            remainingAmountUnderOnrp,
          })
        );
      }

      toast({
        type: ToastType.ERROR,
        autoClose: 5000,
        messageTitle: CLOSED_TOAST_TITLE,
        messageText: CLOSED_ONRP_TOAST_TEXT,
      });
    }
    if (!timeToClose) {
      setStatus("closed");
      toast.dismiss("countdownToast");
      dispatch(setRequestLockButtonStatus(true));
      toast({
        type: ToastType.ERROR,
        autoClose: 5000,
        messageTitle: CLOSED_TOAST_TITLE,
        messageText: CLOSED_TOAST_TEXT,
      });
      if (timeToOpen) {
        timeouts.current.push(setTimeout(() => refreshStatus(), timeToOpen));
      }
    }
  };

  useEffect(() => {
    const { lockDeskTimingsPerDay } = lockDeskData;
    if (lockDeskTimingsPerDay !== undefined) {
      if (lockDeskTimingsPerDay.length) {
        refreshStatus();
      }
    }
    return () => timeouts.current.forEach((timeout) => clearTimeout(timeout));
  }, [lockDeskData]);

  const fetchAndStoreLockDeskStatusData = async () => {
    try {
      const response = await Common.getLockDeskData(
        loanChannel === 0 ? 1 : loanChannel
      );
      dispatch(setLockDeskData(response));
    } catch (error) {
      // console.log({ error });
    }
  };

  useEffect(() => {
    fetchAndStoreLockDeskStatusData();
  }, []);

  return (
    <>
      <Grid cols={["20px", "1fr", "16px"]} gutter="xxs">
        <div style={{ alignSelf: "end" }}>
          {status === "closing" && (
            <WarningSquare
              width="16px"
              height="16px"
              color={["warning", "900"]}
            />
          )}
          {isAlertText && (
            <AlertsDetailFill
              width="16px"
              height="16px"
              color={["danger", "900"]}
            />
          )}
        </div>
        <div
          style={
            isAlertText
              ? { alignSelf: "end", color: "#C64252", fontWeight: "bold" }
              : { alignSelf: "end" }
          }
        >
          {LOCKDESK_STATUS_TITLES[status]}
        </div>
        <div style={{ alignSelf: "end" }}>
          <InfoCircle
            width="16px"
            height="16px"
            color={["brand-primary", "700"]}
            onClick={() => dispatch(setLockDeskModal(true))}
          />
        </div>
      </Grid>
      {isLockDeskModal && (
        <LockDeskModal
          handleClose={() => dispatch(setLockDeskModal(false))}
          isModalOpen={isLockDeskModal}
          id="lockDeskModel"
        />
      )}

      <DSToast position="top-right" />
    </>
  );
};

export default memo(LockDeskStatus);
