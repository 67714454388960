import { getRateSelector } from "data/lockSummary/actionCreators";
import { getBuySideQualification } from "Qualification/QualificationActions";

export const getUpdatedSummary = async (selectedPrice, loanData, dispatch) => {
  const newLoanData =
    selectedPrice !== "currentPrice"
      ? loanData
      : {
          ...loanData,
          relockRequest: {
            ...loanData?.relockRequest,
            effectiveDate: "",
          },
        };
  await getBuySideQualification("", newLoanData, dispatch);
  return getRateSelector(dispatch, newLoanData);
};
