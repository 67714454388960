import React from "react";
import {
  GridForm,
  TextBoxForm,
  ComboBoxForm,
  InputMaskForm,
} from "components/Form";
import { DSTruncatedTooltipText } from "@elliemae/ds-truncated-tooltip-text";
import { PopperPositions as Positions } from "@elliemae/ds-popper";
import * as masks from "view/SearchForm/utils/constants";
import { EXTRA_BORROWERS } from "../../../utils/dependencies";
import * as handlers from "./handlers";

export const columns = [
  {
    width: "28.5%",
    Header: "Borrower Information",
    Cell: ({ row, handleSetLoanDataValue, isIntegrationMode }) => (
      <GridForm cols={["100%"]} alignItems="flex-start" height="100%">
        <TextBoxForm
          name={`fullName${row.index}`}
          customValue={row.original.fullName}
          id={`borrowers-${row.original.id}-fullName-focus`}
          extraDependencies={EXTRA_BORROWERS}
          onBlur={(data) => handleSetLoanDataValue(data)}
          maxLength={24}
          readOnly={(data) => {
            if (isIntegrationMode) return true;
            return handlers.handleReadOnlyFirstName(data, row.index);
          }}
        />
      </GridForm>
    ),
  },
  {
    width: "14.285%",
    Header: "Citizenship",
    Cell: ({ row, handleSetLoanDataValue }) => (
      <GridForm cols={["100%"]} alignItems="flex-start" height="100%">
        <div style={{ paddingTop: 15, overflow: "hidden" }}>
          <ComboBoxForm
            isNonClearable
            name={`citizenship${row.index}`}
            customValue={({ value }) => (value === null ? "none" : value)}
            pathValue={`borrowers[${row.index}].citizenship`}
            onChangeV2={(data) => handleSetLoanDataValue(data)}
            containerStyles={{ alignItems: "baseline", height: "100%" }}
          />
        </div>
      </GridForm>
    ),
  },
  {
    width: "14.285%",
    Header: "Liquid Assets",
    Cell: ({ row, handleSetLoanDataValue }) => (
      <GridForm
        cols={["100%"]}
        alignItems="flex-start"
        extraDependencies={EXTRA_BORROWERS}
        tooltip={(data) => handlers.handleReadOnlyLiquidAsset(data, row.index)}
        height="100%"
      >
        <InputMaskForm
          maxLength={12}
          name={`liquidAsset${row.index}`}
          maskOpts={masks.DECIMAL_PLACES}
          pathValue={`borrowers[${row.index}].borrowerFinanancial.liquidAsset`}
          onBlur={(data) => handleSetLoanDataValue(data)}
          id={`borrowers-${row.original.id}-liquidAsset-focus`}
          extraDependencies={EXTRA_BORROWERS}
          clientSettingsValidationEnabled
          readOnly={(data) =>
            handlers.handleReadOnlyLiquidAsset(data, row.index)
          }
        />
      </GridForm>
    ),
  },
  {
    width: "14.285%",
    Header: () => (
      <DSTruncatedTooltipText
        containerProps={{ id: "cell-annual-income-id" }}
        tooltipPlacement={Positions.TOP}
        value="Retirement Accounts"
      />
    ),
    Cell: ({ row, handleSetLoanDataValue }) => (
      <GridForm
        cols={["100%"]}
        alignItems="flex-start"
        extraDependencies={EXTRA_BORROWERS}
        tooltip={(data) =>
          handlers.handleReadOnlyRetirementAsset(data, row.index)
        }
        height="100%"
      >
        <InputMaskForm
          maxLength={12}
          name={`retirementAsset${row.index}`}
          maskOpts={masks.DECIMAL_PLACES}
          pathValue={`borrowers[${row.index}].borrowerFinanancial.retirementAsset`}
          onBlur={(data) => handleSetLoanDataValue(data)}
          id={`borrowers-${row.original.id}-retirementAsset-focus`}
          extraDependencies={EXTRA_BORROWERS}
          readOnly={(data) =>
            handlers.handleReadOnlyRetirementAsset(data, row.index)
          }
        />
      </GridForm>
    ),
  },
  {
    width: "14.285%",
    Header: () => (
      <DSTruncatedTooltipText
        containerProps={{ id: "cell-annual-income-id" }}
        tooltipPlacement={Positions.TOP}
        value="Annual Income"
      />
    ),
    Cell: ({ row, handleSetLoanDataValue }) => (
      <GridForm
        cols={["100%"]}
        alignItems="flex-start"
        extraDependencies={EXTRA_BORROWERS}
        tooltip={(data) => handlers.handleReadOnlyAnualIncome(data, row.index)}
        height="100%"
      >
        <InputMaskForm
          maxLength={12}
          name={`anualIncome${row.index}`}
          maskOpts={masks.DECIMAL_PLACES}
          pathValue={`borrowers[${row.index}].borrowerFinanancial.income`}
          onBlur={(data) => handleSetLoanDataValue(data)}
          id={`borrowers-${row.original.id}-income-focus`}
          extraDependencies={EXTRA_BORROWERS}
          clientSettingsValidationEnabled
          readOnly={(data) =>
            handlers.handleReadOnlyAnualIncome(data, row.index)
          }
        />
      </GridForm>
    ),
  },
  {
    width: "14.285%",
    Header: "Monthly Debt",
    Cell: ({ row, handleSetLoanDataValue }) => (
      <GridForm
        cols={["100%"]}
        alignItems="flex-start"
        extraDependencies={EXTRA_BORROWERS}
        tooltip={(data) => handlers.handleReadOnlyMonthlyDebt(data, row.index)}
        height="100%"
      >
        <InputMaskForm
          maxLength={12}
          name={`monthlyDebt${row.index}`}
          maskOpts={masks.DECIMAL_PLACES}
          pathValue={`borrowers[${row.index}].borrowerFinanancial.monthlyDebt`}
          onBlur={(data) => handleSetLoanDataValue(data)}
          id={`borrowers-${row.original.id}-monthlyDebt-focus`}
          extraDependencies={EXTRA_BORROWERS}
          readOnly={(data) =>
            handlers.handleReadOnlyMonthlyDebt(data, row.index)
          }
        />
      </GridForm>
    ),
  },
];
