import styled from "styled-components";

export const StyledModalContainer = styled.div`
  min-height: 50vh;
  max-height: calc(80vh - 70px);
  transition: all 0.5s;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #a4a4a4;
    border: 4px solid white;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #949494;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #949494;
  }
  &::-webkit-scrollbar-track {
    background: white;
    border: 0px solid #000000;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-track:hover {
    background: white;
  }
  &::-webkit-scrollbar-track:active {
    background: white;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
