import React, { useState, useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { isEmpty } from "utils/shared";
import { DSSeparator } from "@elliemae/ds-separator";
import { ModalHeader, ModalFooter } from "@elliemae/ds-modal-slide";
import ModalSlide from "components/ModalSlide";
import { useLoanInformationSelector, useRateSelector } from "data/useSelectors";
import PriceAnalyzerView from "view/LockConfirm/components/PriceAnalyzer";
import StyledLockConfirmView from "view/LockConfirm/styled";
import BuysidePriceSection from "view/LockConfirm/components/BuysidePriceSection";
import SummaryHeader from "view/LockConfirm/components/SummaryHeader";
import Validate from "view/LockConfirm/components/Validate";
import Banner from "view/LockConfirm/components/Validate/components/Banner";
import { priceUpdate } from "view/LockConfirm/utils";
import { getRateSelectorAction } from "data/lockSummary/actionCreators";
import Session, { IS_VALIDATE, ALL_SUMMARY_PARAMS } from "services/Session";
import PricingDetailsModal from "view/LockSummary/components/PricingDetailsModal";
import { getTableDataWithIDs } from "view/LockSummary/components/PricingDetailsModal/helpers";
import CommentsSection from "view/LockSummary/components/CommentsSection";
import PricingDropdown from "../BuysidePriceSection/components/PricingDropdown";

const LockConfirmWrapper = ({ setIsCompareRatesView }) => {
  const dispatch = useDispatch();
  const loanInfo = useLoanInformationSelector();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceDetailsModalOpen, setIsPriceDetailsModalOpen] = useState(false);
  const [tableData, setTableData] = useState({
    rawPrice: [],
    adjustedPrice: [],
  });
  const defaultSelectData = useRef({});
  const isValidate = Session.get(IS_VALIDATE);
  const noNewProduct = isEmpty(Session.get(ALL_SUMMARY_PARAMS));
  const { priceData } = useRateSelector();

  useEffect(() => {
    if (priceData) {
      setTableData(getTableDataWithIDs(priceData));
    }
  }, [priceData]);

  const handleApplyChange = (data) => {
    defaultSelectData.current = data;
  };

  const showPriceAnalyzerModal = useCallback(() => {
    setIsModalOpen((prevState) => !prevState);
  }, []);

  const showPriceDetailsModal = useCallback(() => {
    setIsPriceDetailsModalOpen((prevState) => !prevState);
  }, []);

  const applyNewPriceConfirmAnalyzer = () => {
    setIsModalOpen((prevState) => !prevState);
    priceUpdate(defaultSelectData.current, loanInfo);
    dispatch(getRateSelectorAction());
    defaultSelectData.current = {};
  };

  const onCompareRates = () => {
    setIsModalOpen(false);
    setIsCompareRatesView(true);
  };

  return (
    <>
      {isValidate && noNewProduct && <Banner />}
      <StyledLockConfirmView>
        <SummaryHeader />
        <DSSeparator
          style={{ margin: "8px 0 24px" }}
          dashed
          orientation="horizontal"
          position="initial"
        />
        {isValidate && noNewProduct ? (
          <Validate
            buySidePriceSection={
              <BuysidePriceSection
                pricingDropdownSection={
                  <PricingDropdown
                    priceAnalyzerModal={showPriceAnalyzerModal}
                    priceDetailsModal={showPriceDetailsModal}
                  />
                }
              />
            }
          />
        ) : (
          <BuysidePriceSection
            pricingDropdownSection={
              <PricingDropdown
                priceAnalyzerModal={showPriceAnalyzerModal}
                priceDetailsModal={showPriceDetailsModal}
              />
            }
          />
        )}
        <ModalSlide
          maxWidth="650px"
          isOpen={isModalOpen}
          header={
            <ModalHeader
              title="Price Analyzer"
              data-testid="LockConfirmWrapper-all-components"
              onClose={showPriceAnalyzerModal}
            />
          }
          footer={
            <ModalFooter
              onConfirm={applyNewPriceConfirmAnalyzer}
              onReject={showPriceAnalyzerModal}
              confirmLabel="Apply New Price"
            />
          }
        >
          <PriceAnalyzerView
            onCompareRates={onCompareRates}
            onApplyChange={handleApplyChange}
          />
        </ModalSlide>
        <PricingDetailsModal
          togglePricingDetails={showPriceDetailsModal}
          isOpenPricingDetails={isPriceDetailsModalOpen}
          tableData={tableData}
        />
        <CommentsSection />
      </StyledLockConfirmView>
    </>
  );
};

LockConfirmWrapper.propTypes = {
  setIsCompareRatesView: PropTypes.func.isRequired,
};

export default LockConfirmWrapper;
