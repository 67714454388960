import React, { Fragment } from "react";
import { arrayOf, shape } from "prop-types";
import { isEmpty } from "lodash";
import { EmptyState } from "@elliemae/ds-datagrids";

const RowsGridEmpty = ({ loanPrograms, rows }) => {
  return (
    <Fragment>
      {!isEmpty(loanPrograms) && !rows.length ? (
        <div style={{ margin: "auto", paddingTop: "48px" }}>
          <EmptyState
            data-testid="RowsGridEmpty-all-components"
            primaryMessage="No Products matched your request"
            secondaryMessage=""
          />
        </div>
      ) : (
        <div />
      )}
    </Fragment>
  );
};

RowsGridEmpty.defaultProps = {
  loanPrograms: {},
  rows: [],
};

RowsGridEmpty.propTypes = {
  loanPrograms: shape({}),
  rows: arrayOf(shape({})),
};

export default RowsGridEmpty;
