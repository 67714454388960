import React, { useState, memo, useEffect } from "react";
import { arrayOf, bool, func } from "prop-types";
import { useAppData } from "utils/customHooks/useAppData";
import EligibleProductsGrid from "./EligibleProductsGrid";
import { splitByEligible } from "./helpers";
import { FILTERS_INITIAL_STATE } from "./constants";

const ClassicGrid = ({
  loanPrograms,
  applyFilter,
  refreshPrograms,
  onSearch,
  updateAdjustmentDetailsCache,
}) => {
  const refEligible = useAppData("refEligible");
  const setEligibleProducts = useAppData("setEligibleProducts");
  const [activeFilters, setActiveFilters] = useState(FILTERS_INITIAL_STATE);
  const [rows, setRows] = useState([]);

  const generateEligibleRows = (programs) => {
    const { eligible } = programs
      ? splitByEligible(programs)
      : { eligible: [] };
    return eligible.filter((p) => applyFilter(p));
  };

  const handleFilterChange = (filterName, value) => {
    setActiveFilters((prevState) => ({ ...prevState, [filterName]: value }));
  };

  useEffect(() => {
    setRows(generateEligibleRows(loanPrograms));
  }, [loanPrograms]);

  useEffect(() => {
    if (rows.length > 0 && !refEligible.current) {
      setEligibleProducts(rows.length);
      refEligible.current = true;
    }
    return () => {
      refEligible.current = false;
    };
  }, [rows]);

  return (
    <EligibleProductsGrid
      rows={rows}
      activeFilters={activeFilters}
      onFilterChange={handleFilterChange}
      isLoanPrograms={loanPrograms}
      refreshPrograms={refreshPrograms}
      onSearch={onSearch}
      updateAdjustmentDetailsCache={updateAdjustmentDetailsCache}
    />
  );
};

ClassicGrid.defaultProps = {
  loanPrograms: [],
  refreshPrograms: false,
  applyFilter: () => null,
  onSearch: () => null,
  updateAdjustmentDetailsCache: () => null,
};

ClassicGrid.propTypes = {
  loanPrograms: arrayOf(),
  refreshPrograms: bool,
  applyFilter: func,
  onSearch: func,
  updateAdjustmentDetailsCache: func,
};

export default memo(ClassicGrid);
