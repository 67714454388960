import styled from "styled-components";

export const BonaFideDiscountWrapper = styled.div`
  padding: ${({ theme: { space = {} } }) => `${space.xxxs} ${space.xs}`};

  ul {
    list-style-type: none;
    padding-inline-start: unset;
  }

  .bona-fide-heading {
    text-transform: uppercase;
  }
`;
