import styled from "styled-components";

export const StyledTitle = styled.div`
  font-weight: bold;
  text-align: left;
  border: 1px none white;
  width: 750px;
  font-size: 13px;
`;

export const StyledDiv = styled.div`
  font-weight: normal;
  border: 1px none pink;
  padding: 5px 0 5px 0;
  margin-left: 20px;
`;

export const StyledCard1 = styled.span`
  font-weight: normal;
  border: 1px none yellow;
  left: 30px;
  width: 47%;
  display: inline-block;
`;

export const StyledCard2 = styled.span`
  font-weight: normal;
  border: 1px none yellow;
  left: 30px;
  width: 50%;
  display: inline-block;
`;
export const StyledTextTitle = styled.div`
  font-weight: bold;
`;

export const StyledEmptyCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledCenteredText = styled.div`
  text-align: center;
`;
