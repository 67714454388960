import React from "react";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { InputMaskForm } from "components/Form";
import { setLoanDataValue } from "store/searchForm/actions";
import * as masks from "../../../../utils/constants";
import * as dependencies from "../../utils/dependencies";
import * as validations from "./utils/validations";

const CreditScoreField = () => {
  const dispatch = useDispatch();

  const handleSetLoanDataCreditScore = ({ value, borrowers }) => {
    const parseValue = ["", undefined, "none"].includes(value) ? null : value;
    const newBorrowers = borrowers?.length
      ? borrowers.map((borrower) => ({
          ...borrower,
          borrowerFinanancial: {
            ...borrower.borrowerFinanancial,
            creditScore: parseValue,
          },
        }))
      : borrowers;
    dispatch(setLoanDataValue(newBorrowers, "borrowers"));
  };

  return (
    <InputMaskForm
      required
      clearable
      maxLength={3}
      maskOpts={masks.DECIMAL_PLACES_NOT_PREFIX}
      name="representativeCreditScore"
      label="Representative Credit Score"
      dependencies={dependencies.CREDIT_SCORE}
      readOnly={validations.validateNoCreditScore}
      pathValue="borrowers[0].borrowerFinanancial.creditScore"
      customValue={({ value, customDependencies }) => {
        const { noCreditScores, borrowers } = customDependencies;
        if ((!isEmpty(borrowers) && noCreditScores) || !value) {
          handleSetLoanDataCreditScore({ borrowers, value: 0 });
          return 0;
        }
        return value;
      }}
      onBlur={({ customDependencies, value }) => {
        const { borrowers } = customDependencies;
        handleSetLoanDataCreditScore({ borrowers, value });
      }}
    />
  );
};

export default CreditScoreField;
