import React from "react";
import { string } from "prop-types";
import Session, { ORIGIN_SOURCE } from "services/Session";
import { StyledSlash, StyledTitle, StyledNameView } from "../utils/styled";
import { TITLE_TEXT } from "../utils/constants";

const HeaderTitle = ({ title }) => {
  const isSecondary = Session.get(ORIGIN_SOURCE) === "secondarylock";

  return (
    <StyledTitle marginLeft={isSecondary}>
      {TITLE_TEXT}{" "}
      <StyledSlash data-testid="HeaderTitle-all-components" margin />{" "}
      <StyledNameView>{title}</StyledNameView>
    </StyledTitle>
  );
};

HeaderTitle.defaultProps = {
  title: "",
};

HeaderTitle.propTypes = {
  title: string,
};

export default HeaderTitle;
