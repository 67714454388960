import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { func } from "prop-types";
import moment from "moment";
import { GridContainer, GridItem } from "@elliemae/ds-grid";
import RateColumn from "./RateColumn";
import DateGridColumn from "./DateGridColumn";
import {
  EST,
  formatter,
  utcFormatter,
  customOptionsInitialState,
} from "./helpers";
import { boxStyles } from "../styled";

const loanEventDataSelector = ({ epps }) =>
  (epps && epps.lockConfirm.loanRelockData) || [];

const RatesContainer = ({ onSelect }) => {
  const [customOptionSelectionMap, setCustomOptionSelectionMap] = useState(
    customOptionsInitialState
  );

  const relockSnapShotData = useSelector(loanEventDataSelector);
  const relockEventDates = relockSnapShotData.eventList || [];
  const getDates = (index = -1) => {
    if (relockEventDates && relockEventDates.length > 1) {
      const dates = [...relockEventDates].reverse();
      return index > -1 ? dates[index] : dates;
    }
    return [];
  };
  const { requestedDate } = getDates(1);

  const defaultDropdownOptions = useMemo(() => {
    return [
      {
        label: moment.tz(requestedDate, EST).format(formatter),
        value: requestedDate,
      },
    ];
  }, [requestedDate]);

  const defaultDropdownValue = useMemo(() => {
    return defaultDropdownOptions.length && defaultDropdownOptions[0].value;
  }, [defaultDropdownOptions]);

  const dropdownOptions = useMemo(() => {
    const options = getDates().map((event, index) => {
      const { requestedDate: eventDate } = event;
      const now = moment.utc().format(utcFormatter);
      const date = index === 0 ? now : eventDate;
      const getPriceInfo = () => {
        if (index === 0) return "(Current Price)";
        if (index === relockEventDates.length - 1) return "(Lock Request)";
        return "";
      };
      return {
        label: `${moment.tz(date, EST).format(formatter)} ${getPriceInfo()}`,
        value: date,
      };
    });

    if (options.length) {
      options.push({
        label: "Custom Date",
        value: "custom",
        hasSeparator: true,
      });
    }
    return options;
  }, [relockEventDates]);

  const setCustomOptionsHandler = (name, isCustom) => {
    const newMap = [...customOptionSelectionMap];
    const index = newMap.findIndex((item) => item.name === name);
    newMap.splice(index, 1, { name, isCustom });
    setCustomOptionSelectionMap([...newMap]);
  };

  const isExpanded = customOptionSelectionMap.some((item) => item.isCustom);

  return (
    <GridContainer cols={10} p="16px">
      <GridItem
        span={1}
        style={{ ...boxStyles, borderTopColor: "transparent" }}
      >
        <RateColumn isExpanded={isExpanded} />
      </GridItem>
      <GridItem span={3} style={boxStyles}>
        <DateGridColumn
          dropdownLabel="Requested Price"
          dropdownOptions={defaultDropdownOptions || []}
          dropdownValue={defaultDropdownValue}
          dropdownDisabled
          onSelect={onSelect}
          isExpanded={isExpanded}
          name="compareRatesGrid1"
        />
      </GridItem>
      <GridItem span={3} style={boxStyles}>
        <DateGridColumn
          dropdownLabel="Pricing Change Date"
          dropdownOptions={dropdownOptions || []}
          onSelect={onSelect}
          name="compareRatesGrid2"
          trackCustomSelection={setCustomOptionsHandler}
          isExpanded={isExpanded}
        />
      </GridItem>
      <GridItem span={3} style={boxStyles}>
        <DateGridColumn
          dropdownLabel="Pricing Change Date"
          dropdownOptions={dropdownOptions || []}
          onSelect={onSelect}
          name="compareRatesGrid3"
          trackCustomSelection={setCustomOptionsHandler}
          isExpanded={isExpanded}
        />
      </GridItem>
    </GridContainer>
  );
};

RatesContainer.propTypes = {
  onSelect: func.isRequired,
};

export default RatesContainer;
