import { DSSeparator } from "@elliemae/ds-separator";
import {
  getClientSettingSelector,
  getRateSelectorAction,
} from "data/lockSummary/actionCreators";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataProvider } from "utils/customHooks/useAppData";
import { isShallowEqual } from "utils/shared";
import {
  StyledEppsContainerBody,
  StyledEppsParentView,
  StyledEppsWrapper,
} from "view/global/styles/styled";
import CommentsSection from "./components/CommentsSection";
import DiffElements from "./components/DiffElements";
import FooterActionButtons from "./components/FooterActionButtons";
import GeneralLoanInformation from "./components/GeneralLoanInformation/GeneralLoanInformation";
import InformationGroupSection from "./components/InformationGroupSection";
import { getTableDataWithIDs } from "./components/PricingDetailsModal/helpers";
import PricingTable from "./components/PricingTable";
import SummaryHeader from "./components/SummaryHeader";
import StatusFailMessage from "./components/SummaryHeader/StatusFailMessage";

const LockSummary = () => {
  const [dataTableContext, setTableDataContext] = useState({
    rawPrice: [],
    adjustedPrice: [],
  });
  const dispatch = useDispatch();
  const priceData = useSelector(
    ({
      epps: {
        lockSummary: { rateSelector },
      },
    }) => rateSelector.priceData,
    isShallowEqual
  );

  useEffect(() => {
    // TODO This code snippet was changed and needs to be refactored.
    if (priceData) setTableDataContext(getTableDataWithIDs(priceData));
  }, [priceData]);

  const initPage = async () => {
    await dispatch(getRateSelectorAction());
    await dispatch(getClientSettingSelector());
  };

  useEffect(() => {
    initPage();
    window.scrollTo(0, 0);
  }, []);

  return (
    <StyledEppsParentView>
      <StatusFailMessage />
      <StyledEppsContainerBody>
        <StyledEppsWrapper>
          <DataProvider
            value={{
              dataTableContext,
              setTableDataContext,
            }}
          >
            <SummaryHeader />
            <GeneralLoanInformation />
            <DSSeparator
              margin="s"
              type="non-form"
              orientation="horizontal"
              position="initial"
            />
            <PricingTable />
            <InformationGroupSection />
            <CommentsSection />
          </DataProvider>
        </StyledEppsWrapper>
      </StyledEppsContainerBody>
      <FooterActionButtons />
      <DiffElements />
    </StyledEppsParentView>
  );
};

export default LockSummary;
