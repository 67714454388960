import React, { useState, useEffect } from "react";
import { string, bool, arrayOf, shape, func } from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Grid, GridItem } from "@elliemae/ds-grid";
import { DSFormItemLayout } from "@elliemae/ds-form";
import { DSDateTimePicker } from "@elliemae/ds-date-time-picker";
import { DataTable } from "@elliemae/ds-data-table";
import {
  useLoanDataSelector,
  useLockConfirmFieldSelector,
} from "data/useSelectors";
import { getRatesAction, resetGridData } from "data/lockConfirm/actionCreators";
import {
  columns,
  EST,
  SIZE_SMALL,
  SIZE_LARGE,
  GRID_HEIGHT,
  getPlaceholderData,
} from "./helpers";
import {
  StyledDateGridColumn,
  StyledDataGridWrapper,
  NoResultsMask,
} from "../styled";
import DatesDropdown from "./DatesDropdown";

const DateGridColumn = ({
  name,
  isExpanded,
  onSelect,
  dropdownLabel,
  dropdownOptions,
  dropdownValue,
  dropdownDisabled,
  trackCustomSelection,
}) => {
  const dispatch = useDispatch();
  const [isCustom, setIsCustom] = useState(false);
  const [customDateValue, setCustomDateValue] = useState();
  const [isCustomDateValid, setIsCustomDateValid] = useState(true);
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [rates, setRates] = useState(getPlaceholderData());
  const [selection, setSelection] = useState({});
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [gridDate, setGridDate] = useState("");
  const loanInfo = useLoanDataSelector();
  // from lockConfirm/reducer
  const allRates = useLockConfirmFieldSelector("ratesData", []);
  const ratesData = useLockConfirmFieldSelector(name, []);

  const handleSelection = ({ id, rateDataID, programID, flag }) => {
    onSelect({
      id,
      disablePrice: flag,
      specificRateDataID: rateDataID,
      specificProgramID: programID,
      effectiveDate: gridDate,
    });
  };

  useEffect(() => {
    let data = [];
    if (ratesData.length) {
      data = [...allRates]
        // generate datagrid rows based on number of unique rates (allRates)
        .map(({ rate, rateDataID }) => ({
          rateValue: rate,
          rateId: rateDataID,
        }))
        // fill each row with data from ratesData based on rateDataID
        // cell renderers to handle logic if data not available
        .map(({ rateValue, rateId }, index) => {
          const {
            rateDataID,
            price,
            basePrice: base,
            priceVal: discount,
            programID,
          } = ratesData.find(({ rate }) => rate === rateValue) || {};
          // Lock icon and default selection will only happen for the first datagrid

          const isLock =
            price &&
            name === "compareRatesGrid1" &&
            rateDataID === loanInfo.relockRequest.specificRateDataID;

          const id = `${name}${index}-${rateDataID || rateId}`;

          if (isLock) {
            handleSelection({ id, rateDataID, programID, flag: true }); // default selection in grid 1
            setSelection({ [id]: true });
          }
          return {
            id,
            price,
            isLock,
            base,
            discount,
            programID,
            rateDataID,
            onSelectCallback: handleSelection,
          };
        });
    }
    setShowPlaceholder(!data.length);
    setRates(data.length ? data : getPlaceholderData(allRates.length));
  }, [ratesData, allRates, loanInfo]);

  const resetCustomDate = () => {
    setCustomDateValue(null);
    setIsCustomDateValid(true);
    setIsTimeSelected(false);
  };

  const handleOnChange = (newValue) => {
    const valueIsCustom = newValue === "custom";
    setIsCustom(valueIsCustom);
    trackCustomSelection(name, valueIsCustom);
    if (!valueIsCustom) {
      resetCustomDate();
      setIsDropdownSelected(true);
      setGridDate(newValue);
      // fetch rate sheet only if option selected is a date
      dispatch(getRatesAction(newValue, name));
      return;
    }
    // if custom option selected, remove previous rate sheet
    // this will show placeholder message on datagrid
    dispatch(resetGridData(name));
    setShowPlaceholder(true);
    setRates(getPlaceholderData(allRates.length));
  };

  const handleSetDate = (value) => {
    if (isTimeSelected) {
      setCustomDateValue(value);
      return;
    }
    // set time to 12am if user has not selected any time
    setCustomDateValue(value.hours(0).minutes(0));
  };

  const handleSetTime = (value) => {
    setIsTimeSelected(true);
    setCustomDateValue(value);
  };

  const handleDateTimePickerClose = () => {
    if (customDateValue) {
      // custom date is valid if it's same or before current Eastern time
      const isValid = customDateValue
        .seconds(0)
        .isSameOrBefore(moment.tz(EST).format("MM/DD/YYYY hh:mm A"), "minute");

      setIsCustomDateValid(isValid);
      setIsDropdownSelected(true);
      setGridDate(customDateValue);

      // get rate sheet only when datetimepicker closes and custom date is valid
      if (isValid) {
        dispatch(getRatesAction(customDateValue, name, true));
      }
    }
  };

  return (
    <StyledDateGridColumn>
      <Grid
        direction="column"
        height={isExpanded ? SIZE_LARGE : SIZE_SMALL}
        padding="16px"
      >
        <GridItem height={GRID_HEIGHT}>
          <DatesDropdown
            dropdownLabel={dropdownLabel}
            dropdownOptions={dropdownOptions}
            dropdownValue={dropdownValue}
            dropdownDisabled={dropdownDisabled}
            onChange={handleOnChange}
          />
        </GridItem>
        {isExpanded && (
          <GridItem
            height={GRID_HEIGHT}
            data-testid="DateGridColumn-all-components"
            className="custom-date-picker-wrapper"
          >
            {isCustom && (
              <DSFormItemLayout
                inputComponent={DSDateTimePicker}
                onBlur={handleDateTimePickerClose}
                hasError={!isCustomDateValid}
                validationMessage="Invalid date/time"
                extraInputProps={{
                  date: customDateValue,
                  datePickerProps: {
                    focusCurrentDate: true,
                    onDateChange: handleSetDate,
                    screenReaderInputMessage: "Select date",
                    isDayBlocked: (day) =>
                      day.hours(0).isAfter(moment(), "day"),
                  },
                  timePickerProps: {
                    onChange: handleSetTime,
                  },
                }}
              />
            )}
          </GridItem>
        )}
      </Grid>
      <StyledDataGridWrapper>
        <DataTable
          columns={columns}
          data={rates}
          selectSingle
          uniqueRowAccessor="id"
          selection={selection}
          onSelectionChange={setSelection}
          noSelectionColumn
        />
        {/* Need to show a masked overlay for no results instead of default behavior due to
            alignment issues with empty datagrid component on Smart Client - EPPS-32456 */}
        {(!isDropdownSelected || showPlaceholder) && (
          <NoResultsMask>Select a date to compare</NoResultsMask>
        )}
      </StyledDataGridWrapper>
    </StyledDateGridColumn>
  );
};

DateGridColumn.propTypes = {
  name: string,
  dropdownLabel: string.isRequired,
  dropdownOptions: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      hasSeparator: bool,
    })
  ),
  dropdownValue: string,
  dropdownDisabled: bool,
  onSelect: func.isRequired,
  trackCustomSelection: func,
  isExpanded: bool,
};

DateGridColumn.defaultProps = {
  name: "",
  dropdownValue: "",
  dropdownOptions: [],
  dropdownDisabled: false,
  trackCustomSelection: () => null,
  isExpanded: false,
};

export default DateGridColumn;
