import React from "react";
import { useDispatch } from "react-redux";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { useSearchFormSelector } from "components/Form/utils/customHooks";
import { searchFormActions } from "store/searchForm";

const ErrorBanner = () => {
  const dispatch = useDispatch();
  const bannerIsOpen = useSearchFormSelector("errorForm.bannerIsOpen").value;
  let bannerBody = "Please fix any fields that are marked below.";
  const errorFilters = useSearchFormSelector("errorForm.errorFilters").value;
  const errorFields = useSearchFormSelector("errorForm.errorFields").value;
  if (errorFilters.length > 0 && errorFields.length === 0) {
    bannerBody = errorFilters?.[0]?.details;
  }

  return (
    <DSBanner
      body={bannerBody}
      isOpen={!!bannerIsOpen}
      onClose={() => dispatch(searchFormActions.setBannerIsOpen(false))}
      label="Errors Found"
      type={BANNER_TYPES.DANGER}
    />
  );
};

export default ErrorBanner;
