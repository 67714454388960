import React, { useState, useEffect } from "react";
import { DSCardArray } from "@elliemae/ds-card-array";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { get } from "lodash";
import populateRelockEventBox from "./helpers";

const LockUpdateBoxesWrapper = (props) => {
  const relockData = get(props, "relockData") || null;
  const relockEventDateList = get(props, "relockEventDateList") || null;
  const defaultRate = get(props, "defaultRate") || null;
  const [isOpen, setIsOpen] = useState(false);
  const selectedLockBox = get(props, "selectedLockBox") || {};

  useEffect(() => {
    if (relockData) {
      const notAvailableLength = Object.keys(relockData).filter(
        (x) => relockData[x] === null
      ).length;
      setIsOpen(notAvailableLength > 0);
    }
  }, [relockData]);

  return (
    <div
      className="grid-x"
      style={{ marginTop: "2px", marginBottom: "2px", width: "100%" }}
    >
      <div
        style={{
          width: "100%",
          margin: "0 15px 0 15px",
        }}
      >
        {isOpen && (
          <DSBanner
            body="Rate is unavailable for one or more dates in loan history."
            containerProps={{ id: "my-custom-id" }}
            label="Note"
            type={BANNER_TYPES.INFO}
            showCloseButton={false}
          />
        )}
      </div>
      <div className="cell large-12">
        <DSCardArray.Wrapper
          columns={1}
          style={{
            marginBottom: "0rem",
            padding: "0",
          }}
        >
          {populateRelockEventBox(
            relockData,
            relockEventDateList,
            defaultRate,
            selectedLockBox
          )}
        </DSCardArray.Wrapper>
      </div>
    </div>
  );
};

export default LockUpdateBoxesWrapper;
