import React from "react";
import { func, bool, shape } from "prop-types";
import { ModalHeader } from "@elliemae/ds-modal-slide";
import ModalSlide from "components/ModalSlide";
import ProgramDetails from "../ProgramDetails";
import { useProgramDetails } from "../../hooks";

const ProgramDetailsModal = ({
  isOpen,
  toggleModalSlide,
  programInformation,
}) => {
  const {
    program,
    productNotes,
    formPostAllRegs,
    activeGuidelineLink,
  } = useProgramDetails(programInformation);
  return (
    <ModalSlide
      isOpen={isOpen}
      header={
        <ModalHeader
          onClick={toggleModalSlide}
          onClose={toggleModalSlide}
          title={program}
        />
      }
    >
      <ProgramDetails
        notes={productNotes}
        activeGuidelineLink={activeGuidelineLink}
        onLinkClick={formPostAllRegs}
      />
    </ModalSlide>
  );
};

ProgramDetailsModal.defaultProps = {
  isOpen: false,
  toggleModalSlide: () => null,
  programInformation: null,
};

ProgramDetailsModal.propTypes = {
  isOpen: bool,
  toggleModalSlide: func,
  programInformation: shape({}),
};

export default ProgramDetailsModal;
