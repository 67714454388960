import React from "react";
import { debounce } from "lodash";
import { DSInputMask, MASK_TYPES } from "@elliemae/ds-form";
import * as masks from "view/SearchForm/utils/constants";

const days = [30, 60, 90];

const periods = [
  { value: 12, label: "Last 12 Months" },
  { value: 24, label: "Last 13 to 24 Months" },
];

export const getDelinquenciesRows = (borrowerFinancialHistory) => {
  const rows = periods.map((period) => {
    const result = days.reduce(
      (acum, val) => {
        const key = `${val}DaysMortgageLatestin${period.value}Months`;
        return {
          ...acum,
          [`${val}Days`]: { key, value: borrowerFinancialHistory[key] },
        };
      },
      { period: period.label }
    );
    return result;
  });
  return rows;
};

export const numberFieldRenderer = (property, row, handleChange) => {
  const handleChangeAndParseInt = ({ target }) => {
    const newValue = Number.parseInt(target.value.replace(/,/g, ""), 10);
    handleChange(row[property].key, newValue);
  };
  return (
    <DSInputMask
      mask={(number) => MASK_TYPES.NUMBER(masks.NO_DECIMAL_NO_PREFIX)(number)}
      value={String(row[property].value)}
      onBlur={debounce(handleChangeAndParseInt, 500)}
      tabIndex="0"
    />
  );
};
