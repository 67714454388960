export const SET_LOAN_DATA = "@origin/SET_ORIGIN_LOAN_DATA";
export const SET_ORIGINAL_LOAN_DATA = "@origin/SET_ORIGIN_ORIGINAL_LOAN_DATA";
export const SET_FIRST_LOAD = "@origin/SET_FIRST_LOAD";
export const SET_BUYSIDE_ADJUSTMENTS = "@origin/SET_BUYSIDE_ADJUSTMENTS";
export const UPDATE_BUYSIDE_ADJUSTMENTS = "@origin/UPDATE_BUYSIDE_ADJUSTMENTS";
export const SET_LOCKED_PROGRAM_DATA = "@origin/SET_LOCKED_PROGRAM_DATA";

export const setLoanDataAction = (payload) => ({
  type: SET_LOAN_DATA,
  payload,
});

export const setOriginalLoanDataAction = (payload) => ({
  type: SET_ORIGINAL_LOAN_DATA,
  payload,
});

export const setFirstLoad = (payload) => ({
  type: SET_FIRST_LOAD,
  payload,
});

export const setLockedProgramData = (payload) => ({
  type: SET_LOCKED_PROGRAM_DATA,
  payload,
});
