import styled from "styled-components";

export const StyledGridWrapper = styled.div`
  margin-top: 8px;

  .em-ds-icon {
    margin-top: 120px;
  }
`;

export const StyledCell = styled.span`
  margin-left: 24px;
`;
