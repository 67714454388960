import api from "utils/api";

export const calculateRateLockExtension = (loanUuid, lockOptions) => {
  return api.calculateRateLockExtension({
    loanUuid,
    numberOfDays: lockOptions.numberOfDays,
    lockDate: lockOptions.lockDate,
    parentLockUuid: lockOptions.parentLockUuid,
  });
};

export const getLockPageSettings = async () => {
  const apiResponse = await api.getLockPageSettings();

  const {
    extensionLimit,
    extensionLimitDays,
    extendedLocksTotalLockDays,
    totalExtensionsAllowed,
    limitTotalExtensionsAllowed,
  } = apiResponse.lockExtensions;
  const { controlOption } = apiResponse.lockExtensions.control ?? {}; // DaysAndPrice, DaysAndPricePerExtension, NoCompanyControl

  const { daysAndAdjustments, fixedDaysAndAdjustment, allowDailyAdjustment } =
    apiResponse.lockExtensions.control.controlSettings ??
    apiResponse.lockExtensions.control.controlSettingsPerExtension ??
    {};

  const getExtensionNumberFromLabel = (label) =>
    Number(label?.replace(/[^\d]/g, ""));

  const getControlSettings = ({
    lockRequests,
    originalLockRequest,
    currentLockRequest,
  }) => {
    const controlSettings = {
      daysToExtendControlType: null, // Enumerated, Text
      daysToExtendControlOptions: null,
      adjustmentControlType: null, // null, Text
      maxNumberOfDays: null,
      totalExtensionsAllowed:
        limitTotalExtensionsAllowed && totalExtensionsAllowed > 0
          ? totalExtensionsAllowed
          : null,
    };

    if (controlOption === "NoCompanyControl") {
      controlSettings.daysToExtendControlType = "Text";
      controlSettings.adjustmentControlType = "Text";
    }

    if (controlOption === "DaysAndPrice") {
      if (allowDailyAdjustment) {
        controlSettings.daysToExtendControlType = "Text";
      } else if (fixedDaysAndAdjustment) {
        controlSettings.daysToExtendControlType = "Enumerated";
        controlSettings.daysToExtendControlOptions = (daysAndAdjustments ?? [])
          .map((i) => i.daysToExtend)
          .sort();
      }
    }

    if (controlOption === "DaysAndPricePerExtension") {
      const nextExtension = lockRequests.length;
      controlSettings.daysToExtendControlType = "Enumerated";
      controlSettings.daysToExtendControlOptions = (daysAndAdjustments ?? [])
        .filter(
          (ad) =>
            getExtensionNumberFromLabel(ad.extensionNumber) === nextExtension
        )
        .map((i) => i.daysToExtend)
        .sort();
    }

    const totalDaysExtendedExtensionOnly =
      currentLockRequest?.cumulatedDaystoExtend || 0;

    const totalDaysExtended =
      totalDaysExtendedExtensionOnly + (originalLockRequest?.numberOfDays || 0);

    const maxNumberOfDaysByExtensionLimitConfig = {
      Unlimited: Number.MAX_SAFE_INTEGER,
      LimitedDays:
        (Number(extensionLimitDays) || 0) - totalDaysExtendedExtensionOnly,
      OriginalLockPeriod: originalLockRequest?.numDayLocked,
    };

    const cumulativeMax = extendedLocksTotalLockDays || Number.MAX_SAFE_INTEGER;
    const perExtensionMax =
      maxNumberOfDaysByExtensionLimitConfig[extensionLimit] ??
      Number.MAX_SAFE_INTEGER;

    controlSettings.daysToExtendControlOptions = (
      controlSettings.daysToExtendControlOptions ?? []
    ).filter(
      (day) =>
        totalDaysExtended + day <= cumulativeMax && day <= perExtensionMax
    );

    controlSettings.maxNumberOfDays = Math.max(
      0,
      Math.min(cumulativeMax - totalDaysExtended, perExtensionMax)
    );

    return controlSettings;
  };

  const validateNumberOfDays = (numberOfDays, lockRequests) => {
    // Number of Day validation must happen on backend, for security.
    // Price adjustment can be retrieved using the calculation API, on the backend

    const controlSettings = getControlSettings({ lockRequests });
    if (
      controlSettings.daysToExtendControlOptions?.length &&
      !controlSettings.daysToExtendControlOptions.includes(Number(numberOfDays))
    ) {
      return false;
    }

    if (numberOfDays > controlSettings.maxNumberOfDays) {
      return false;
    }

    return true;
  };

  return { getControlSettings, validateNumberOfDays };
};
