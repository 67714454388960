const addDaysToTime = (time, days) => {
  return time + days * 24 * 60 * 60 * 1000;
};

const getWeekDay = (currentDate) => {
  return currentDate.toLocaleString("en-us", {
    timeZone: "America/New_York",
    weekday: "long",
  });
};

const getShortDate = (currentDate) => {
  return currentDate.toLocaleString("en-US", {
    timeZone: "America/New_York",
    dateStyle: "short",
  });
};

// TODO: see if we can change this to toLocaleString("en-us"). Also make sure that n is a number.
export const addThousandsSeparator = (n, thousandsSeparatorSymbol) =>
  n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);

const newTime = (shortDate, time, timezone) => {
  return new Date(`${shortDate} ${time} ${timezone}`).getTime();
};

const getEndTime = (date, endTimeString, timezone) => {
  return endTimeString === "12:00 AM"
    ? addDaysToTime(newTime(date, endTimeString, timezone), 1)
    : newTime(date, endTimeString, timezone);
};

const formatArrayOfTimings = (timings = [], shortDate, timezone) => {
  return timings.map(
    ({
      lockDeskStartTime,
      lockDeskEndTime,
      onrpStartTime,
      onrpEndTime,
      isTommorrow,
      isOnrp = false,
      isEffectiveEndTime,
    }) => ({
      lockDeskStartTime: addDaysToTime(
        newTime(shortDate, lockDeskStartTime || onrpStartTime, timezone),
        isTommorrow ? 1 : 0
      ),
      lockDeskEndTime: addDaysToTime(
        getEndTime(shortDate, lockDeskEndTime || onrpEndTime, timezone),
        isTommorrow ? 1 : 0
      ),
      isOnrp,
      isEffectiveEndTime,
    })
  );
};

const findTimingsPerDay = (
  weekDay,
  timingsPerDay = [],
  shortDate,
  timezone
) => {
  const today = timingsPerDay.find(({ dayofWeek }) => dayofWeek === weekDay);
  const tomorrow =
    timingsPerDay[(timingsPerDay.indexOf(today) + 1) % timingsPerDay.length];

  const jointTimings = [
    ...today?.lockDeskHoursTimings,
    ...today?.onrpTimings.map((timing) => ({
      ...timing,
      isOnrp: true,
    })),
    ...tomorrow?.lockDeskHoursTimings.map((timing) => ({
      ...timing,
      isTommorrow: true,
    })),
    ...tomorrow?.onrpTimings.map((timing) => ({
      ...timing,
      isTommorrow: true,
      isOnrp: true,
    })),
  ];
  const formattedTimings = formatArrayOfTimings(
    jointTimings,
    shortDate,
    timezone
  );
  return formattedTimings.sort(
    (a, b) => a.lockDeskStartTime - b.lockDeskStartTime
  );
};

export const minutesToMilliS = (minutes) => minutes * 60 * 1000;

export const timeDayInRange = (lockDeskApiPayload) => {
  const { lockDeskTimingsPerDay = {}, timezone } = lockDeskApiPayload;

  const date = new Date();
  const weekDay = getWeekDay(date);
  const shortDate = getShortDate(date);
  const timingsPerDay = findTimingsPerDay(
    weekDay,
    lockDeskTimingsPerDay,
    shortDate,
    timezone
  );

  const timeInRange = timingsPerDay.find(
    ({ lockDeskStartTime, lockDeskEndTime }) => {
      const currentTime = date.getTime();
      return currentTime >= lockDeskStartTime && currentTime < lockDeskEndTime;
    }
  );

  let timeToClose = null;
  let timeToOpen = null;
  if (timeInRange) {
    timeToClose = timeInRange.lockDeskEndTime - date.getTime();
  } else {
    const nextWindows =
      timingsPerDay.filter(
        ({ lockDeskStartTime }) => lockDeskStartTime > date.getTime()
      ) || [];
    const minNextWindow = Math.min(
      ...nextWindows.map(({ lockDeskStartTime }) => lockDeskStartTime)
    );

    timeToOpen = Number.isFinite(minNextWindow)
      ? minNextWindow - date.getTime()
      : null;
  }

  return { timeInRange, timeToClose, timeToOpen };
};
