/**
 * @type { {
 * accessToConcessionApproval: boolean,
 * customLockExtensions: boolean,
 * bypassConcessionApprover: boolean,
 * editEffectiveDate: boolean,
 * editConcessions: boolean,
 * editCustomAdjustments: boolean,
 * editLockExtension: boolean,
 * editRelockFee: boolean,
 * lockExtensionRequest: boolean,
 * priceConcessionReques: boolean,
 * viewAPR: boolean,
 * viewDeliveryAndCommitment: boolean,
 * viewInvestorGuidelineOverride: boolean,
 * viewLOComp: boolean,
 * viewMI: boolean,
 * viewProfitMargin: boolean,
 * viewRawPrices: boolean,
 * viewSRP: boolean,
 * viewTrueInvestorName: boolean,
 * accessBuyside: boolean,
 * accessSellside: boolean,
 * accessPriceAnalyzer: boolean,
 * changeIdentity: boolean,
 * accessRatesheet: boolean,
 * filterRates: boolean,
 * accessStandardView: boolean,
 * accessBestexView: boolean,
 * } }
 */
const Rights = {};
const roleMapping = {
  1: "accessToConcessionApproval",
  2: "customLockExtensions",
  3: "bypassConcessionApprover",
  4: "editEffectiveDate",
  5: "editConcessions",
  6: "editCustomAdjustments",
  7: "editLockExtension",
  8: "editRelockFee",
  9: "lockExtensionRequest",
  10: "priceConcessionReques",
  11: "viewAPR",
  12: "viewDeliveryAndCommitment",
  13: "viewInvestorGuidelineOverride",
  14: "viewLOComp",
  15: "viewMI",
  16: "viewProfitMargin",
  17: "viewRawPrices",
  18: "viewSRP",
  19: "viewTrueInvestorName",
  20: "accessBuyside",
  21: "accessSellside",
  22: "accessPriceAnalyzer",
  23: "changeIdentity",
  24: "accessRatesheet",
  25: "filterRates",
  26: "accessStandardView",
  27: "accessBestexView",
  28: "AssignLoanOfficer",
};

export const setUserRights = (rights) => {
  Object.entries(roleMapping).forEach(([id, roleName]) => {
    if (Object.getOwnPropertyDescriptor(Rights, roleName)?.writable !== false) {
      Object.defineProperty(Rights, roleName, {
        value: rights.some(({ rightId }) => Number(rightId) === Number(id)),
        writable: true,
        enumerable: true,
      });
    }
  });
};

export const setUserAdminRights = () => {
  Object.values(roleMapping).forEach((roleName) => {
    Object.defineProperty(Rights, roleName, {
      value: true,
      writable: false,
      enumerable: true,
    });
  });
};

export default Rights;
