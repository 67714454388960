import React from "react";
import { func, string, number } from "prop-types";
import { DSButton } from "@elliemae/ds-button";
import { ChevronLeft } from "@elliemae/ds-icons";
import { DSToolbar } from "@elliemae/ds-toolbar";
import { useDispatch } from "react-redux";
import {
  DSPageHeaderV2,
  PageHeaderSummary,
  PageHeaderTitle,
} from "@elliemae/ds-page-header";
import { setAllRatesRowData } from "../../../../../../Qualification/QualificationActions";

const BackArrow = () => {
  const dispatch = useDispatch();
  return (
    <DSButton
      buttonType="link"
      icon={<ChevronLeft />}
      onClick={() => dispatch(setAllRatesRowData(null))}
    />
  );
};

const ToolBar = ({ onClickProgramDetails }) => (
  <DSToolbar withDepth={false}>
    <DSButton
      buttonType="secondary"
      labelText="Program Details"
      onClick={onClickProgramDetails}
    />
  </DSToolbar>
);

const AllRatesHeader = ({ onClickProgramDetails, name, rate }) => {
  return (
    <React.Fragment>
      <DSPageHeaderV2
        backarrow={<BackArrow />}
        breadcrumbs={<div style={{ paddingTop: `0px` }}> Search Results</div>}
        pageTitle={<PageHeaderTitle>{name}</PageHeaderTitle>}
        summary={<PageHeaderSummary label="Rates" value={rate} />}
        toolbar={<ToolBar onClickProgramDetails={onClickProgramDetails} />}
        withBottomSeparator={false}
      />
      <div
        style={{
          margin: "8px 0 9.5px 0",
          display: `flex`,
          width: `100%`,
          borderBottom: `1px solid #a9b1be`,
        }}
      />
    </React.Fragment>
  );
};

AllRatesHeader.propTypes = {
  onClickProgramDetails: func.isRequired,
  name: string.isRequired,
  rate: number.isRequired,
};

ToolBar.propTypes = {
  onClickProgramDetails: func.isRequired,
};

export default AllRatesHeader;
