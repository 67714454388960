import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchFormSelector } from "components/Form/utils/customHooks";
import { searchFormActions } from "store/searchForm";
import FooterButtons from "components/FooterButtons";
import { setRedirectTabAction } from "data/landing/actions";
import { focusField } from "utils/shared";

const FooterForm = () => {
  const dispatch = useDispatch();
  const errorForm = useSearchFormSelector("errorForm").value;

  useEffect(() => {
    const { errorFields, bannerIsOpen } = errorForm;
    if (errorFields.length > 0 && bannerIsOpen) {
      dispatch(searchFormActions.setBannerIsOpen(true));
    }
  }, [errorForm.errorFields]);

  const handleSearchAction = () => {
    const { errorFields, bannerIsOpen } = errorForm;
    focusField();
    if (errorFields.length > 0 && !bannerIsOpen) {
      dispatch(searchFormActions.setBannerIsOpen(true));
    } else if (errorFields.length === 0) {
      dispatch(searchFormActions.setBannerIsOpen(false));
      dispatch(setRedirectTabAction("/search-results"));
    }
  };

  return (
    <FooterButtons
      buttons={[
        {
          type: "primary",
          text: "Search",
          onClick: handleSearchAction,
          "data-testid": "footer-button-search",
        },
      ]}
    />
  );
};

export default FooterForm;
