import { isEmpty } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import Common from "common/services/Common";
import { DSDataGrid } from "@elliemae/ds-datagrids";
import { DSModal, MODAL_TYPE_V2 } from "@elliemae/ds-modal";
import { updateEncompassElementsColumnsDef } from "./constants";

const DiffElements = () => {
  const getDiffElements =
    useSelector(
      ({
        epps: {
          lockSummary: { diffElements },
        },
      }) => diffElements
    ) || [];

  return (
    <DSModal
      modalType={MODAL_TYPE_V2.FORM}
      centered
      size="medium"
      minColumnWidth={60}
      shouldCloseOnOverlayClick
      showRejectButton
      modalTitle="Update Lock Request Fields"
      confirmLabel="Close"
      isOpen={!isEmpty(getDiffElements)}
      onClose={() => {
        Common.closeApplication();
      }}
      onConfirm={() => {
        Common.closeApplication();
      }}
    >
      <DSDataGrid
        columns={updateEncompassElementsColumnsDef}
        height={320}
        rows={getDiffElements}
        rowKey="fieldName"
      />
    </DSModal>
  );
};

export default DiffElements;
