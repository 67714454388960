import styled from "styled-components";

export const StyledLoginBody = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral["000"]};
`;

export const StyledLoginBannerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledLoginContainer = styled.div`
  width: 25em;
  min-height: 25em;
`;

export const StyledLoginLogo = styled.div`
  margin-bottom: 20px;
`;

export const StyledLoginForm = styled.div`
  border-radius: 2px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  padding-top: 20px;
`;

export const StyledLoginFormInput = styled.div`
  padding: 10px 20px;
`;

export const StyledLoginFormBotton = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 20px 25px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
