import { v4 as uuidv4 } from "uuid";

export const newOtherRealEstate = () => ({
  id: uuidv4(),
  monthlyPayment: "0",
  mtgBalance: "0",
  occupancyRate: 0,
  rentalIncome: null,
  use: null,
  value: "0",
  isPropertyCommercial: false,
});

export const paths = {
  IS_OTHER_REAL_ESTATE: {
    path: "initialValues",
    values: ["initialOtherRealEstateOwned"],
  },
  LIEN_POSITION: {
    path: "loanInformation",
    values: ["lienPosition"],
  },
  PRINT_2003_APPLICATION: {
    path: "",
    values: ["print2003Application"],
  },
  PROPERTY_TYPE: {
    path: "property",
    values: ["type"],
  },
  PROPERTY_ZIP: {
    path: "property",
    values: ["zip"],
  },
  PROPERTY_CITY: {
    path: "property",
    values: ["city"],
  },
  PROPERTY_STATE: {
    path: "property",
    values: ["state"],
  },
  PROPERTY_COUNTY: {
    path: "property",
    values: ["county"],
  },
  OTHER_REAL_ESTATE_OWNED: {
    path: "property",
    values: ["otherRealEstateOwned"],
  },
  PROPERTY_CENSUS_TRACT: {
    path: "property",
    values: ["censusTract"],
  },
  PROPERTY_STATE_CODE: {
    path: "property",
    values: ["stateCode"],
  },
  PROPERTY_COUNTY_CODE: {
    path: "property",
    values: ["countyCode"],
  },
  PROPERTY_MSA: {
    path: "property",
    values: ["msa"],
  },
};

export const pathValues = {
  ASSOCIATION_FEE: "property.associationFee",
  CENSUS_TRACT: "property.censusTract",
  CITY: "property.city",
  CONDOTEL: "loanInformation.condotel",
  COUNTY: "property.county",
  COUNTY_CODE: "property.countyCode",
  INSURANCE_AMOUNT: "property.insuranceAmount",
  MSA: "property.msa",
  NON_WARRANTABLE_CONDO: "loanInformation.non_warrantable_Condo",
  OTHER_REAL_ESTATE_OWNED: "property.otherRealEstateOwned",
  PROPERTY_TYPE: "property.type",
  PROPERTY_USE: "property.use",
  RENTAL_INCOME: "property.rentalIncome",
  RURAL: "property.rural",
  STATE: "property.state",
  STATE_CODE: "property.stateCode",
  STREET_ADDRESS: "property.streetAddress",
  TAX: "property.tax",
  UNIT_NUMBER: "property.unitNumber",
  UNIT_TYPE_ID: "property.unitTypeId",
  ZIP: "property.zip",
};

export const otherRealEstateFieldsToCheck = [
  "value",
  "rentalIncome",
  "mtgBalance",
  "monthlyPayment",
];

export const tooltipText = {
  editWithinOriginationSystem:
    "This amount is calculated from other fields in your loan origination system, and must be edited within that application.",
  buyDownType:
    "Buydown Type must be updated at their point of origination and resubmitted to EPPS",
  buyDownContributor:
    "Buydown Contributor must be updated at their point of origination and resubmitted to EPPS",
};
