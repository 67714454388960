import React from "react";
import { func } from "prop-types";
import { DSButton } from "@elliemae/ds-button";

const ProgramDetailsButton = ({ onClick }) => (
  <DSButton
    buttonType="secondary"
    labelText="Program Details"
    size="m"
    onClick={onClick}
  />
);

ProgramDetailsButton.propTypes = {
  onClick: func.isRequired,
};

export default ProgramDetailsButton;
