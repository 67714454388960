import React from "react";
import { string, bool } from "prop-types";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import Common from "common/services/Common";

import {
  MODAL_TEXT,
  REJECT_LABEL,
  CONFIRM_LABEL,
  MODAL_SUMMARY,
} from "../utils/constants";

const HeaderModal = ({ isOpen, handleOpenModal }) => (
  <DSModal
    centered
    showClose
    version={2}
    isOpen={isOpen}
    showRejectButton
    modalTitle={MODAL_TEXT}
    onClose={handleOpenModal}
    shouldCloseOnOverlayClick
    onReject={handleOpenModal}
    rejectLabel={REJECT_LABEL}
    confirmLabel={CONFIRM_LABEL}
    modalType={MODAL_TYPE_V2.DECISION}
    modalSubType={MODAL_SUB_TYPE_V2.WARNING}
    onConfirm={() => Common.closeApplication()}
  >
    {MODAL_SUMMARY}
  </DSModal>
);

HeaderModal.defaultProps = {
  handleOpenModal: () => {},
  isOpen: false,
};

HeaderModal.propTypes = {
  handleOpenModal: string,
  isOpen: bool,
};

export default HeaderModal;
