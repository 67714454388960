import React, { useState, useEffect } from "react";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { useDispatch } from "react-redux";
import {
  useLockDeskStatusActionsSelector,
  useLoanInformationField,
} from "data/useSelectors";
import { setRequestLockButtonStatus } from "data/lockDeskStatus/actionCreators";
import { WARNING_TEXT } from "../../utils/constants";
import { addThousandsSeparator } from "../../utils/helpers";

function OnrpLimitNotificationHeader() {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { onrpNotificationInfo = {} } = useLockDeskStatusActionsSelector();
  const firstMortgageAmount = useLoanInformationField("firstMortgageAmount", 0);

  useEffect(() => {
    if (onrpNotificationInfo.isUnderOnrp) {
      const isGreaterLoanAmount =
        firstMortgageAmount > onrpNotificationInfo?.remainingAmountUnderOnrp;
      setOpen(isGreaterLoanAmount);
      dispatch(setRequestLockButtonStatus(isGreaterLoanAmount));
    }
  }, [onrpNotificationInfo, firstMortgageAmount]);

  return (
    <DSBanner
      body={`The amount for this loan is $${addThousandsSeparator(
        firstMortgageAmount,
        ","
      )}, which is greater than the remaining Overnight Rate Protection amount of $${addThousandsSeparator(
        onrpNotificationInfo?.remainingAmountUnderOnrp,
        ","
      )}.`}
      isOpen={isOpen}
      label={WARNING_TEXT}
      onClose={() => setOpen(false)}
      type={BANNER_TYPES.WARNING}
      data-testid="OnrpLimitNotificationHeader-all-components"
    />
  );
}

export default OnrpLimitNotificationHeader;
