import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { getDefaultTheme } from "@elliemae/pui-theme";
import "common/components/themes/vanilla/styles/base.scss";
import GlobalErrorBoundary from "components/GlobalErrorBoundary";
import createStore from "./store";
import App from "./app/App";

// eslint-disable-next-line no-underscore-dangle
const store = createStore(window.__INITIAL_STATE__);
ReactDOM.render(
  <ThemeProvider theme={getDefaultTheme()}>
    <Provider store={store}>
      <GlobalErrorBoundary>
        <App />
      </GlobalErrorBoundary>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);
