import React from "react";
import { DataTable } from "@elliemae/ds-data-table";
import { useLockDeskStatusActionsSelector } from "data/useSelectors";
import { isEmpty } from "utils/shared";
import { timeDayInRange } from "../../../utils/helpers";

import {
  getOnrpColumn,
  normalizeLockDeskData,
  getHiddenColumn,
  getHeaderData,
  getLockDeskColumn,
} from "./utils/helpers";

const LockDeskDataTable = () => {
  const { lockDeskData } = useLockDeskStatusActionsSelector();
  const { timeToClose, timeInRange } = isEmpty(lockDeskData)
    ? []
    : timeDayInRange(lockDeskData);

  const lockData =
    normalizeLockDeskData(lockDeskData?.lockDeskTimingsPerDay) || [];
  const hiddenColumns = getHiddenColumn(lockData);
  const columns = [
    {
      Header: "Day",
      accessor: "day",
      width: hiddenColumns.length > 0 ? 150 : 80,
    },
    getLockDeskColumn(timeToClose, timeInRange),
    getOnrpColumn(timeToClose, timeInRange),
  ];
  return (
    <div>
      <p style={{ marginTop: "0px", marginBottom: "20px" }}>
        {getHeaderData(timeToClose, timeInRange)}
      </p>
      <DataTable
        data-testid="LockDeskGrid-all-components"
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={lockData}
        autoHeight
        textWrap="wrap"
      />
    </div>
  );
};

export default LockDeskDataTable;
