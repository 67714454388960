import api from "utils/api";
import Session, { APPLICATION_NAME, IS_LO } from "services/Session";

const logs = {};

const logEvent = (payload) => {
  const applicationName = Session.get(APPLICATION_NAME) || "ENCW";
  let tparam1 = "";
  if (applicationName === "ENC") tparam1 = "SmartClient";
  else if (applicationName === "ENCW") tparam1 = "LOC";
  else if (applicationName === "TPOC") tparam1 = "TPOC";
  if (Session.get(IS_LO)) {
    api.logEvent({ tparam1, ...payload });
  } else {
    // eslint-disable-next-line no-console
    console.log({ tparam1, ...payload });
  }
};

export const startLog = (event) => {
  logs[event] = new Date();
};
export const stopLog = (event) => {
  if (logs[event]) {
    const endTime = new Date();
    const elapsedTime = endTime - logs[event]; // in ms
    const eventDefId = Number(event);
    delete logs[event];
    logEvent({ eventDefId, elapsedTime });
  }
};
