import { SET_TAB_KEY } from "common/constants/ActionConstants";
import browserHistory from "utils/browserHistory";
import Session, { APPLICATION_TYPE } from "services/Session";

export const setRedirectTabAction = (pathname) => {
  const search = Session.get(APPLICATION_TYPE) === "LO" ? "?App=LO" : "";
  browserHistory.push({ pathname, search });
  return {
    type: SET_TAB_KEY,
    payload: pathname,
  };
};
