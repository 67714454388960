/* eslint-disable react/prop-types */

import { useSelector, shallowEqual } from "react-redux";
import { get } from "lodash";

// Given an object, a string path ("path1.path2.path3") and a new
// value, it assigns object[path1][path2][path3] = newValue.
export const recursivePropAccess = (obj, propPath, newPropValue) => {
  let objRef = { ...obj };
  propPath.split(".").forEach((prop, i, arr) => {
    if (i < arr.length - 1) {
      objRef = objRef[prop];
    } else {
      objRef[prop] = newPropValue;
    }
  });
  return obj;
};

// Gets a value from loanInfo
const fieldValueSelector = (nameOrPath, defaultValue = null) => ({
  EPPS: { loanInfo = {} },
}) => {
  if (typeof nameOrPath === "string") {
    return recursivePropAccess(loanInfo, nameOrPath, defaultValue);
  }
  if (Array.isArray(nameOrPath)) {
    const valuesSelector = {};
    nameOrPath.forEach((group) => {
      const { path, values } = group;
      if (!Array.isArray(values)) return;
      values.forEach((value) => {
        valuesSelector[value] = loanInfo[path]?.[value] || null;
      });
    });
    return valuesSelector;
  }
  return null;
};

// Returns a value from loanInfo using fieldValueSelector
export const useFieldValueSelector = (pathValue) =>
  useSelector(fieldValueSelector(pathValue), shallowEqual);

// Updates a loanInfo value
export const setLoanDataValue = (value, route) => (dispatch, state) => {
  const loanData = get(state(), "EPPS.loanInfo") || {};
  dispatch({
    type: "UPDATE_LOANINFO",
    payload: recursivePropAccess(loanData, route, value),
  });
};

export const replaceValue = (value, replace) => {
  return value.replace(`${replace}`, "").replace(/,/g, "");
};

// Updates max length for float number strings
export const getMaxLength = (value, defaultMax = 0) => {
  if (typeof value === "string" && value.split(".").length > 1) {
    return defaultMax + 1;
  }
  return defaultMax;
};

const isObjectDate = (obj) => {
  return (
    obj &&
    typeof obj.getMinutes === "function" &&
    typeof obj.getHour === "function" &&
    typeof obj.getDate === "function" &&
    typeof obj.getMonth === "function" &&
    typeof obj.getFullYear === "function"
  );
};

// Takes a JS Date as input and returns HH:MM AM/PM
export const formatTime = (date) => {
  if (!isObjectDate(date)) return "";
  let hours = date.getHours() % 12;
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  if (hours === 0 || hours === "0") {
    hours = 12; // the hour '0' should be '12'
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  return `${hours}:${minutes} ${ampm}`;
};

// Takes a JS Date as input and returns MM/DD/YYYY
export const formatDate = (date) => {
  if (!isObjectDate(date)) return "";
  const year = date.getFullYear();
  let month = (1 + date.getMonth()).toString();
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date.getDate().toString();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${month}/${day}/${year}`;
};

export const formatDateTime = (date) => {
  return `${formatDate(date)} ${formatTime(date)}`;
};
