import React from "react";
import { arrayOf, bool, number, shape, string } from "prop-types";
import { DSLabelValue } from "@elliemae/ds-label-value";
import { GridContainer, GridItem } from "@elliemae/ds-grid";
import { DSInputMask, MASK_TYPES } from "@elliemae/ds-form";
import StyledDSLabel from "./styled";

function SummarySection({ fields, cols }) {
  return (
    <GridContainer cols={cols}>
      {fields.map(
        ({
          label,
          value,
          hidden,
          span = 1,
          dataTestID,
          editable,
          mask,
          setValue,
          onBlur,
        }) =>
          !hidden && (
            <GridItem span={span} key={label} data-testid={dataTestID}>
              <StyledDSLabel>
                <DSLabelValue>
                  <DSLabelValue.Label>{label}</DSLabelValue.Label>
                  {!editable && (
                    <DSLabelValue.Value>{value}</DSLabelValue.Value>
                  )}
                  {editable && (
                    <DSInputMask
                      value={String(value)}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                      onBlur={onBlur}
                      mask={(val) => MASK_TYPES.NUMBER(mask)(val)}
                    />
                  )}
                </DSLabelValue>
              </StyledDSLabel>
            </GridItem>
          )
      )}
    </GridContainer>
  );
}

SummarySection.defaultProps = {
  fields: [],
  cols: 7,
};

SummarySection.propTypes = {
  fields: arrayOf(
    shape({ label: string, value: string, hidden: bool, span: number })
  ),
  cols: number,
};

export default SummarySection;
