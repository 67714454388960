import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { DSButtonV2, BUTTON_TYPES } from "@elliemae/ds-button";
import { DSDropdownMenuV2 } from "@elliemae/ds-dropdownmenu";
import { DSTooltipV3, tooltipPositions } from "@elliemae/ds-tooltip";
import { ChevronDown, ChevronUp } from "@elliemae/ds-icons";
import styled from "styled-components";

const StyledDSButtonV2 = styled(DSButtonV2)`
  display: flex;
  gap: 0;
  margin-right: 8px;
`;

const TooltipText =
  "When you select any loan term, all other loan terms will be removed.";

const dropDownIconStyle = { marginLeft: "8px" };

const QuoteInsuranceDropdown = ({ label, dropdownMenuItems, onSelect }) => {
  const btnRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelection = () => {
    setIsDropdownOpen(false);
    onSelect();
  };

  return (
    <DSDropdownMenuV2
      isOpened={isDropdownOpen}
      options={dropdownMenuItems}
      selectedOptions={{}}
      onOptionClick={handleSelection}
      onClickOutside={() => {
        setIsDropdownOpen(false);
        btnRef.current.focus();
      }}
    >
      <DSTooltipV3
        text={TooltipText}
        startPlacementPreference={tooltipPositions.TOP}
        withoutPortal={false}
      >
        <StyledDSButtonV2
          buttonType={BUTTON_TYPES.OUTLINE}
          onClick={() => setIsDropdownOpen((prevOpened) => !prevOpened)}
          innerRef={btnRef}
          aria-label={`${label} ${TooltipText}`}
        >
          {label}
          {isDropdownOpen ? (
            <ChevronUp style={dropDownIconStyle} />
          ) : (
            <ChevronDown style={dropDownIconStyle} />
          )}
        </StyledDSButtonV2>
      </DSTooltipV3>
    </DSDropdownMenuV2>
  );
};

QuoteInsuranceDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  dropdownMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      dsId: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default QuoteInsuranceDropdown;
