import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import createAction from "utils/createAction";
import { SET_BUYSIDE_ADJUSTMENTS, UPDATE_BUYSIDE_ADJUSTMENTS } from "./actions";

export const setBuySideAdjustmentsAction = (adjustments) => {
  let mappedAdjustments = adjustments ?? [];
  if (adjustments.length > 0)
    mappedAdjustments = adjustments.reduce(
      (acum, adj) => ({
        ...acum,
        [adj.adjustmentType]: [
          ...(acum[adj.adjustmentType] ?? []),
          {
            id: adj.id || uuidv4(),
            price: adj.price ? Number(adj.price).toFixed(3) : null,
            rate: adj.rate ? Number(adj.rate).toFixed(3) : null,
            description: adj.description,
            adjustmentType: adj.adjustmentType,
            persist: true,
            persistDisabled: false,
            ...(adj.approvalDate && {
              approvalDate: moment(adj.approvalDate).format("MM/DD/YYYY"),
            }),
            ...(adj.approvedReason && { approvedReason: adj.approvedReason }),
            ...(adj.approvedBy && { approvedBy: adj.approvedBy }),
          },
        ],
      }),
      {}
    );
  return createAction(SET_BUYSIDE_ADJUSTMENTS)(mappedAdjustments);
};

export const updateBuySideAdjustmentsAction = (adjustments, type) => {
  const payload = { type, data: adjustments };
  return createAction(UPDATE_BUYSIDE_ADJUSTMENTS)(payload);
};
