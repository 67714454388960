const productInformationValidators = {
  rentalIncome: {
    allowedFalseyValues: [0],
    fieldName: "rentalIncome",
    message: "Required field",
    required: ({ required }) => required,
  },
  state: {
    fieldName: "state",
    required: ({ required }) => required,
    message: "Required field",
  },
  states: {
    fieldName: "states",
    required: ({ required }) => required,
    message: "Required field",
  },
  county: {
    fieldName: "county",
    required: ({ required }) => required,
    message: "Required field",
  },
  city: {
    fieldName: "city",
    required: ({ required }) => required,
    message: "Required field",
  },
  zipCode: {
    fieldName: "zipCode",
    required: ({ required }) => required,
    message: "Required field",
  },
  associationFee: {
    allowedFalseyValues: [0],
    fieldName: "associationFee",
    required: ({ required }) => required,
    message: "Required field",
  },
  insuranceAmount: {
    allowedFalseyValues: [0],
    fieldName: "insuranceAmount",
    required: ({ required }) => required,
    message: "Required field",
  },
  propertyTaxes: {
    allowedFalseyValues: [0],
    fieldName: "propertyTaxes",
    required: ({ required }) => required,
    message: "Required field",
  },
  propertyUse: {
    fieldName: "propertyUse",
    required: ({ required }) => required,
    message: "Required field",
  },
  propertyTypes: {
    fieldName: "propertyTypes",
    required: ({ required }) => required,
    message: "Required field",
  },
  mtgBalance: {
    allowedFalseyValues: [0],
    fieldName: "mtgBalance",
    required: ({ required }) => required,
    message: "Required field",
  },
  monthlyPayment: {
    allowedFalseyValues: [0],
    fieldName: "monthlyPayment",
    required: ({ required }) => required,
    message: "Required field",
  },
  value: {
    allowedFalseyValues: [0],
    fieldName: "value",
    required: ({ required }) => required,
    message: "Required field",
  },
};

export default productInformationValidators;
