import React, { memo } from "react";
import PropTypes from "prop-types";
import { GridForm, InputMaskForm } from "components/Form";
import { Grid } from "@elliemae/ds-grid";
import * as masks from "view/SearchForm/utils/constants";
import * as dependencies from "./utils/dependencies";
import { checkGiftFundReadOnly } from "./helpers";

const SupplementalFunding = ({ handleSetLoanDataValue }) => {
  return (
    <Grid cols={5} gutter="xs" alignItems="flex-start">
      <GridForm span={1} extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}>
        <InputMaskForm
          clearable
          maxLength={16}
          name="giftAmount"
          label="Gift Funds $"
          maskOpts={masks.NOT_NEGATIVE_VALUE}
          onKeyUp={handleSetLoanDataValue}
          pathValue="loanInformation.giftForDownPayment"
          extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}
          readOnly={checkGiftFundReadOnly}
          placeholder="$0"
          customValue={({ value }) =>
            value === null ? "" : Math.trunc(Number(value))
          }
        />
      </GridForm>
      <GridForm span={1} extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}>
        <InputMaskForm
          clearable
          maxLength={16}
          maskOpts={masks.PERCENT_VALUES}
          maskType="PERCENT"
          name="giftPercent"
          label="Gift Funds %"
          extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}
          onBlur={({ customDependencies, ...rest }) => {
            const { value } = customDependencies;
            const giftAmount = Math.round(
              value * (rest.value / 100)
            ).toString();
            handleSetLoanDataValue(rest);
            handleSetLoanDataValue({
              value: giftAmount,
              pathValue: "loanInformation.giftForDownPayment",
            });
          }}
          dependencies={dependencies.GIFT_PERCENT}
          customValue={({ customDependencies }) => {
            const { giftForDownPayment, value } = customDependencies;
            return (
              Math.round((Math.abs(giftForDownPayment) / value) * 100000) / 1000
            ).toString();
          }}
          readOnly={checkGiftFundReadOnly}
        />
      </GridForm>
      <GridForm span={1} extraDependencies={dependencies.EXTRA_SELLER_AMOUNT}>
        <InputMaskForm
          clearable
          maxLength={16}
          name="sellerAmount"
          label="Seller Concessions $"
          maskOpts={masks.DECIMAL_PLACES}
          onKeyUp={handleSetLoanDataValue}
          dependencies={dependencies.SELLER_AMOUNT}
          pathValue="loanInformation.sellerConsessionForDownPayment"
          extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}
          placeholder="$0"
          customValue={({ value }) => (value === null ? "" : Number(value))}
        />
      </GridForm>
      <GridForm span={1} extraDependencies={dependencies.EXTRA_SELLER_AMOUNT}>
        <InputMaskForm
          clearable
          maxLength={16}
          maskOpts={masks.PERCENT_VALUES}
          maskType="PERCENT"
          name="sellerPercent"
          label="Seller Concessions %"
          extraDependencies={dependencies.EXTRA_GIFT_AMOUNT}
          onBlur={({ customDependencies, ...rest }) => {
            const { value } = customDependencies;
            const sellerAmount = Math.round(
              value * (rest.value / 100)
            ).toString();
            handleSetLoanDataValue(rest);
            handleSetLoanDataValue({
              value: sellerAmount,
              pathValue: "loanInformation.sellerConsessionForDownPayment",
            });
          }}
          dependencies={dependencies.SELLER_PERCENT}
          customValue={({ customDependencies }) => {
            const {
              sellerConsessionForDownPayment,
              value,
            } = customDependencies;
            return (
              Math.round(
                (Math.abs(sellerConsessionForDownPayment) / value) * 100000
              ) / 1000
            ).toString();
          }}
        />
      </GridForm>
    </Grid>
  );
};

SupplementalFunding.defaultProps = {
  handleSetLoanDataValue: () => {},
};

SupplementalFunding.propTypes = {
  handleSetLoanDataValue: PropTypes.func,
};

export default memo(SupplementalFunding);
