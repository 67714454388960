export const INITIAL_STATE = {
  loanData: {},
  originalLoanData: {},
  isFirstLoad: true,
  lockedProgram: {},
  buySideAdjustments: {
    CustomPriceAdjustment: [],
    ReLockFeeAdjustment: [],
    LockExtensionAdjustment: [],
    CorporatePriceConcession: [],
    BranchPriceConcession: [],
  },
};
