import React from "react";
import { DSCardArray } from "@elliemae/ds-card-array";
import { DSLabelValue } from "@elliemae/ds-label-value";
import { DSRadio } from "@elliemae/ds-form";
import { DSSeparator } from "@elliemae/ds-separator";
import { DSTooltipV2 } from "@elliemae/ds-tooltip";
import { DSRibbon } from "@elliemae/ds-ribbon";
import {
  LockLockedNewLockRequested,
  AlertsDetailFill,
} from "@elliemae/ds-icons";
import { get } from "lodash";
import { dispCurrency, dispDiscount } from "./helpers";
import { formatRate } from "../helpers";

import {
  StyledLockUpdateBox,
  StyledLockUpdateBoxDate,
  StyledLockStatusLabel,
  StyledRateNotAvailable,
  StyledWorseCaseText,
  StyledDetailsRibbonContainer,
} from "./styled";

const LockUpdateBox = (props) => {
  const isduplicateFlag = get(props, "isduplicateFlag") || "";
  const lockDate = get(props, "lockDate") || "";
  const loanEventData = get(props, "loanEventData") || "";
  const handleLoanSelect = get(props, "handleLoanSelect") || "";
  const selectedEventBox = get(props, "selectedEventBox") || "";
  const lockIcon = get(props, "lockIcon") || "";
  const worseIcon = get(props, "worseIcon") || "";
  const uniqId = get(props, "uniqId") || "";
  const lockStatus = get(props, "lockStatus") || "";
  const defaultDateTime = get(props, "defaultDateTime") || "";
  const effectiveDate = get(props, "effectiveDate") || "";

  return (
    <StyledLockUpdateBox>
      <div className="grid-x" style={{ width: "100%" }}>
        <div className="cell large-12">
          {!isduplicateFlag ? (
            <StyledLockUpdateBoxDate>
              <DSLabelValue>
                <DSLabelValue.Label size="s">{lockDate}</DSLabelValue.Label>
              </DSLabelValue>
            </StyledLockUpdateBoxDate>
          ) : (
            <StyledLockUpdateBoxDate duplicateBox>
              <DSLabelValue>
                <DSLabelValue.Label size="s" />
              </DSLabelValue>
            </StyledLockUpdateBoxDate>
          )}
        </div>
        <div className="cell large-12">
          <DSCardArray.CardBox
            className={
              selectedEventBox === uniqId && !loanEventData.rateNotAvailable
                ? "relock-box-select-border"
                : ""
            }
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              paddingBottom: "0.4rem",
              backgroundColor: loanEventData.rateNotAvailable && "#F6F7F9",
            }}
          >
            <div className="grid-x">
              {loanEventData.rateNotAvailable ? (
                <div className="cell medium-4 large-4">
                  <StyledRateNotAvailable>
                    <DSLabelValue>
                      <DSLabelValue.Value>Rate Unavailable</DSLabelValue.Value>
                    </DSLabelValue>
                  </StyledRateNotAvailable>
                </div>
              ) : (
                <div className="cell medium-2 large-2">
                  <DSRadio
                    labelText={(100 - loanEventData.price).toFixed(3)}
                    value={uniqId}
                    checked={selectedEventBox === uniqId}
                    onChange={() =>
                      handleLoanSelect({
                        effectiveDate,
                        rateDataID: loanEventData.rateDataID,
                        programID: loanEventData.programID,
                        uId: uniqId,
                      })
                    }
                  />
                </div>
              )}

              <div className="cell medium-1 large-1">
                {lockIcon && (
                  <DSTooltipV2
                    title="Rate Lock has been requested for this item."
                    placement="bottom"
                    zIndex={5000}
                    triggerComponent={
                      <LockLockedNewLockRequested
                        size="s"
                        color={["brand-primary", "600"]}
                        style={{ marginLeft: "10px" }}
                      />
                    }
                  />
                )}
              </div>
              {loanEventData.rateNotAvailable ? (
                <div className="cell medium-3 large-3" />
              ) : (
                <div className="cell medium-5 large-5" />
              )}
              <div
                className="cell medium-4 large-4"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <StyledWorseCaseText>
                  {worseIcon && (
                    <>
                      <DSLabelValue>
                        <DSLabelValue.Value color="neutral500">
                          Worst Case
                          <AlertsDetailFill
                            size="s"
                            color={["warning", "600"]}
                            style={{ margin: "0px auto 0px 15px" }}
                          />
                        </DSLabelValue.Value>
                      </DSLabelValue>
                    </>
                  )}
                </StyledWorseCaseText>
              </div>

              <div
                className="cell large-12"
                style={{
                  marginLeft: loanEventData.rateNotAvailable ? "0" : ".8rem",
                }}
              >
                <StyledLockStatusLabel>
                  <DSLabelValue>
                    <DSLabelValue.Value color="neutral500">
                      {lockStatus}
                    </DSLabelValue.Value>
                  </DSLabelValue>
                </StyledLockStatusLabel>
              </div>
              <div className="cell large-12">
                <DSSeparator
                  margin="s"
                  orientation="horizontal"
                  position="initial"
                  type="non-form"
                  color="neutral500"
                />
              </div>
              <div className="cell large-12">
                <StyledDetailsRibbonContainer>
                  <DSRibbon.RibbonWrapper>
                    <DSRibbon.RibbonRow>
                      <DSRibbon.RibbonCol>
                        <DSRibbon.RibbonCard
                          value={
                            loanEventData.basePrice != null
                              ? `${(100 - loanEventData.basePrice).toFixed(3)}`
                              : "--"
                          }
                          additionalLabel="Base"
                        />
                      </DSRibbon.RibbonCol>
                      <DSRibbon.RibbonCol>
                        <DSRibbon.RibbonCard
                          value={
                            loanEventData.price != null
                              ? dispDiscount(loanEventData.price * -1)
                              : "--"
                          }
                          additionalLabel="Discount/Rebate Points"
                        />
                      </DSRibbon.RibbonCol>
                      <DSRibbon.RibbonCol>
                        <DSRibbon.RibbonCard
                          value={dispCurrency(loanEventData.priceVal)}
                          additionalLabel="Discount/Rebate"
                        />
                      </DSRibbon.RibbonCol>
                      <DSRibbon.RibbonCol>
                        <DSRibbon.RibbonCard
                          value={formatRate(loanEventData.rate)}
                          additionalLabel="Rate"
                        />
                      </DSRibbon.RibbonCol>
                      <DSRibbon.RibbonCol>
                        <DSRibbon.RibbonCard
                          value={`${defaultDateTime} ET`}
                          additionalLabel="Time"
                        />
                      </DSRibbon.RibbonCol>
                    </DSRibbon.RibbonRow>
                  </DSRibbon.RibbonWrapper>
                </StyledDetailsRibbonContainer>
              </div>
            </div>
          </DSCardArray.CardBox>
        </div>
      </div>
    </StyledLockUpdateBox>
  );
};

export default LockUpdateBox;
