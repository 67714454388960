export const getMaxLength = (value = "", defaultLength = 0) => {
  if (typeof value === "string") {
    if (value.split(".").length > 1) {
      return defaultLength + 1;
    }
  }
  return defaultLength;
};

export const replaceValue = (value, replace) => {
  return value.replace(`${replace}`, "").replace(/,/g, "");
};

export const parseValue = (value) => {
  return ![undefined, null, ""].includes(value) ? parseFloat(value) : "";
};

export const recursivePropAccess = (obj, propAccess, propValue) => {
  const copyObject = JSON.parse(JSON.stringify(obj));
  let objRef = copyObject;
  propAccess
    .replace("[", ".")
    .replace("]", "")
    .split(".")
    .forEach((prop, i, arr) => {
      if (i < arr.length - 1) {
        objRef = objRef[prop];
      } else {
        objRef[prop] = propValue;
      }
    });
  return copyObject;
};
