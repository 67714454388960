import React from "react";
import PropTypes from "prop-types";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import CustomSelectionDataTable from "./CustomSelectionDataTable";

const MortgageInsurancePrograms = ({ handleClose }) => {
  return (
    <DSModal
      containerProps={{ "data-testid": "mortgage-insurance-programs-modal" }}
      isOpen
      modalSubType={MODAL_SUB_TYPE_V2.INFORMATION}
      modalTitle="Select Mortgage Insurance"
      confirmLabel="Save"
      rejectLabel="Cancel"
      modalType={MODAL_TYPE_V2.FORM}
      onClose={handleClose}
      onConfirm={handleClose}
      onReject={handleClose}
      shouldCloseOnOverlayClick
      showClose
      showRejectButton
      version={2}
      size="x-large"
    >
      <CustomSelectionDataTable />
    </DSModal>
  );
};

MortgageInsurancePrograms.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default MortgageInsurancePrograms;
