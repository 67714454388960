// TODO: These are going to generated from Lookup API

export const loanData = {
  requestAction: 1,
  requestLockStatus: 0,
  requestLockType: 1,
  sourceApplicationName: "",
  sourceApplicationFormName: "",
  eppsUserName: "",
  facadeUserID: "",
  userTierId: 0,
  lockDays: [30],
  loanType: "",
  deliveryType: "",
  buydownContributor: 0,
  buydownType: 0,
  relockRequest: {
    effectiveDate: "",
    specificProgramID: 0,
    specificRateDataID: 0,
  },
  loanInformation: {
    loanId: 0,
    lienPosition: 1,
    loanPurpose: 1,
    firstMortgageAmount: 300000,
    secondMortgageAmount: null,
    totalMortgageAmount: 300000,
    financedAmount: null,
    otherPayment: null,
    cashOut: 0,
    target: 100,
    includeClosingCost: true,
    noClosingCost: true,
    lenderFeeWaiver: true,
    communityAffordableSecond: true,
    loanChannel: 0,
    noLoan: null,
    giftForDownPayment: 0,
    sellerConsessionForDownPayment: 0,
    helocInitialDraw: 0,
    helocCreditLimit: 0,
    totSubClosedEndMort: 0,
    helocDrawnTotal: 0,
    loanAmountMaxLine: 0,
    fhaCaseNumberDate: "2022-05-31",
    prepaymentPenaltyTerm: 0,
    condotel: true,
    selfEmployedBorrower: false,
    selfEmployedCoBorrower: false,
    non_warrantable_Condo: true,
    usdaGuarantee: 0,
    otherHELOCDraws: 0,
    otherHELOCLines: 0,
    print2003Application: "",
    commitmentType: "",
    estimatedClosingCost: 0,
    averageRepresentativeCreditScore: "500",
  },
  compensation: {
    model: 1,
    points: 0,
    pointsTotalBased: "string",
    amount: 1,
    minimum: 0,
    maximum: 0,
  },
  agencyApprovals: 0,
  productType: [1],
  productOptions: [1],
  specialProducts: [0],
  standardProducts: [1, 5, 6],
  documentationLevel: 1,
  borrowers: [
    {
      firstName: "EPPS",
      lastName: "CONV FIXED Loan",
      phone: "string",
      streetAddress: "string",
      city: "Parsippany",
      state: "NJ",
      zip: "07045",
      email: "EPPS@abcc.com",
      borrowerFinanancial: {
        creditScore: 800,
        liquidAsset: 2500000,
        retirementAsset: 2500000,
        income: 200000,
        monthlyDebt: 0,
      },
      citizenship: 1,
    },
  ],
  borrowerFinancialHistory: {
    "30DaysMortgageLatestin12Months": 0,
    "60DaysMortgageLatestin12Months": 0,
    "90DaysMortgageLatestin12Months": 0,
    "30DaysMortgageLatestin24Months": 0,
    "60DaysMortgageLatestin24Months": 0,
    "90DaysMortgageLatestin24Months": 0,
    "120DaysMortgageLatestin12Months": 0,
    noticeOfDefaultForeClosure: null,
    bankruptcyInMonths: null,
    demonstrateHousingPaymentHistory: true,
    firstTimeHomeBuyers: true,
    noCreditScores: false,
    dscr: 5,
    nonQMDocLevel: 1,
    bkType: 1,
    nodType: 1,
  },
  property: {
    value: 550000,
    type: 1,
    use: 1,
    zip: "07901",
    streetAddress: "2323 Main St",
    city: "Summit",
    county: "Union",
    state: "NJ",
    rural: true,
    tax: 0,
    insuranceAmount: 0,
    associationFee: 0,
    rentalIncome: 0,
    occupancyRate: 2,
    propValueUsed: 0,
    otherRealEstateOwned: [
      {
        use: 1,
        value: 0,
        mtgBalance: 0,
        monthlyPayment: 0,
        rentalIncome: 0,
        occupancyRate: 2,
        isPropertyCommercial: true,
      },
    ],
    gseRefinancePurposeType: "string",
    stateCode: 0,
    countyCode: 0,
    censusTract: 0,
    msa: 0,
    unitTypeId: 1,
    unitNumber: "5656",
  },
  lockRequestAdditionalFields: [
    {
      fieldId: "string",
      dataType: "string",
      value: "string",
      description: "string",
      options: [
        {
          key: "string",
          value: "string",
        },
      ],
    },
  ],
  customId: "",
};
export const loanTypes = [
  {
    key: "30 Year Fixed",
    value: 1,
  },
  {
    key: "25 Year Fixed",
    value: 20,
  },
  {
    key: "20 Year Fixed",
    value: 2,
  },
  {
    key: "15 Year Fixed",
    value: 3,
  },
  {
    key: "10 Year Fixed",
    value: 4,
  },
  {
    key: "10 Year ARM",
    value: 5,
  },
  {
    key: "7 Year ARM",
    value: 6,
  },
  {
    key: "5 Year ARM",
    value: 7,
  },
  {
    key: "3 Year ARM",
    value: 8,
  },
  {
    key: "1 Year ARM",
    value: 10,
  },
];

export const loanProperties = {
  baseLoanAmount: 40000,
  loanAmortizationTermMonths: 30,
  loanAmortizationType: "Purchase",
  propertyAppraisedValueAmount: 50000,
  propertyEstimatedValueAmount: 5000000,
  purchasePriceAmount: 50000,
};

export const originalLoanInformation = {
  id: "9defc547-2780-47f8-ad72-13a2f340dc5c",
  baseLoanAmount: 40000,
  loanAmortizationTermMonths: 360,
  property: {
    streetAddress: "PVS Apartment",
    city: "Los Angeles",
    state: "CA",
    postalCode: "90013",
    loanPurposeType: "Purchase",
    county: "Los Angeles",
    propertyUsageType: "PrimaryResidence",
    loanPurposeTypeUrla: "Purchase",
  },
  applications: [
    {
      borrower: {
        firstNameWithMiddleName: "EPPS-28810_1",
        lastNameWithSuffix: "EPPS-28810_1",
      },
      coborrower: {
        firstNameWithMiddleName: "EPPS-28810_1",
        lastNameWithSuffix: "EPPS-28810_1",
      },
      propertyUsageType: "PrimaryResidence",
      totalMonthlyPaymentAmount: 0,
      assetsAvailableAmount: 10000,
      proposedFirstMortgageAmount: 969.3,
      bottomRatioPercent: 10,
      topRatioPercent: 12,
    },
  ],
  cashFromToBorrowerAmount: 10000,
  propertyAppraisedValueAmount: 50000,
  loanProductData: {
    subsequentRateAdjustmentMonthsCount: 0,
    gsePropertyType: "Attached",
    loanScheduledClosingDate: "2021-07-05T00:00:00",
  },
  loanProgramName: "30 Year Fixed",
  firstSubordinateLienAmount: 40000,
  subjectPropertyGrossRentalIncomeAmount: 0,
  mortgageType: "Conventional",
  subjectPropertyOccupancyPercent: 0,
  miAndFundingFeeFinancedAmount: 0,
  rateLock: {
    buySideLockExpires: "2020-09-07T16:19:03Z",
    gsePropertyType: "Attached",
    propertyUsageType: "PrimaryResidence",
    mortgageType: "Conventional",
    loanAmortizationType: "Fixed",
    loanAmortizationTermMonths: 360,
    balloonLoanMaturityTermMonths: 360,
    requestPrepayPenalty: "N",
    gfpmYears: 0,
    requestImpoundWaived: "Not Waived",
    borrLenderPaid: "Lender Paid",
    baseLoanAmount: 40000,
    borrowerRequestedLoanAmount: 40000,
    requestLockType: "NewLock",
    requestLockStatus: "Not Locked",
    loanDocumentationType: "FullDocumentation",
    firstTimeHomebuyersIndicator: false,
    twelveMonthMortgageRentalHistoryIndicator: false,
    subjectPropertyNonWarrantableProjectIndicator: false,
    subjectPropertyCondotelIndicator: false,
    lockRequestLoanPurposeType: "Purchase",
    purchasePriceAmount: 50000,
    propertyEstimatedValueAmount: 5000000,
    helocActualBalance: "0.00",
    firstSubordinateAmount: 40000,
    helocCreditLimit: 0,
    lienPriorityType: "FirstLien",
    subjectPropertyCity: "Los Angeles",
    subjectPropertyState: "CA",
    subjectPropertyPostalCode: "90013",
    subjectPropertyStreetAddress: "PVS Apartment",
    subjectPropertyCounty: "Los Angeles",
    propertyAppraisedValueAmount: 50000,
    financedNumberOfUnits: "1",
  },
  closingCost: {
    closingDisclosure3: {
      excludeBorrowerClosingCosts: false,
    },
  },
  loCompensation: {
    basePlanRateForBroker: 0,
  },
  vaLoanData: {
    creditScore: 850,
  },
  hmda: {
    stateCode: 6,
  },
  options: 0,
  fnmCommunitySecondsIndicator: false,
  print2003Application: "2020",
  propertyEstimatedValueAmount: 5000000,
  borrowerRequestedLoanAmount: 40000,
  miscellaneous: {
    optimalBlueHistoryData: "",
  },
  purchasePriceAmount: 50000,
  loanAmortizationType: "Fixed",
  ltv: 80,
  combinedLtv: 80,
  hcltvHtltv: 80,
  lockRequests: [
    {
      timeReqbuySideNumDayExtendeduested: 0,
      id: "42623887-e29f-455e-aef1-da4ec88471b1",
      numDayLocked: 0,
      requestedBy: "admin",
      requestedStatus: "Old Request",
      requestedDate: "2021-07-07T21:19:03Z",
      timeRequested: "2:19:03 PM",
    },
    {
      timeReqbuySideNumDayExtendeduested: 0,
      id: "a90e6163-b4b5-4988-bedb-794c320916c7",
      numDayLocked: 0,
      requestedBy: "admin",
      requestedStatus: "Old Request",
      requestedDate: "2021-07-07T21:19:57Z",
      timeRequested: "2:19:57 PM",
    },
    {
      timeReqbuySideNumDayExtendeduested: 0,
      id: "37b86e87-2ede-4e96-8bf4-d3f6e6d20b8e",
      numDayLocked: 0,
      requestedBy: "admin",
      requestedStatus: "Old Request",
      requestedDate: "2021-07-07T21:20:51Z",
      timeRequested: "2:20:51 PM",
    },
    {
      timeReqbuySideNumDayExtendeduested: 0,
      id: "4021f64c-0300-4bc8-92b2-0d7feb7f1a2d",
      numDayLocked: 0,
      requestedBy: "admin",
      requestedStatus: "Old Request",
      requestedDate: "2021-07-07T21:21:42Z",
      timeRequested: "2:21:42 PM",
    },
    {
      timeReqbuySideNumDayExtendeduested: 0,
      id: "34003f29-1973-4a7d-97bb-2b49aaeb4c3b",
      numDayLocked: 0,
      requestedBy: "admin",
      requestedStatus: "Requested",
      requestedDate: "2021-07-10T00:22:35Z",
      timeRequested: "5:22:35 PM",
    },
  ],
};
export const productOptions = [
  {
    key: "Interest Only",
    value: 4,
  },
  {
    key: "Waive Escrows",
    value: 2,
  },
  {
    key: "No MI",
    value: 7,
  },
  {
    key: "Lender Paid MI",
    value: 1,
  },
  {
    key: "40-Year Amortization",
    value: 5,
  },
];

export const standardProducts = [
  {
    key: "Agency",
    value: 1,
  },
  {
    key: "Non-Agency",
    value: 5,
  },
  {
    key: "FHA",
    value: 2,
  },
  {
    key: "VA",
    value: 3,
  },
  {
    key: "USDA",
    value: 4,
  },
];

export const specialProducts = [
  {
    key: "FHA 100 Repo",
    value: 0,
  },
  {
    key: "FHA 203",
    value: 0,
  },
  {
    key: "FHA 203k",
    value: 51,
  },
  {
    key: "FHA Streamline",
    value: 27,
  },
  {
    key: "FHLMC",
    value: 0,
  },
  {
    key: "Option 1",
    value: 0,
  },
  {
    key: "Option 2",
    value: 0,
  },
  {
    key: "Option 3",
    value: 0,
  },
  {
    key: "Option 4",
    value: 0,
  },
  {
    key: "Option 5",
    value: 0,
  },
];

export const foreclosure = [
  {
    key: "Never or none in the last 7 years",
    value: null,
  },
  {
    key: "None in last 60 months",
    value: 60,
  },
  {
    key: "None in last 48 months",
    value: 48,
  },
  {
    key: "None in last 36 months",
    value: 36,
  },
  {
    key: "None in last 24 months",
    value: 24,
  },
  {
    key: "None in last 18 months",
    value: 18,
  },
  {
    key: "None in last 12 months",
    value: 12,
  },
  {
    key: "Currently in Default",
    value: 0,
  },
  {
    key: "None in last 6 months",
    value: 6,
  },
  {
    key: "Within last 6 months",
    value: 1,
  },
];

export const bankruptcy = [
  {
    key: "Never or none in the last 7 years",
    value: null,
  },
  {
    key: "None in last 60 months",
    value: 60,
  },
  {
    key: "None in last 48 months",
    value: 48,
  },
  {
    key: "None in last 36 months",
    value: 36,
  },
  {
    key: "None in last 24 months",
    value: 24,
  },
  {
    key: "None in last 18 months",
    value: 18,
  },
  {
    key: "None in last 12 months",
    value: 12,
  },
  {
    key: "Currently in Bankruptcy",
    value: 0,
  },
  {
    key: "None in last 6 months",
    value: 6,
  },
  {
    key: "Within last 6 months",
    value: 1,
  },
];

export const mortgageData = [
  "30DaysMortgageLatestin12Months",
  "60DaysMortgageLatestin12Months",
  "90DaysMortgageLatestin12Months",
  "30DaysMortgageLatestin24Months",
  "60DaysMortgageLatestin24Months",
  "90DaysMortgageLatestin24Months",
];

export const newEmployment = {
  100: [
    { label: "Employed or Retired (can Verify)", value: 1 },
    { label: "Self Employed (can Verify)", value: 2 },
  ],
  0: [{ label: "Will Not Verify or Unemployed", value: 0 }],
};

export const newIncome = {
  100: [{ label: "Full Income", value: 100 }],
  70: [{ label: "Alternative Income", value: 70 }],
  60: [{ label: "Lite Income", value: 60 }],
  50: [{ label: "Stated Income", value: 50 }],
  0: [{ label: "No Income", value: 0 }],
};

export const newAsset = {
  100: [{ label: "Full Assets", value: 100 }],
  50: [{ label: "Stated Assets", value: 50 }],
  0: [{ label: "No Assets", value: 0 }],
};
