import React, { memo, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { DSDatePicker } from "@elliemae/ds-date-picker";
import { customOnDateChange, getValidator } from "../utils/common";
import { useLoanDataFieldSelector } from "../utils/customHooks";

const DatePickerForm = ({
  name,
  defaultValue,
  dateFormat,
  labelText,
  required,
  feedbackMessage,
  pathValue,
  screenReaderInputMessage,
  placeholder,
  firstDayOfWeek,
  ...rest
}) => {
  const fieldValue =
    defaultValue || useLoanDataFieldSelector(String(pathValue) || null).value;
  const dependencies = useLoanDataFieldSelector(rest.dependencies);
  const [localValue, setLocalValue] = useState(moment(fieldValue));
  const [validator, setValidator] = useState({
    hasError: false,
    errorMessage: "",
    required,
  });

  const customDependencies = useMemo(() => ({ ...dependencies }), [
    dependencies,
  ]);

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (newValidator?.errorMessage !== validator?.errorMessage) {
      setValidator(newValidator);
    }
  };

  useEffect(() => {
    handleValidator(fieldValue);
  }, [customDependencies]);

  const validateEvent = (event) => {
    const { replace } = rest;
    const customEvent = rest[event];
    const eventValues = { customEvent, replace, pathValue, customDependencies };
    return customOnDateChange({
      event,
      value: localValue,
      setValue: setLocalValue,
      dateFormat,
      ...eventValues,
    });
  };

  const handleOnDateChange = (newDate) => {
    setLocalValue(newDate);

    const { onChange } = rest;
    onChange({ value: newDate.toISOString(), pathValue });
  };

  const handleOnChange = (newDate) => {
    setLocalValue(newDate);
  };

  return (
    <DSFormLayoutBlockItem
      feedbackMessage={feedbackMessage || validator.feedbackMessage}
      validationMessage={validator.errorMessage}
      hasError={validator.hasError}
      inputID={`epps-field-id-${name}`}
      label={labelText}
    >
      <DSDatePicker
        value={localValue}
        required={required}
        placeholder={placeholder}
        firstDayOfWeek={firstDayOfWeek}
        screenReaderInputMessage={screenReaderInputMessage}
        onDateChange={(newDate) => handleOnDateChange(newDate)}
        onChange={(newDate) => handleOnChange(newDate)}
        data-testid="DatePickerForm-all-components"
        // custom props
        {...validateEvent("onBlur")}
        {...validateEvent("onKeyUp")}
      />
    </DSFormLayoutBlockItem>
  );
};

DatePickerForm.defaultProps = {
  name: "",
  defaultValue: null,
  dateFormat: "MM/DD/YYYY",
  labelText: "Select Date",
  required: false,
  dependencies: [],
  feedbackMessage: "MM/DD/YYYY",
  pathValue: "",
  screenReaderInputMessage: "Select date",
  placeholder: "MM/DD/YYYY",
  firstDayOfWeek: 0,
};

DatePickerForm.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  dateFormat: PropTypes.string,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  dependencies: PropTypes.instanceOf(Array),
  feedbackMessage: PropTypes.string,
  pathValue: PropTypes.string,
  screenReaderInputMessage: PropTypes.string,
  placeholder: PropTypes.string,
  firstDayOfWeek: PropTypes.number,
};

export default memo(DatePickerForm);
