import React, { useEffect, useState, useRef } from "react";
import { arrayOf, bool } from "prop-types";
import { useDispatch } from "react-redux";
import { DataTable } from "@elliemae/ds-data-table";
import { DSTabs, DSTab, TabTypes } from "@elliemae/ds-tabs";
import { selectProduct } from "data/lockSummary/actions";
import ProgramDetailsModal from "view/LockSummary/components/ProgramDetailsModal";
import {
  useLoanDataFieldSelector,
  useLockedProgramSelector,
  useLookup,
} from "data/useSelectors";
import { setLockedProgramData } from "data/origin/actions";
import { StyledDataTableWrapper } from "view/global/styles/styled";
import { getIneligibleGridColumns } from "./utils/constants";
import { useIneligibleProductsGridHooks } from "./utils/customHooks";
import IneligibleProductsToolbar from "../ClassicGrid/EligibleProductsToolbar";
import { FILTERS_INITIAL_STATE } from "../ClassicGrid/constants";

function IneligibleProductsGrid({ loanPrograms, loanLimits, refreshPrograms }) {
  const [expandedRows, setExpandedRows] = useState({});
  const [programSlide, setProgramSlide] = useState(false);
  const actionRef = useRef({});
  const [activeFilters, setActiveFilters] = useState(FILTERS_INITIAL_STATE);
  const clientsettings = useLookup("clientSettings", "");
  const loanTerms = useLoanDataFieldSelector("productType", []) || [];
  const lookupsLoanTerms = useLookup("loanTerms");
  let loanTermsTabs;
  if (Array.isArray(loanTerms) && lookupsLoanTerms) {
    loanTermsTabs = lookupsLoanTerms.filter((obj) =>
      loanTerms.includes(obj.value)
    );
    loanTermsTabs.unshift({ label: "All" });
  }
  const { focusIneligible, programID } = useLockedProgramSelector();
  const dispatch = useDispatch();

  const handleFilterChange = (filterName, value) => {
    setActiveFilters((prevState) => ({ ...prevState, [filterName]: value }));
  };

  const { newRows } = useIneligibleProductsGridHooks(
    loanPrograms,
    loanLimits,
    activeFilters
  );

  useEffect(() => {
    if (focusIneligible && newRows?.length !== 0) {
      if (actionRef?.current?.scrollToIndex) {
        const programIndex = newRows.findIndex(
          (elem) => elem?.programID === programID
        );
        actionRef.current.scrollToIndex(programIndex, { align: "center" });
        setExpandedRows((prevExpandedRows) => {
          const newExpanded = { ...prevExpandedRows };
          newExpanded[programIndex] = true;
          return newExpanded;
        });
        dispatch(setLockedProgramData({ focusIneligible: false }));
      }
    }
  }, [focusIneligible, actionRef, newRows]);

  if (clientsettings !== "") {
    return (
      <>
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <DSTabs type={TabTypes.SUBTABS}>
            {loanTermsTabs.map((tab, idx) => (
              <DSTab
                key={tab.label.concat(idx)}
                tabId={`tab${idx + 1}`}
                title={tab.label}
                onClick={() => {
                  handleFilterChange("term", tab);
                }}
              />
            ))}
          </DSTabs>
        </div>
        <div style={{ height: "calc(100% - 100px)" }}>
          <IneligibleProductsToolbar
            activeFilters={activeFilters}
            onFilterChange={handleFilterChange}
            refreshPrograms={refreshPrograms}
            showTarget={false}
            showFlagged={false}
          />
          <ProgramDetailsModal
            isSlide
            isOpen={programSlide}
            toggleModalSlide={() => setProgramSlide(false)}
          />
          <StyledDataTableWrapper>
            <DataTable
              uniqueRowAccessor="programID"
              actionRef={actionRef}
              height="100%"
              paginated
              isExpandable
              data={newRows}
              expandedRows={expandedRows}
              onRowExpand={setExpandedRows}
              columns={getIneligibleGridColumns()}
              cellRendererProps={{
                onClickProgramName: (rowData) => {
                  dispatch(selectProduct(rowData));
                  setProgramSlide(true);
                },
              }}
              colsLayoutStyle="auto"
              noResultsMessage="No Results Found"
              noResultsSecondaryMessage="You do not have any Programs at this time."
              rowKey="programID"
            />
          </StyledDataTableWrapper>
        </div>
      </>
    );
  }
  return null;
}

IneligibleProductsGrid.defaultProps = {
  loanPrograms: [],
  loanLimits: [],
  refreshPrograms: false,
};

IneligibleProductsGrid.propTypes = {
  loanPrograms: arrayOf(),
  loanLimits: arrayOf(),
  refreshPrograms: bool,
};

export default IneligibleProductsGrid;
