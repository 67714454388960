import React from "react";
import { Grid } from "@elliemae/ds-grid";
import { DSTruncatedTooltipText } from "@elliemae/ds-truncated-tooltip-text";
import { PopperPositions as Positions } from "@elliemae/ds-popper";
import { WarningTriangle } from "@elliemae/ds-icons";

export const renderAlingCustomColumn = (value, aling = "flex-end") => {
  return (
    <Grid justifyContent={aling} style={{ width: "100%", paddingRight: 3 }}>
      <DSTruncatedTooltipText
        containerProps={{ id: "ineligibilityGrid-column-id" }}
        tooltipPlacement={Positions.TOP}
        value={value}
      />
    </Grid>
  );
};

export const renderIneligibityCustomRow = (value, flag, aling = "flex-end") => {
  const props = {
    justifyContent: aling,
    style: { width: "100%" },
    py: "xxs",
  };
  return (
    <Grid cols={1}>
      <Grid span={1} {...props}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!flag && (
            <WarningTriangle
              size="s"
              color={["danger", 900]}
              style={{ marginRight: "5px" }}
            />
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <DSTruncatedTooltipText
              containerProps={{ id: "ineligibilityGrid-row-id" }}
              tooltipPlacement={Positions.TOP}
              value={value ?? ""}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const parsedColumnValue = (columnsValue) => {
  return Number((columnsValue * 100).toFixed());
};
