import styled from "styled-components";
import Session, { APPLICATION_NAME, IS_LO } from "services/Session";

const getApplication = () => {
  const applicationName = Session.get(IS_LO)
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  return applicationName;
};

export const StyledEppsContainerHeader = styled.div`
  top: 0;
  z-index: 2;
  position: sticky;
  background-color: white;
  flex: 0 0 auto;
`;

export const StyledEppsParentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: #ffff;
  ${() =>
    getApplication() !== "ENC" &&
    `
    min-width: 970px;
    padding: 0 16px;
  `};
`;

export const StyledEppsParentView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
  ${({ overflow }) => `overflow: ${overflow}`}
`;

export const StyledEppsContainerBody = styled.div`
  flex: 1 1 auto;
  position: relative;
  height: 100%;
  overflow-y: ${() => (getApplication() === "ENC" ? "auto" : "inherit")};
`;

export const StyledEppsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ paddingBottom }) =>
    getApplication() === "ENC"
      ? "min-width: 970px; padding: 0 16px;"
      : `padding-bottom: ${paddingBottom || 50}px;`}
  [data-testid="ds-tabs-container"] {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    > div:first-child {
      overflow: hidden;
      > div:first-child {
        > div:first-child {
          overflow: hidden;
        }
      }
    }
    [data-testid="ds-tabs-tab-panel"] {
      height: 100%;
      overflow: auto;
      > div:first-child {
        > div:first-child {
          overflow: hidden;
        }
      }
    }
  }
`;

export const StyledDataTableWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  [data-testid="data-table-wrapper"] {
    height: 100%;
  }
  [data-testid="data-table-scrollable-container"] {
    height: 100%;
  }
  [data-testid="data-table-cell"] {
    div {
      display: flex;
      ${({ isAllRatesGrid }) =>
        isAllRatesGrid ? "justify-content: flex-end;" : ""}
    }
  }
`;

/* TODO: Remove override of dimsum styles and find alternate solution for spacing between form items */
export const StyledEppsFormWrapper = styled.div`
  .em-ds-form-item {
    margin-bottom: 0;
  }
`;
