import React, { Fragment, useLayoutEffect } from "react";
import { number, shape, func, bool } from "prop-types";
import { DSSeparator } from "@elliemae/ds-separator";
import { GridContainer, GridItem } from "@elliemae/ds-grid";
import { DSButton, BUTTON_VARIANT } from "@elliemae/ds-button";
import { Delete } from "@elliemae/ds-icons";
import { PopperPositions as Positions } from "@elliemae/ds-popper";
import { ComboBoxForm, InputMaskForm } from "components/Form";
import { setLoanDataValue } from "store/searchForm/actions";
import { useDispatch } from "react-redux";
import { renderConditionalTooltip, focusField } from "utils/shared";
import { pathValues, tooltipText } from "./utils/constants";

const opts = {
  flooredNumber: true,
  integerLimit: 9,
  allowNegative: false,
};

const OtherRealState = ({
  index,
  estate,
  isLastIndex,
  removeRealEstate,
  originalRealEstateLength,
  disabledFields,
}) => {
  const dispatch = useDispatch();

  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ["", undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue));
  };

  const getDisabled = (fieldName) => {
    return disabledFields.includes(fieldName);
  };

  useLayoutEffect(() => {
    if (index >= originalRealEstateLength) {
      focusField(`otherRealEstateOwned-${estate.id}-use`);
    }
  }, []);

  const getDisabledButton = () => {
    return index < originalRealEstateLength;
  };

  return (
    <Fragment>
      <GridContainer cols={["auto", "50px"]} gutter="16px">
        <GridContainer cols={5} gutter="16px">
          <GridItem span={1}>
            <ComboBoxForm
              isNonClearable
              name={`propertyUse-${index}`}
              id={`otherRealEstateOwned-${estate.id}-use-focus`}
              pathValue={`${pathValues.OTHER_REAL_ESTATE_OWNED}[${index}].use`}
              onChangeV2={handleSetLoanDataValue}
              placeholder="Select Occupancy Type"
              label="Occupancy Type"
              required
            />
          </GridItem>
          <GridItem span={1}>
            {renderConditionalTooltip(
              {
                interactionType: "hover",
                placement: Positions.BOTTOM,
                title: tooltipText.editWithinOriginationSystem,
              },
              getDisabled("value"),
              <div>
                <InputMaskForm
                  clearable={!getDisabled("value")}
                  id={`otherRealEstateOwned-${estate.id}-value-focus`}
                  label="Market Value"
                  maskOpts={opts}
                  name={`value-${index}`}
                  onKeyUp={handleSetLoanDataValue}
                  pathValue={`${pathValues.OTHER_REAL_ESTATE_OWNED}[${index}].value`}
                  placeholder="$0"
                  readOnly={getDisabled("value")}
                  required
                />
              </div>
            )}
          </GridItem>
          <GridItem span={1}>
            {renderConditionalTooltip(
              {
                interactionType: "hover",
                placement: Positions.BOTTOM,
                title: tooltipText.editWithinOriginationSystem,
              },
              getDisabled("mtgBalance"),
              <div>
                <InputMaskForm
                  clearable={!getDisabled("mtgBalance")}
                  id={`otherRealEstateOwned-${estate.id}-mtgBalance-focus`}
                  label="Mortgage Balance"
                  maskOpts={opts}
                  name={`mtgBalance-${index}`}
                  onKeyUp={handleSetLoanDataValue}
                  pathValue={`${pathValues.OTHER_REAL_ESTATE_OWNED}[${index}].mtgBalance`}
                  placeholder="$0"
                  readOnly={getDisabled("mtgBalance")}
                  required
                />
              </div>
            )}
          </GridItem>
          <GridItem span={1}>
            {renderConditionalTooltip(
              {
                interactionType: "hover",
                placement: Positions.BOTTOM,
                title: tooltipText.editWithinOriginationSystem,
              },
              getDisabled("monthlyPayment"),
              <div>
                <InputMaskForm
                  clearable={!getDisabled("monthlyPayment")}
                  id={`otherRealEstateOwned-${estate.id}-monthlyPayment-focus`}
                  label="Mortgage Payment"
                  onKeyUp={handleSetLoanDataValue}
                  maskOpts={opts}
                  name={`monthlyPayment-${index}`}
                  pathValue={`${pathValues.OTHER_REAL_ESTATE_OWNED}[${index}].monthlyPayment`}
                  placeholder="$0"
                  readOnly={getDisabled("monthlyPayment")}
                  required
                />
              </div>
            )}
          </GridItem>
          <GridItem span={1}>
            {renderConditionalTooltip(
              {
                interactionType: "hover",
                placement: Positions.BOTTOM,
                title: tooltipText.editWithinOriginationSystem,
              },
              getDisabled("rentalIncome"),
              <div>
                <InputMaskForm
                  clearable={!getDisabled("rentalIncome")}
                  id={`otherRealEstateOwned-${estate.id}-rentalIncome-focus`}
                  pathValue={`${pathValues.OTHER_REAL_ESTATE_OWNED}[${index}].rentalIncome`}
                  maskOpts={opts}
                  name={`rentalIncome-${estate.id}`}
                  placeholder="$0"
                  readOnly={getDisabled("rentalIncome")}
                  label="Rental Income"
                  onKeyUp={handleSetLoanDataValue}
                />
              </div>
            )}
          </GridItem>
        </GridContainer>
        <GridItem alignItems="center">
          <DSButton
            aria-label="Delete Real Estate"
            buttonType="text"
            id="deleteRealEstate"
            leftIcon={<Delete />}
            size="l"
            variant={BUTTON_VARIANT.DEFAULT}
            onClick={() => removeRealEstate(index)}
            style={{ alignItems: "center" }}
            disabled={getDisabledButton()}
          />
        </GridItem>
      </GridContainer>
      <DSSeparator
        dashed
        margin="s"
        orientation="horizontal"
        position="initial"
        style={{
          visibility: isLastIndex && "hidden",
          marginBottom: isLastIndex && "50px",
        }}
      />
    </Fragment>
  );
};

OtherRealState.defaultProps = {
  index: 0,
  originalRealEstateLength: 0,
  estate: {},
  disabledFields: {},
  isLastIndex: false,
};

OtherRealState.propTypes = {
  index: number,
  originalRealEstateLength: number,
  estate: shape({}),
  isLastIndex: bool,
  removeRealEstate: func.isRequired,
  disabledFields: shape({}),
};

export default OtherRealState;
