import React, { memo, useState, Fragment } from "react";
import { func, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import { DSButton } from "@elliemae/ds-button";
import { ModalHeader, ModalFooter } from "@elliemae/ds-modal-slide";
import ModalSlide from "components/ModalSlide";
import { updateBuySideAdjustmentsAction } from "data/origin/actionCreators";
import { setValidateSubmitButtonEnabledStatus } from "data/lockConfirm/actions";
import Session, { IS_VALIDATE } from "services/Session";
import EmptyCard from "./EmptyCard";
import { CARDS_LIMIT, CARDS_TITLES, CARDS_TEMPLATES } from "./constants";
import {
  checkCardsType,
  normalizeCardsData,
  normalizeAdjustmentsData,
  validateRequiredInputs,
} from "./helpers";
import { loanBuySideAdjustmentsSelector } from "../selectors";
import ConcessionCard from "./ConcessionCard";
import AdjustmentCard from "./AdjustmentCard";

export const CARDS_COMPONENTS = {
  adjustments: AdjustmentCard,
  concessions: ConcessionCard,
};

const SlidePriceCards = ({ setOpen, open: type }) => {
  const dispatch = useDispatch();
  const cardSettings = useSelector(
    ({ epps: { lockSummary } }) => lockSummary.adjustmentsSettings
  );

  const cardsType = checkCardsType(type);
  // TODO:talk with backend about matching encompassSettings properties with adjustments types
  const cardSetting =
    cardsType === "concessions"
      ? cardSettings.priceConcessions
      : cardSettings[`${type[0].toLowerCase()}${type.slice(1)}`];

  const emptyTemplate = {
    ...CARDS_TEMPLATES[cardsType],
    adjustmentType: type,
    persist: true,
    persistDisabled: !cardSetting,
  };

  const variations = useSelector(loanBuySideAdjustmentsSelector(type));
  const initialState =
    variations.length > 0
      ? normalizeAdjustmentsData(variations)
      : [emptyTemplate];
  const [rowCards, setRowCards] = useState(initialState);
  const [validationErrors, setValidationErrors] = useState([]);

  const reachedLimit = rowCards?.length === CARDS_LIMIT[cardsType];

  const handleAddCard = () => {
    setRowCards((prevCards) => [...prevCards, { ...emptyTemplate }]);
  };

  const closeModal = () => {
    setOpen("");
  };

  const handleDeleteRow = (index) => {
    const newRows = cloneDeep(rowCards);
    newRows.splice(index, 1);
    setRowCards(newRows);
    const errors = validationErrors.filter((el, idx) => idx !== index);
    setValidationErrors(errors);
  };

  const handleChange = (value, field, index) => {
    const newRows = cloneDeep(rowCards);
    newRows[index][field] = value;
    setRowCards(newRows);
    const { errors } = validateRequiredInputs(rowCards, cardsType);
    if (errors) {
      setValidationErrors(errors);
    } else {
      setValidationErrors([]);
    }
  };

  const handleConfirm = () => {
    let newRowCards;
    const isValidate = Session.get(IS_VALIDATE);
    const hasChanges = rowCards.length !== 0;
    let dispatchBuySideAdjFlag = true;
    if (hasChanges) {
      const { hasError, errors } = validateRequiredInputs(rowCards, cardsType);
      if (hasError) {
        dispatchBuySideAdjFlag = false;
        setValidationErrors(errors);
      } else newRowCards = normalizeCardsData(rowCards);
    } else newRowCards = rowCards;

    if (dispatchBuySideAdjFlag) {
      dispatch(updateBuySideAdjustmentsAction(newRowCards, type));
      if (isValidate) dispatch(setValidateSubmitButtonEnabledStatus(true));
      closeModal();
    }
  };

  const CardComponent = CARDS_COMPONENTS[cardsType];

  return (
    <ModalSlide
      header={<ModalHeader title={CARDS_TITLES[type]} onClose={closeModal} />}
      footer={
        <ModalFooter
          onConfirm={handleConfirm}
          onReject={closeModal}
          confirmLabel="Apply"
        />
      }
      data-testid="SlidePriceCards-all-components"
      isOpen
    >
      {rowCards?.length === 0 ? (
        <EmptyCard handleAddCard={handleAddCard} />
      ) : (
        <Fragment>
          {rowCards?.map((row, index) => (
            <CardComponent
              errors={validationErrors[index]}
              rowCard={row}
              index={index}
              title={CARDS_TITLES[type]}
              onDeleteRow={handleDeleteRow}
              onChange={handleChange}
            />
          ))}
          <div style={{ margin: "16px" }}>
            <DSButton
              buttonType="text"
              labelText="Add Another"
              disabled={reachedLimit}
              onClick={handleAddCard}
            />
          </div>
        </Fragment>
      )}
    </ModalSlide>
  );
};

SlidePriceCards.defaultProps = {
  open: "",
};

SlidePriceCards.propTypes = {
  setOpen: func.isRequired,
  open: string,
};

export default memo(SlidePriceCards);
