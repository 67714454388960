/* eslint-disable no-console */
import { createLogger } from "redux-logger";

const actionTransformer = (action) => {
  const transformedAction = { ...action };
  if (action.type === "BATCHING_REDUCER.BATCH") {
    transformedAction.payload.type = action.payload
      .map((next) => next.type)
      .join(" => ");
    return transformedAction.payload;
  }

  return transformedAction;
};

const level = "info";
const logger = {};

Object.keys(console).forEach((method) => {
  if (typeof console[method] === "function") {
    logger[method] = console[method].bind(console);
  }
});

logger[level] = function levelFn(...args) {
  const lastArg = args.pop();

  if (Array.isArray(lastArg)) {
    lastArg.forEach((item) => {
      console[level](...args, item);
    });
  }

  // eslint-disable-next-line prefer-rest-params
  console[level](...arguments);
};

export default createLogger({
  level,
  actionTransformer,
  logger,
  collapsed: true,
});
