import React, { useEffect, useState, memo, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bool, instanceOf, number, string } from "prop-types";
import { DSInputMask, DSFormItemLayout, MASK_TYPES } from "@elliemae/ds-form";
import { searchFormActions } from "store/searchForm";
import {
  useLookupOptions,
  useLoanDataFieldSelector,
  useSearchFormSelector,
} from "../utils/customHooks";
import {
  getLabel,
  isFunction,
  getReplace,
  getValidator,
  getMaxLength,
  customOnChange,
  getContainerProps,
} from "../utils/common";

function InputMaskForm({
  name,
  label,
  required,
  maskOpts,
  pathValue,
  maxLength,
  ...rest
}) {
  const dispatch = useDispatch();
  const getFieldValue = useLoanDataFieldSelector(pathValue);
  const dependencies = useLoanDataFieldSelector(rest.dependencies);
  const extraDependencies = useSearchFormSelector(rest.extraDependencies);

  // TODO : Need to cleanup dependencies,extraDependencies and clientSettings data flow for validation/custom validation.
  const clientSettings = useLookupOptions("clientSettings");

  const [fieldValue, setFieldValue] = useState();
  const [validator, setValidator] = useState({
    hasError: false,
    errorMessage: "",
    required,
  });
  const [error, setError] = useState(false);

  const customDependencies = useMemo(
    () => ({
      ...dependencies,
      ...extraDependencies,
      ...(clientSettings &&
        rest?.clientSettingsValidationEnabled && {
          clientsettings: { ...clientSettings[0] },
        }),
    }),
    [dependencies, extraDependencies, clientSettings]
  );

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (
      newValidator?.errorMessage !== validator?.errorMessage ||
      newValidator?.required !== validator?.required
    ) {
      setValidator(newValidator);
    }
  };

  useEffect(() => {
    const { customValue, customParser } = rest;
    const getInitialValue = isFunction(customParser)
      ? customParser(getFieldValue.value)
      : getFieldValue.value;
    const getValue = isFunction(customValue)
      ? customValue({ value: getInitialValue, customDependencies, pathValue })
      : getInitialValue;
    handleValidator(getValue);
    if (maskOpts?.flooredNumber === true) {
      setFieldValue(Math.round(getValue));
    } else {
      setFieldValue(getValue);
    }
  }, [getFieldValue, customDependencies]);

  useEffect(() => {
    const { hasError } = validator;
    if (hasError !== undefined && error !== hasError) {
      setError((prev) => !prev);
      dispatch(searchFormActions.setFormHasError({ name, hasError }));
    }
  }, [validator]);

  useEffect(() => {
    return () => {
      if (validator.hasError)
        dispatch(searchFormActions.setFormHasError({ name, hasError: false }));
    };
  }, [error]);

  const containerProps = useMemo(() => {
    return {
      ...getContainerProps(name, validator.hasError),
      ...rest.containerProps,
    };
  }, [validator]);

  const validateEvent = (event) => {
    const { replace } = rest;
    const customEvent = rest[event];
    const eventValues = { customEvent, replace, pathValue, customDependencies };
    return customOnChange({ event, ...eventValues });
  };

  const handleLabel = () => {
    return getLabel(label, customDependencies);
  };

  const handleFeedbackMessage = () => {
    const { feedbackMessage = "" } = rest;
    return (
      <p>
        {isFunction(feedbackMessage)
          ? feedbackMessage({ initialValue: fieldValue, customDependencies })
          : feedbackMessage}
      </p>
    );
  };

  const handleReadOnly = () => {
    const { readOnly } = rest;
    return isFunction(readOnly)
      ? readOnly({ initialValue: fieldValue, customDependencies })
      : readOnly;
  };

  const handleOnKeyUp = (val) => {
    const { replace, onKeyUp, customParser } = rest;
    const replaceValue = getReplace(val, replace);
    const value = isFunction(customParser)
      ? customParser(replaceValue)
      : replaceValue;
    setFieldValue(val);
    handleValidator(value);
    if (onKeyUp) {
      onKeyUp({ value, pathValue, customDependencies });
    }
  };

  const handleOnClear = (value) => {
    const { onClearField } = rest;
    setFieldValue(value);
    handleValidator(value);
    if (onClearField) onClearField({ value, pathValue, customDependencies });
  };

  return (
    <DSFormItemLayout
      {...rest}
      name={name}
      labelText={handleLabel()}
      value={String(fieldValue)}
      readOnly={handleReadOnly()}
      required={validator.required}
      hasError={validator.hasError}
      validationMessage={validator.errorMessage}
      maxLength={getMaxLength(fieldValue, maxLength)}
      clearable={handleReadOnly() ? false : rest.clearable}
      mask={(val) => MASK_TYPES[rest.maskType || "NUMBER"](maskOpts)(val)}
      inputComponent={<DSInputMask onClear={() => handleOnClear(null)} />}
      onKeyUp={({ target }) => handleOnKeyUp(target.value)}
      feedbackMessage={handleFeedbackMessage()}
      containerProps={containerProps}
      data-testid="InputMaskForm-all-components"
      // custom handlers
      {...validateEvent("onBlur")}
      {...validateEvent("onClick")}
      {...validateEvent("onFocus")}
      {...validateEvent("onChange")}
    />
  );
}

InputMaskForm.defaultProps = {
  name: "",
  label: "",
  maskOpts: {},
  pathValue: "",
  maxLength: 50,
  required: false,
  dependencies: [],
  extraDependencies: [],
};

InputMaskForm.propTypes = {
  name: string,
  label: string,
  maskOpts: instanceOf(Object),
  pathValue: string,
  maxLength: number,
  required: bool,
  dependencies: instanceOf(Array),
  extraDependencies: instanceOf(Array),
};

export default memo(InputMaskForm);
