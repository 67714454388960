import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  useLoanInformationField,
  useUserData,
  useLoanDataFieldSelector,
} from "data/useSelectors";
import Session, { IS_BUYSIDE, APPLICATION_NAME, IS_LO } from "services/Session";
import SmartClientHeader from "./SmartClientHeader";
import PricingNotificationHeader from "./PricingNotificationHeader";
import OnrpLimitNotificationHeader from "./HeaderMenu/LockDeskStatus/components/OnrpLimitNotificationHeader";
import HeaderMenu from "./HeaderMenu";
import WarningProgramStatus from "./WarningProgramStatus";

function Header() {
  const { loanId } = useSelector(({ EPPS }) => EPPS.loanPrograms || {});
  const { firstName = "", lastName = "", office = "" } = useUserData();
  const loanNumber = useLoanInformationField("loanNumber", "");
  const loanIdLoanData = useLoanInformationField("loanId");
  const loanChannel = useLoanDataFieldSelector("loanInformation.loanChannel");
  const displayedLoanId = loanId || loanIdLoanData;
  const username = `${firstName} ${lastName}`;
  const isBuyside = Session.get(IS_BUYSIDE);
  const isValidChannel = [0, 1].includes(loanChannel);
  const isVisibleLockDesk = isValidChannel && !isBuyside;

  const applicationName = Session.get(IS_LO)
    ? Session.get(APPLICATION_NAME) || "ENCW"
    : "EPPS";

  return (
    <Fragment>
      {applicationName === "ENC" ? (
        <SmartClientHeader
          loanId={displayedLoanId}
          username={username}
          office={office}
          loanNumber={loanNumber}
        />
      ) : (
        <HeaderMenu
          loanId={displayedLoanId}
          applicationName={applicationName}
        />
      )}
      <PricingNotificationHeader />
      {isVisibleLockDesk && <OnrpLimitNotificationHeader />}
      {isBuyside && <WarningProgramStatus />}
    </Fragment>
  );
}

export default Header;
