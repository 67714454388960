export const MODAL_TITLES = {
  ineligible: "Locked product is no longer eligible",
  unavailable: "Locked product is no longer available",
  ineligibleRate: "Rate for locked product is no longer available",
};

export const MODAL_BODIES = {
  unavailable: " is no longer available",
  ineligibleRate:
    ": The interest rate that you selected for this program is no longer available.",
};
