import React from "react";
import { InputMaskForm } from "components/Form";
import * as masks from "../../../../utils/constants";
import * as dependencies from "../../utils/dependencies";

const AverageRepresentativeCreditScoreField = () => {
  return (
    <InputMaskForm
      maxLength={3}
      maskOpts={masks.NO_DECIMAL_NO_PREFIX}
      name="averageRepresentativeCreditScore"
      label="Avg. Repr Credit Score"
      pathValue="loanInformation.averageRepresentativeCreditScore"
      dependencies={dependencies.AVG_REP_CREDIT_SCORE}
      readOnly
    />
  );
};

export default AverageRepresentativeCreditScoreField;
