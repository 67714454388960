import styled from "styled-components";

export const StyledTitle = styled.div`
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.title[500]};
  margin-left: ${({ theme, marginLeft }) => (marginLeft ? theme.space.xxs : 0)};
`;

export const StyledDebugInfo = styled.div`
  margin: 0px ${({ theme }) => theme.space.xs};
  font-weight: ${({ theme }) => theme.fontWeights[100]};
  padding-top: ${({ theme }) => theme.space.xxs};
`;

export const StyledSlash = styled.div`
  border-left: 1px solid grey;
  height: ${({ theme }) => theme.fontSizes.title[600]};
  margin-top: 7px;
  ${({ margin, theme }) => margin && `margin: 0 ${theme.space.xs}`};
`;

export const StyledNameView = styled.div`
  text-transform: capitalize;
  margin-right: ${({ theme }) => theme.space.xxs};
  margin-left: ${({ marginLeft, theme }) =>
    marginLeft ? theme.space.xs : "none"};
`;
