import { isEmpty } from "lodash";

const isNumber = (value) => !Number.isNaN(parseFloat(value));
const isFunc = (value) => typeof value === "function";

const validate = (data, validators, dependencies) => {
  const validation = {
    hasError: false,
    required: false,
    errorMessage: "",
  };

  const setError = (errorMsg) => {
    validation.hasError = true;
    validation.errorMessage = errorMsg;
  };

  validators.forEach((validator) => {
    const { fieldName, message, sanitizer } = validator;
    const { allowedFalseyValues = [] } = validator;

    if (sanitizer && !isFunc(sanitizer)) {
      throw new Error("VALIDATOR: sanitizer should be a function");
    }

    const unsafeValue =
      Array.isArray(data[fieldName]) && !data[fieldName].length
        ? null
        : data[fieldName];
    const sanitizedValue = sanitizer ? sanitizer(unsafeValue) : unsafeValue;
    let pathValue = null;
    if (!Number.isNaN(sanitizedValue)) {
      pathValue = sanitizedValue;
    }

    // REQUIRED FIELD VALIDATION
    const required = isFunc(validator.required)
      ? validator.required({ ...dependencies })
      : validator.required;
    validation.required = required;

    if (required && !pathValue && !allowedFalseyValues.includes(pathValue)) {
      const msg = isFunc(message) ? message(data, pathValue) : message;
      setError(msg);
    }

    // EQUALITY VALIDATION
    if (validator.equal) {
      const { value, message: msg } = validator.equal;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'equal' property without a 'value' field."
        );
      }
      if (pathValue === value) {
        const equalMsg = isFunc(msg) ? msg(data, pathValue) : msg;
        setError(equalMsg);
      }
    }

    // NUMBER MIN VALIDATION
    if (validator.min) {
      const { value, message: msg } = validator.min;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'min' property without a 'value' field."
        );
      }
      if (+pathValue < value || [undefined, null, ""].includes(pathValue)) {
        const validationProps = { value: pathValue, dependencies, message };
        const minMsg = isFunc(msg) ? msg(validationProps) : msg;
        setError(minMsg);
      }
    }

    // NUMBER MAX VALIDATION
    if (isNumber(pathValue) && validator.max) {
      const { value, message: msg } = validator.max;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'max' property without a 'value' field."
        );
      }
      if (+pathValue > value || [undefined, null, ""].includes(pathValue)) {
        const maxMsg = isFunc(msg) ? msg(data, pathValue) : msg;
        setError(maxMsg);
      }
    }

    // NUMBER IN RANGE VALIDATION
    if (
      pathValue !== null &&
      pathValue !== undefined &&
      validator.minmax &&
      !allowedFalseyValues.includes(pathValue)
    ) {
      let parsedPathValue = Number(pathValue);
      if (pathValue === null || Number.isNaN(parsedPathValue)) {
        parsedPathValue = 0;
      }

      const { value, message: msg, rangeInclusive = false } = validator.minmax;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'minmax' property without a 'value' field."
        );
      } else if (!Array.isArray(value) || value.length !== 2) {
        throw new Error(
          "VALIDATOR: 'minmax' property value must be an array with two numbers."
        );
      }

      if (rangeInclusive) {
        if (!(parsedPathValue >= value[0] && parsedPathValue <= value[1])) {
          const maxMsg = isFunc(msg) ? msg(data, parsedPathValue) : msg;
          setError(maxMsg);
        }
      } else if (
        !(parsedPathValue >= value[0] && parsedPathValue <= value[1])
      ) {
        const maxMsg = isFunc(msg) ? msg(data, parsedPathValue) : msg;
        setError(maxMsg);
      }
    }

    // STRING REGEX MATCH VALIDATION
    if (typeof pathValue === "string" && validator.match) {
      const { regex, message: msg } = validator.match;
      if (regex === undefined) {
        throw new Error(
          "VALIDATOR: found 'match' property without a 'regex' field."
        );
      }
      if (!pathValue.match(regex)) {
        const matchMsg = isFunc(msg) ? msg(data, pathValue) : msg;
        setError(matchMsg);
      }
    }

    // STRING MINLENGTH VALIDATION
    if (typeof pathValue === "string" && validator.maxLength) {
      const { value, message: msg } = validator.maxLength;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'maxLength' property without a 'value' field."
        );
      }
      if (pathValue.length > value) {
        const maxLnMsg = isFunc(msg) ? msg(data, pathValue) : msg;
        setError(maxLnMsg);
      }
    }

    // STRING MAXLENGTH VALIDATION
    if (typeof pathValue === "string" && validator.minLength) {
      const { value, message: msg } = validator.minLength;
      if (value === undefined) {
        throw new Error(
          "VALIDATOR: found 'minLength' property without a 'value' field."
        );
      }
      if (pathValue.length > value) {
        const minLnMsg = isFunc(msg) ? msg(data, pathValue) : msg;
        setError(minLnMsg);
      }
    }

    // CUSTOM VALIDATOR
    if (validator.customValidator) {
      const { customValidator } = validator;
      if (!isFunc(customValidator)) {
        throw new Error("VALIDATOR: 'customValidator' should be a 'function'.");
      }
      const result = customValidator(data, dependencies);

      const { hasError, message: msg, messages = {} } = result;
      if (hasError && ![undefined, null].includes(msg)) {
        setError(msg);
      }
      if (hasError && !isEmpty(messages)) {
        setError(messages);
      }
    }

    return validation;
  });

  return validation;
};

export default validate;

/*
VALIDATOR SCHEMA
{
  fieldName: String,
  path: String // path to the field in the dataObject. can be a root path or a nested path.,
  required: Boolean | function => Boolean,
  message: String | function => returns String // message to show if the required field is not specified,
  min: {value: Number, message:  String | function => String },
  max:{value: Number, message:  String | function => String },
  minmax: {value: Array, message: String | function => String}
  minLength: {value: Number, message:  String | function => String },
  maxLength: {value: Number, message:  String | function => String },
  match: {regex: RegExp , message: String | function => returns String },
  allowedFalseyValues: Array of allowed Falsey Values. Ex: [null, "", 0], default to [],
  customValidator: function => Object of type {hasError: Boolean, message: String},
  sanitizer: function => any // a custum function to format the value before running the validations
 }
 */
