import styled from "styled-components";

export const StyledWrapperDataGrid = styled.div`
  .em-ds-data-grid .em-ds-data-grid-body {
    overflow: hidden;
  }
`;

// TODO all these properties can be applied using Grid component
export const StyledEligibleContainer = styled.div`
  padding-top: 8px;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
`;

export const StyledEligibleProgramLinks = styled.p`
  color: #3989ca;
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledStandardViewContainer = styled.div`
  overflow: hidden;
  padding-bottom: 20px;

  .em-ds-accordion {
    .em-ds-accordion-item__expand-zone-space {
      padding-bottom: 16px;
    }
    .em-ds-data-grid__controls-pagination {
      padding-top: 16px;
    }
  }
`;

export const StyledEligibleRowSelected = styled.div`
  ${({ gridRow }) => (gridRow ? "height: 32px;" : "height: 50px;")};
  max-height: 50px;
  width: calc(100% + 1.23076rem);
  margin-left: -0.61538rem;
  margin-right: -0.61538rem;
  padding-left: 0.61538rem;
  padding-right: 0.61538rem;
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  span:last-of-type {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  ${({ targetRow }) => (targetRow ? "background: #EBF6FF;" : "")};
`;

export const StyledPriceCellRenderer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledProductNotesList = styled.div`
  ul {
    padding-right: 20px;
  }
`;
