import AppError from "utils/errors/appError";

const parseValidResponse = async (response, ignoreErrorStatuses = []) => {
  const responseCode = response.status;
  if (response.ok || ignoreErrorStatuses.indexOf(responseCode) >= 0) {
    const contentType = response.headers.get("content-type");
    let responseBody;
    if (contentType && contentType.includes("application/json")) {
      responseBody = await response.json();
    } else {
      responseBody = await response.text();
    }
    return responseBody;
  }
  throw new AppError();
};
/**
 * This will handle api response and return json object if success
 * if anything not between response status 200 - 299 (see response.ok) it will throw AppError exception
 *
 * @param {number[]} filters statuses to ignore
 * @returns {Function} response handler
 */
export const apiResponseHandler = (...filters) => async (response) =>
  parseValidResponse(response, filters);
