import React, { Fragment, useEffect, useMemo, useRef } from "react";
import { elementType } from "prop-types";
import { Grid } from "@elliemae/ds-grid";
import { DSSeparatorV2 } from "@elliemae/ds-separator";
import {
  useRateSelectorLien,
  useLoanDataFieldSelector,
} from "data/useSelectors";

import PricingGrid from "components/PricingGrid";
import { transformRateSelectorDataToSlide } from "view/SearchResult/components/GridPriceDetails/helpers";
import { DSDataGrid } from "@elliemae/ds-datagrids";
import HedgeLoanCheckbox from "./components/HedgeLoanCheckbox";
import Summary from "../Summary";
import {
  StyledPricingGridHeader,
  StyledBuySidePrice,
  StyledTileGrids,
} from "./styled";
import { getSummaryData, marginDetailsColumns } from "./helpers";

const BuysidePriceSection = ({ pricingDropdownSection }) => {
  const rateDate = useRateSelectorLien("rateDate", null);
  const { effectiveDate = "" } = useLoanDataFieldSelector("relockRequest", {});
  const isArm = useRateSelectorLien("arm", "");
  const programTypeName = useRateSelectorLien("programTypeName", []);
  const isHeloc = programTypeName.includes("HELOC");
  const adjustments = useRateSelectorLien("adjustments", []);
  const armIndexValue = useRateSelectorLien("armIndexValue", null);
  const dataMarginDetailsSlide = useRef(
    transformRateSelectorDataToSlide(adjustments, armIndexValue)
  );

  useEffect(() => {
    dataMarginDetailsSlide.current = transformRateSelectorDataToSlide(
      adjustments,
      armIndexValue
    );
  }, [armIndexValue, adjustments]);

  const summaryDetails = useMemo(
    () => getSummaryData({ rateDate, effectiveDate }),
    [rateDate, effectiveDate]
  );

  return (
    <Fragment>
      <Grid cols={[1, 1]} gutter="s">
        <Grid>
          <StyledPricingGridHeader>
            <div data-testid="BuysidePriceSection-all-components">
              <Summary summaryDetails={summaryDetails} />
            </div>
            <StyledBuySidePrice>
              <Grid
                cols={["repeat(3,auto)"]}
                wrap="nowrap"
                gutter="16px"
                alignItems="center"
              >
                <HedgeLoanCheckbox />
                <Grid height="60%">
                  <DSSeparatorV2 isVertical colorType="light" />
                </Grid>
                {pricingDropdownSection}
              </Grid>
            </StyledBuySidePrice>
          </StyledPricingGridHeader>
          <PricingGrid />
        </Grid>
        {(isArm || isHeloc) && (
          <Grid height="245px">
            <StyledTileGrids>
              <span>Margin Details</span>
            </StyledTileGrids>
            <DSDataGrid
              columns={marginDetailsColumns}
              rows={dataMarginDetailsSlide.current.adjustmentRows}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

BuysidePriceSection.propTypes = {
  pricingDropdownSection: elementType.isRequired,
};

export default BuysidePriceSection;
