import React, { useEffect, useState, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { DSFormItemLayout } from "@elliemae/ds-form";
import { DSZipCodeSearch } from "@elliemae/ds-zipcode-search";
import { useDispatch } from "react-redux";
import { setLoanDataValue } from "store/searchForm/actions";
import { searchFormActions } from "store/searchForm";
import {
  useLoanDataFieldSelector,
  useSearchFormSelector,
} from "../utils/customHooks";
import {
  getLabel,
  isFunction,
  getValidator,
  getContainerProps,
} from "../utils/common";

function ZipCodeSearchForm({
  name,
  label,
  required,
  maskOpts,
  pathValue,
  maxLength,
  ...rest
}) {
  const getFieldValue = useLoanDataFieldSelector(pathValue);
  const dependencies = useLoanDataFieldSelector(rest.dependencies);
  const extraDependencies = useSearchFormSelector(rest.extraDependencies);
  const [fieldValue, setFieldValue] = useState();
  const [validator, setValidator] = useState({
    hasError: false,
    errorMessage: "",
    required,
  });
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const customDependencies = useMemo(
    () => ({ ...dependencies, ...extraDependencies, options: rest.options }),
    [dependencies, extraDependencies, rest.options]
  );

  const handleValidator = (value) => {
    const validatorValues = { value, required, name, customDependencies };
    const newValidator = getValidator({ ...validatorValues });
    if (newValidator?.errorMessage !== validator?.errorMessage) {
      setValidator(newValidator);
    }
  };

  const getValue = () => {
    const { customValue, customParser } = rest;
    const getInitialValue = isFunction(customParser)
      ? customParser(getFieldValue.value)
      : getFieldValue.value;
    const currentValue = isFunction(customValue)
      ? customValue({ value: getInitialValue, customDependencies, pathValue })
      : getInitialValue;
    return currentValue;
  };

  const handleSetLoanDataValue = ({ value, pathVal }) => {
    const parseValue = ["", undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathVal));
  };

  useEffect(() => {
    const currentValue = getValue();
    setFieldValue(currentValue);
    handleValidator(currentValue);
  }, [rest.options]);

  useEffect(() => {
    if (validator.hasError !== undefined) {
      if (error !== validator.hasError) {
        const { hasError } = validator;
        setError((prev) => !prev);
        dispatch(searchFormActions.setFormHasError({ name, hasError }));
      }
    }
  }, [validator]);

  const containerProps = useMemo(() => {
    return {
      ...getContainerProps(name, validator.hasError),
      ...rest.containerProps,
    };
  }, [validator]);

  const handleLabel = () => {
    return getLabel(label, customDependencies);
  };

  const handleFeedbackMessage = () => {
    const { feedbackMessage } = rest;
    return isFunction(feedbackMessage)
      ? feedbackMessage({ initialValue: fieldValue, customDependencies })
      : feedbackMessage;
  };

  return (
    <DSFormItemLayout
      {...rest}
      name={name}
      labelText={handleLabel()}
      value={fieldValue}
      required={validator.required}
      hasError={validator.hasError}
      validationMessage={validator.errorMessage}
      inputComponent={<DSZipCodeSearch />}
      feedbackMessage={handleFeedbackMessage()}
      containerProps={containerProps}
      onSearch={(value) => {
        handleSetLoanDataValue({ value, pathVal: pathValue });
        rest.onSearch(value);
        handleValidator(value);
      }}
      onChange={(item) => {
        rest.onChange(item, pathValue);
      }}
    />
  );
}

ZipCodeSearchForm.defaultProps = {
  name: "",
  label: "",
  maskOpts: {},
  pathValue: "",
  maxLength: 50,
  required: false,
  dependencies: [],
  extraDependencies: [],
};

ZipCodeSearchForm.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  maskOpts: PropTypes.instanceOf(Object),
  pathValue: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  dependencies: PropTypes.instanceOf(Array),
  extraDependencies: PropTypes.instanceOf(Array),
};

export default memo(ZipCodeSearchForm);
