import React, { useEffect, useState, useMemo } from "react";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import {
  useCompareDataSelector,
  useLoanDataFieldSelector,
} from "data/useSelectors";

const Banner = () => {
  const [isBannerShown, setIsBannerShown] = useState(false);
  const [bannerMessage, setBannerMessage] = useState();
  const [bannerType, setBannerType] = useState();
  const { changeCounter: pricingChangesCount = 0 } = useCompareDataSelector();
  const loanFieldChanges = useLoanDataFieldSelector(
    "additionalLockInfo.lockCompareResults",
    {}
  );
  const loanFieldChangesCount = useMemo(
    () => Object.values(loanFieldChanges).flat().length,
    [loanFieldChanges]
  );

  useEffect(() => {
    if (pricingChangesCount > 0) {
      setIsBannerShown(true);
      setBannerMessage("Pricing has changed, the loan is still eligible.");
      setBannerType(BANNER_TYPES.WARNING);
    } else if (pricingChangesCount === 0 && loanFieldChangesCount === 0) {
      setIsBannerShown(true);
      setBannerMessage("Pricing and Loan Values have not changed.");
      setBannerType(BANNER_TYPES.SUCCESS);
    } else if (pricingChangesCount === 0 && loanFieldChangesCount !== 0) {
      setIsBannerShown(true);
      setBannerMessage("Pricing has not changed, the loan is still eligible.");
      setBannerType(BANNER_TYPES.SUCCESS);
    } else {
      setIsBannerShown(false);
    }
  }, [pricingChangesCount, loanFieldChangesCount]);

  return isBannerShown ? (
    <DSBanner
      containerProps={{
        id: "validate-banner",
        "data-testid": "Banner-all-components",
        style: { overflow: "visible" }, // ToDo while loading overflow is seeting hidden so was not showing he banner. Need to check why this is happening
      }}
      label={bannerMessage}
      type={bannerType}
      showCloseButton={false}
    />
  ) : null;
};

export default Banner;
