/* eslint-disable no-await-in-loop */
import {
  GET_RATES_SUCCESS,
  UPDATE_ERRORS_LIST,
} from "common/constants/ActionConstants";
import qualificationService from "Qualification/QualificationService";
import createAction from "utils/createAction";
import { cloneDeep } from "lodash";
import { openLoader, closeLoader } from "data/screenLoader/actions";
import browserHistory from "utils/browserHistory";
import AuthenticationService from "view/Authentication/AuthenticationService";
import Common from "common/services/Common";
import { setRedirectTabAction } from "data/landing/actions";
import { setLoanId, setLoanLimits } from "data/lockSummary/actions";
import {
  openGlobalErrorModalAction,
  openGiftFundErrorModal,
} from "data/globalErrorModal/actionCreators";
import { PATH_LOGIN, PATH_SEARCH_FOR_PRODUCTS } from "routes/constants";
import { ERROR_MESSAGES } from "utils/constants/ErrorMessages";
import { DEBUG_BUYSIDE } from "common/constants/AppGlobals";
import { searchFormActions } from "store/searchForm";
import { getLoanLimits } from "services/searchFormServices";
import Session, { JWT_TOKEN, USER_TYPE, CRED } from "services/Session";
import { SET_ALL_RATES_ROW_DATA } from "../common/constants/ActionConstants";
import { webHookDataBuyside } from "../mocks/Buyside";

const getNotificationSuccessAction = createAction(GET_RATES_SUCCESS);
const getRateFailAction = createAction(UPDATE_ERRORS_LIST);
let originCredentials;

export const setAllRatesRowData = createAction(SET_ALL_RATES_ROW_DATA);

export const getRates = (loanData) => {
  return async (dispatch) => {
    dispatch(openLoader("Qualifying Programs", "qualify"));
    dispatch(getRateFailAction([]));
    dispatch(searchFormActions.setErrorFilters([]));
    try {
      if (!Session.get(JWT_TOKEN)) {
        browserHistory.push(PATH_LOGIN);
      } else {
        let tokenRefreshed;
        do {
          // TODO: this loop is problematic and needs to be reworked
          tokenRefreshed = false;
          const results = await qualificationService.getRates(loanData);
          if (results && results.code !== undefined) {
            // if JWT is expired refresh the jwt
            if (results.code === "EPPS2002") {
              const refreshed = await AuthenticationService.refreshJWT(
                Session.get(USER_TYPE).userName,
                Session.get(JWT_TOKEN)
              );
              if (refreshed) {
                if (
                  refreshed === "Unauthorized" ||
                  refreshed.code === "INVALID_ACCESS_TOKEN"
                ) {
                  browserHistory.push(PATH_LOGIN);
                } else if (refreshed.token) {
                  Session.set(JWT_TOKEN, refreshed.token);
                  tokenRefreshed = true;
                }
              }
            } else if (
              ["EPPS1000", "EPPS90003", "EPPS1086"].includes(results?.code)
            ) {
              let result = [];
              const { errors } = results;

              if (results.code === "EPPS90003") result[0] = results;
              else result = results.errors;

              if (
                Array.isArray(errors) &&
                errors.some((elem) => elem.code === "EPPS1044")
              ) {
                dispatch(
                  openGlobalErrorModalAction({
                    title: ERROR_MESSAGES.EPPS1044.title,
                    errorMessage: ERROR_MESSAGES.EPPS1044.errorMessage,
                    onCloseCallback: Common.closeApplication,
                    confirmLabel: "Close ICE PPE",
                    showClose: false,
                  })
                );
              } else {
                dispatch(getRateFailAction(result));
                dispatch(searchFormActions.setErrorFilters(result));
                dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
              }
            }
          } else {
            dispatch(setLoanId(results.loanId));
            dispatch(getNotificationSuccessAction(results));
            if (results.loanId) {
              try {
                dispatch(closeLoader());
                let loanLimitsData = [];
                loanLimitsData = await getLoanLimits(results.loanId);
                dispatch(setLoanLimits(loanLimitsData));
              } catch (ex) {
                dispatch(
                  openGlobalErrorModalAction({
                    onCloseCallback: Common.closeApplication,
                  })
                );
              }
            }
          }
        } while (tokenRefreshed);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: Common.closeApplication,
        })
      );
    }
  };
};

async function GetWebhookData(loanData, dispatch, action) {
  await Common.getWebhookData(
    loanData,
    await Common.getOriginId(),
    null,
    null,
    await Common.getPATToken()
  )
    .then(async (webHookData) => {
      dispatch(action(webHookData));
      const { code, summary = "{}" } = webHookData || {};
      let parsedSummary;
      try {
        parsedSummary = JSON.parse(summary);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        parsedSummary = {
          code,
          details: typeof summary === "string" ? summary : "",
        };
      }
      if (
        code === "INVALID_ACCESS_TOKEN" &&
        parsedSummary.code === "EPPS2000"
      ) {
        dispatch(closeLoader());
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: parsedSummary.details,
            onCloseCallback: Common.closeApplication,
          })
        );
      }
      // if no mapping found redirect to create mapping
      else if (
        webHookData &&
        code &&
        code === "INVALID_ACCESS_TOKEN" &&
        parsedSummary
      ) {
        let redirect = false;
        originCredentials = Session.get(CRED);
        if (
          originCredentials &&
          originCredentials.userName &&
          originCredentials.password
        ) {
          const mapping = AuthenticationService.setUserEPPSMapping(
            originCredentials.UserName,
            originCredentials.Password
          );
          if (mapping) redirect = true;
          else
            Common.getWebhookData(
              loanData,
              await Common.getOriginId(),
              null,
              null,
              await Common.getPATToken()
            ).then((newWebHookData) => {
              if (newWebHookData && !newWebHookData.code) {
                dispatch(action(newWebHookData));
              } else if (newWebHookData && code && code === "EPPS2003") {
                redirect = true;
              } else {
                // update loanId
                dispatch(setLoanId(newWebHookData.loanId));
              }
            });
        } else redirect = true;
        // TODO this case might never occur
        if (redirect === true) {
          dispatch(
            openGlobalErrorModalAction({
              errorMessage: parsedSummary.details,
            })
          );
        }
      } else {
        if (code === "BAD_REQUEST") {
          if (
            parsedSummary.code === "EPPS90003" ||
            parsedSummary.code === "EPPS1000"
          ) {
            dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
          } else {
            dispatch(closeLoader());
            dispatch(
              openGlobalErrorModalAction({
                onCloseCallback: () => {
                  dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
                },
              })
            );
          }
        } else if (["EPPS1000", "EPPS90003", "EPPS1086"].includes(code)) {
          const result = webHookData.errors || [{ ...webHookData }];
          dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
          if (
            Array.isArray(result) &&
            result.some((elem) => elem.code === "EPPS1044")
          ) {
            dispatch(
              openGlobalErrorModalAction({
                title: ERROR_MESSAGES.EPPS1044.title,
                errorMessage: ERROR_MESSAGES.EPPS1044.errorMessage,
                onCloseCallback: Common.closeApplication,
                confirmLabel: "Close ICE PPE",
                showClose: false,
              })
            );
          } else if (
            Array.isArray(result) &&
            result.some((elem) => elem.code === "EPPS1090")
          ) {
            dispatch(openGiftFundErrorModal());
          } else {
            dispatch(getRateFailAction(result));
            dispatch(searchFormActions.setErrorFilters(result));
            dispatch(searchFormActions.setBannerIsOpen(true));
          }
        }
        dispatch(setLoanId(webHookData.loanId));
      }
    })
    .catch(() => {
      dispatch(closeLoader());
      dispatch(
        openGlobalErrorModalAction({
          onCloseCallback: () => {
            dispatch(setRedirectTabAction(PATH_SEARCH_FOR_PRODUCTS));
          },
        })
      );
    })
    .finally(() => {
      dispatch(closeLoader());
    });
}

export const getWebhookRates = (loanData) => {
  return async (dispatch) => {
    dispatch(openLoader("Qualifying Programs", "qualify"));
    // clear error list
    const error = [];
    dispatch(getRateFailAction(error));
    dispatch(searchFormActions.setErrorFilters(error));
    try {
      await GetWebhookData(loanData, dispatch, getNotificationSuccessAction);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      Common.closeApplication();
    }
  };
};

export const getBuySideQualification = async (
  effectiveDate,
  loanData,
  dispatch
) => {
  const loan = cloneDeep(loanData);
  if (effectiveDate === "current") {
    loan.relockRequest.effectiveDate = null;
  }
  loan.relockRequest.specificRateDataID = null;
  loan.relockRequest.specificProgramID = null;
  let webHookData;
  if (DEBUG_BUYSIDE) {
    webHookData = webHookDataBuyside;
  }
  if (!DEBUG_BUYSIDE) {
    webHookData = await Common.getWebhookData(
      loan,
      await Common.getOriginId(),
      null,
      null,
      await Common.getPATToken()
    );
  }
  if (webHookData && webHookData.code && webHookData.summary) {
    if (webHookData.code === "INVALID_ACCESS_TOKEN") {
      let redirect = false;
      originCredentials = Session.get(CRED);
      if (
        originCredentials &&
        originCredentials.userName &&
        originCredentials.password
      ) {
        const mapping = AuthenticationService.setUserEPPSMapping(
          originCredentials.UserName,
          originCredentials.Password
        );
        if (mapping) redirect = true;
      } else redirect = true;
      // TODO this case might never occur
      if (redirect === true) {
        dispatch(
          openGlobalErrorModalAction({
            errorMessage: webHookData.summary,
          })
        );
      }
    }
  } else if (webHookData.code === "BAD_REQUEST") {
    dispatch(closeLoader());
    dispatch(openGlobalErrorModalAction({}));
  } else if (
    webHookData.code === "EPPS1000" ||
    webHookData.code === "EPPS90003"
  ) {
    dispatch(closeLoader());
    dispatch(openGlobalErrorModalAction({}));
  }
  return webHookData;
};
