import React from "react";
import { node } from "prop-types";
import { DSModal, MODAL_TYPE_V2, MODAL_SUB_TYPE_V2 } from "@elliemae/ds-modal";
import Common from "common/services/Common";
import { errorMessages } from "common/constants/Messages";

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <DSModal
          centered
          isOpen
          modalSubType={MODAL_SUB_TYPE_V2.ERROR}
          modalTitle="Error"
          modalType={MODAL_TYPE_V2.DECISION}
          onClose={Common.closeApplication}
          confirmLabel="Ok"
          onConfirm={Common.closeApplication}
          version={2}
          title="Error"
        >
          <p>{errorMessages.GENERAL_ERROR_MODAL}</p>
        </DSModal>
      );
    }
    return children;
  }
}
GlobalErrorBoundary.propTypes = {
  children: node.isRequired,
};

export default GlobalErrorBoundary;
