import React from "react";
import { pause } from "services/helpers";
import api from "./api";

export const truncateText = (text = "", length) => {
  return text.length > length ? `${text.slice(0, length - 3)}...` : text;
};

export const labelItalicTextFixer = (message) => (
  // This function fixes a dimsum defect that removes 1px from the end of the text (for italic letters).
  <span>
    {message}
    <span style={{ width: "2px", display: "inline-block" }} />
  </span>
);

export const updatePollTransaction = async (opts) => {
  await api.updateTransaction(opts);

  const { transactionID } = opts;

  try {
    const timeoutInSeconds = 30;
    for (let i = 0; i < timeoutInSeconds; i += 1) {
      /* eslint-disable no-await-in-loop */
      await pause(2000);

      const response = await api.getTransactionStatus(transactionID);
      if (response.summary === "Conflict") {
        throw new Error(`${response.summary}: ${response.details}`);
      }
      if (response.status === "completed") {
        return;
      }
    }

    throw new Error("Unable to verify transaction status");
  } catch (err) {
    throw new Error(err);
  }
};
