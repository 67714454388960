/* eslint-disable no-param-reassign */
import React from "react";
import Common from "common/services/Common";
import { CellWithAddons } from "@elliemae/ds-datagrids";
import api from "utils/api";
import Session, { IS_LO, JWT_TOKEN } from "services/Session";

export const getProgramAdjustedAndRawPricesServices = async (loanId) => {
  let response;

  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    response = await api.getProgramAdjustedAndRawPricesV1(
      { patToken, originId },
      loanId
    );
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    response = await api.getProgramAdjustedAndRawPricesV2({ jwtToken }, loanId);
  }

  return {
    priceData: {
      adjustedPrice: response?.priceData?.adjustedPrice || [],
      rawPrice: response?.priceData?.rawPrice || [],
    },
  };
};

const addIdsToPricing = (pricingArray = []) => {
  if (Array.isArray(pricingArray)) {
    return pricingArray.map((elem, index) => ({ ...elem, id: index }));
  }
  return [];
};

export const getTableDataWithIDs = (priceData = {}) => {
  const rawPrice = addIdsToPricing(priceData.rawPrice);
  const adjustedPrice = addIdsToPricing(priceData.adjustedPrice);
  return { rawPrice, adjustedPrice };
};

/*
This function returns an array like this:
[
  {
    property: "rate",
    label: "rate",
  },
  {
    property: "15.val",
    label: "15",
  },
  {
    property: "30.val",
    label: "30",
  },
  ...
]
*/
export const getPriceCols = (
  tableData = [],
  price = "adjustedPrice",
  lienData = {}
) => {
  const adjustmentsVal = lienData.adjustments;

  // If undefined return, because there's no rate or price.
  if (!adjustmentsVal) return [];

  let index = -1;
  let rateVal = 0;
  let priceVal = 0;
  const { lockDays } = lienData;

  // If we're in adjusted price table, search for Final prices
  if (price === "adjustedPrice") {
    index = adjustmentsVal.map((val) => val.adjustmentType).indexOf("Final");
  }

  // If we're in adjusted price table, search for Final prices
  if (price === "rawPrice") {
    index = adjustmentsVal.map((val) => val.adjustmentType).indexOf("Base");
  }

  if (index !== -1) {
    rateVal = adjustmentsVal[index].rate;
    priceVal = adjustmentsVal[index].price;
  }

  let columns = [
    {
      property: "rate",
      label: "Rate",
      customRenderer: (data) => {
        return (
          <CellWithAddons
            value={
              <span>
                {Number.parseFloat(data.value.props.value).toFixed(3)}%
              </span>
            }
          />
        );
      },
    },
  ];
  if (tableData[price] && tableData[price].length) {
    const keys = Object.keys(tableData[price][0]).filter(
      (elem) => elem !== "id" && elem !== "rate"
    );
    columns = [
      ...columns,
      ...keys.map((elem) => {
        return {
          property: `${elem}.val`,
          label: elem,
          // Added custom renderer to avoid traversing this structure
          // just to cut the decimals into a max of three digits.
          customRenderer: (data) => {
            const highlighCell =
              Number.parseFloat(data.metaData.rowData.rate).toFixed(3) ===
                Number.parseFloat(rateVal).toFixed(3) &&
              Number.parseFloat(data.value.props.value).toFixed(3) ===
                Number.parseFloat(priceVal).toFixed(3) &&
              Number.parseInt(elem, 10) === Number.parseInt(lockDays, 10);

            // Parse the value to float
            let value = Number.parseFloat(data.value.props.value);

            // If the parsing was NaN, then show a "-",
            // else fix the value to 3 decimals.
            if (Number.isNaN(value)) value = "-";
            else value = value.toFixed(3);

            return (
              <CellWithAddons
                highLight={highlighCell}
                value={<span>{value}</span>}
              />
            );
          },
        };
      }),
    ];
  }
  // eslint-disable-next-line consistent-return
  return columns;
};
