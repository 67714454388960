import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { arrayOf, shape, bool } from "prop-types";
import { DSTabs, DSTab } from "@elliemae/ds-tabs";
import { DSDataGrid } from "@elliemae/ds-datagrids";
import Rights from "common/services/Rights";
import { getPriceCols } from "./helpers";

const DataGridPricingDetails = ({ tableData, showDSTabs }) => {
  const lienData = useSelector(
    ({ epps }) => epps.lockSummary.rateSelector.lien || {}
  );
  // This is used because of dimsum DataGrid :)
  // Remove in the future when updating
  // DSDataGrid to DSDataTable.
  useLayoutEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(
        "tab1__pricing__details__modal-label"
      );

      if (!element) return;

      if (!tableData.rawPrice || tableData.rawPrice.length === 0) {
        element.style.display = "none";
      } else {
        element.style.display = "block";
      }
    }, 0);
  });

  const renderDataGrid = (priceType) => (
    <DSDataGrid
      columns={getPriceCols(tableData, priceType, lienData)}
      rows={tableData[priceType]}
    />
  );

  const renderHasViewHoldBackRights = () => (
    <DSTabs onlyRenderActiveTab>
      <DSTab tabId="tab0__pricing__details__modal" title="Adjusted Prices">
        <div style={{ marginTop: 16 }}>{renderDataGrid("adjustedPrice")}</div>
      </DSTab>
      <DSTab tabId="tab1__pricing__details__modal2" title="Raw Prices">
        <div style={{ marginTop: 16 }}>{renderDataGrid("rawPrice")}</div>
      </DSTab>
    </DSTabs>
  );

  const renderHasNoViewHoldBackRights = () => (
    <div>
      <span style={{ fontSize: "16px" }}>Adjusted Prices</span>
      <div style={{ marginTop: 16 }}>{renderDataGrid("adjustedPrice")}</div>
    </div>
  );

  const renderWithDsTabs = () =>
    Rights.viewRawPrices
      ? renderHasViewHoldBackRights()
      : renderHasNoViewHoldBackRights();

  const renderWithoutDsTabs = () => (
    <div data-testid="DataGridPricingDetails-all-components">
      <span style={{ display: `flex`, padding: `10px 0 17px 0` }}>
        ADJUSTED PRICES
      </span>
      {renderDataGrid("adjustedPrice")}
      {!!tableData.rawPrice && tableData.rawPrice.length > 0 && (
        <>
          <span style={{ display: `flex`, padding: `10px 0 17px 0` }}>
            RAW PRICES
          </span>
          {renderDataGrid("rawPrice")}
        </>
      )}
    </div>
  );

  return (
    <div
      style={{
        borderBottom: "0.07692rem solid #EBEDF0",
        marginBottom: 10,
        paddingTop: 16,
      }}
    >
      {showDSTabs ? renderWithDsTabs() : renderWithoutDsTabs()}
    </div>
  );
};

DataGridPricingDetails.defaultProps = {
  tableData: [],
  showDSTabs: true,
};

DataGridPricingDetails.propTypes = {
  tableData: arrayOf(
    shape({
      rawPrice: arrayOf(shape({})),
      adjustedPrice: arrayOf(shape({})),
    })
  ),
  showDSTabs: bool,
};

export default DataGridPricingDetails;
