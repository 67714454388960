import { partitionArray } from "utils/shared";
import Session, { IS_VALIDATE, ORIGIN_LOCKID } from "services/Session";

export const parseOriginAdjustments = (originalLoanInfo) => {
  const { rateLock } = originalLoanInfo || {};
  const {
    buySideAdjustments,
    buySidePriceRate,
    buySideRate,
    buySidePriceNetBuyPrice,
    buySideRateNetBuyRate,
  } = rateLock || {};

  if (
    [
      buySideAdjustments,
      buySidePriceRate,
      buySideRate,
      buySidePriceNetBuyPrice,
      buySideRateNetBuyRate,
    ].includes(undefined)
  ) {
    return [];
  }

  const mappedAdjustments = buySideAdjustments
    .filter(
      ({ priceAdjustmentType, adjustmentType }) =>
        ["BasePrice", "BaseRate", "ProfitMargin"].includes(
          priceAdjustmentType
        ) ||
        ["CorporatePriceConcession", "BranchPriceConcession"].includes(
          adjustmentType
        )
    )
    .map(({ description, rate, priceAdjustmentType, adjustmentType }) => ({
      adjustmentType:
        priceAdjustmentType === "ProfitMargin" ? "Profit" : adjustmentType,
      description,
      price: priceAdjustmentType !== "BaseRate" ? rate : null,
      rate: priceAdjustmentType === "BaseRate" ? rate : null,
    }));
  return [
    {
      adjustmentType: "Final",
      description: "FINAL",
      price: buySidePriceNetBuyPrice,
      rate: buySideRateNetBuyRate,
    },
    {
      adjustmentType: "Base",
      description: "BASE",
      price: buySidePriceRate,
      rate: buySideRate,
    },
    ...mappedAdjustments,
  ];
};

const roundAdjustment = (num) => Number((num || 0).toFixed(3));

export const getAdjustmentCompare = (
  leftAdjustments = [],
  rightAdjustments = []
) => {
  if (leftAdjustments.length === 0 || rightAdjustments.length === 0) {
    return { comparissonArray: [], changeCounter: 0 };
  }
  const tempObj = {};
  leftAdjustments.forEach((adj) => {
    let key = adj.description;
    if (
      adj.adjustmentType === "ReLockFeeAdjustment" ||
      adj.adjustmentType === "CustomPriceAdjustment"
    ) {
      key = `${adj.description}|${adj.price}|${adj.adjustmentType}`;
    }
    tempObj[key] = adj;
  });
  rightAdjustments.forEach((adj) => {
    let key = adj.description;
    if (
      adj.adjustmentType === "ReLockFeeAdjustment" ||
      adj.adjustmentType === "CustomPriceAdjustment"
    ) {
      key = `${adj.description}|${adj.price}|${adj.adjustmentType}`;
    }
    if (tempObj[key]) {
      tempObj[key] = {
        ...tempObj[key],
        currentPrice: adj.price,
        currentRate: adj.rate,
      };
    } else {
      tempObj[key] = {
        ...adj,
        currentPrice: adj.price,
        currentRate: adj.rate,
      };
      delete tempObj[key].price;
      delete tempObj[key].rate;
    }
  });
  let changeCounter = 0;
  const comparissonArray = Object.values(tempObj).map((adj) => {
    const histPrice = roundAdjustment(adj.price);
    const currPrice = roundAdjustment(adj.currentPrice);
    const changePrice = Math.abs(histPrice - currPrice);

    const histRate = roundAdjustment(adj.rate);
    const currRate = roundAdjustment(adj.currentRate);
    const changeRate = Math.abs(histRate - currRate);

    if (
      !Session.get(IS_VALIDATE) ||
      (adj.description !== "All-In (incl. Profit Margins)" &&
        adj.description !== "FINAL")
    ) {
      if (changeRate !== 0) changeCounter += 1;
      if (changePrice !== 0) changeCounter += 1;
    }

    return {
      ...adj,
      change: changePrice.toFixed(3),
    };
  });
  return { comparissonArray, changeCounter };
};

export const parseRateAdjustmentsValidate = (
  adjustments,
  originContainsLOComp
) => {
  const [adjsNoSRP, srpAdjs] = partitionArray(
    adjustments,
    ({ adjustmentType }) => adjustmentType !== "SRP"
  );
  const srpSum = srpAdjs.reduce(
    (
      { price: prevPrice, rate: prevRate },
      { price: currentPrice, rate: currentRate }
    ) => ({
      price: prevPrice + Number(currentPrice),
      rate: prevRate + Number(currentRate),
    }),
    { price: 0, rate: 0 }
  );
  const baseAdj = adjustments.find(
    ({ adjustmentType }) => adjustmentType === "Base"
  );
  const finalAdj = adjustments.find(
    ({ adjustmentType }) => adjustmentType === "Final"
  );
  const [adjsNoSrpNoComp, [loComp]] = partitionArray(
    adjsNoSRP,
    ({ description }) => description !== "L.O. Compensation"
  );
  if (originContainsLOComp || !loComp) {
    const newBase = {
      ...baseAdj,
      price: Number(baseAdj.price) + srpSum.price,
      rate: Number(baseAdj.rate) + srpSum.rate,
    };
    return [
      newBase,
      ...adjsNoSRP.filter(({ adjustmentType }) => adjustmentType !== "Base"),
    ];
  }
  const newBaseWithoutSrpOrComp = {
    ...baseAdj,
    price: Number(baseAdj.price) + srpSum.price,
    rate: Number(baseAdj.rate) + srpSum.rate,
  };
  const newFinalWithoutSrpOrComp = {
    ...finalAdj,
    price: Number(finalAdj.price) - Number(loComp.price),
    rate: Number(finalAdj.rate),
  };
  return [
    newBaseWithoutSrpOrComp,
    ...adjsNoSrpNoComp.filter(
      ({ adjustmentType }) =>
        adjustmentType !== "Base" && adjustmentType !== "Final"
    ),
    newFinalWithoutSrpOrComp,
  ];
};
export const checkPricingStatus = (
  programID,
  rateID,
  rateValue,
  pricingResults = {},
  changedNoteRate = false
) => {
  const { loanPrograms } = pricingResults;
  const program = loanPrograms?.find(
    ({ programID: pricingProgramID }) => pricingProgramID === programID
  );
  if (program) {
    if (program.deleted === 0) {
      const rateIDMatch = program.rateDetails?.find(
        ({ rateDataID }) => rateDataID === rateID
      );
      const rateValueMatch = program.rateDetails?.find(
        ({ rate }) => rate === rateValue
      );
      const { rateDataID } = changedNoteRate
        ? rateValueMatch || {}
        : rateIDMatch || rateValueMatch || {};
      if (rateDataID) {
        return { program, rateDataID, status: "eligible" };
      }
      return { program, rateDataID: null, status: "ineligibleRate" };
    }
    return { program, rateDataID: rateID, status: "ineligible" };
  }
  return { program: null, rateDataID: null, status: "unavailable" };
};

export const getSnapshotProgramInfo = (lockRequests) => {
  const currentSnapshot = lockRequests.find(
    ({ id }) => id === Session.get(ORIGIN_LOCKID)
  );
  const activeLockSnapshot = lockRequests.find(
    ({ requestedStatus }) => requestedStatus === "Locked"
  );
  const snapshot = currentSnapshot || activeLockSnapshot || {};
  const { snapshotFields = [] } = snapshot;
  const { value: snapshotProgramName = "" } =
    snapshotFields.find(({ fieldId }) => fieldId === "2866") || {};
  const { value: snapshotInvestorName = "" } =
    snapshotFields.find(({ fieldId }) => fieldId === "2278") || {};
  return `${snapshotInvestorName} ${snapshotProgramName}`;
};

const getMatchingRate = (rateDetails = [], selectedRate) => {
  return rateDetails.find(({ rate }) => rate === selectedRate);
};

export const findBestProgram = (
  loanPrograms = [],
  selectedRate,
  selectedTerm
) => {
  const matchingPrograms = loanPrograms.filter(
    ({ deleted, term, rateDetails }) =>
      deleted === 0 &&
      term === selectedTerm &&
      getMatchingRate(rateDetails, selectedRate)
  );

  const bestProgram = matchingPrograms.reduce((prev, curr) => {
    if (
      prev === null ||
      getMatchingRate(prev.rateDetails, selectedRate).price >
        getMatchingRate(curr.rateDetails, selectedRate).price
    ) {
      return curr;
    }
    return prev;
  }, null);
  const { programID: bestProgramID, rateDetails: bestRateDetails } =
    bestProgram || {};
  const bestRateID = getMatchingRate(bestRateDetails, selectedRate)?.rateDataID;
  return { bestProgramID, bestRateID };
};
