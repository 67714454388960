import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { DSButtonV2 } from "@elliemae/ds-button";
import { Close, WarningTriangle } from "@elliemae/ds-icons";
import {
  DSDialog,
  DSDialogHeader,
  DSDialogBody,
  DSDialogFooter,
  DSDialogSeparator,
  DSDialogAddon,
  DSDialogDefaultLayout,
  DSDialogPrimaryMessage,
  DSDialogSecondaryMessage,
} from "@elliemae/ds-dialog";

const GlobalErrorModal = () => {
  const {
    isOpen,
    title,
    errorMessage,
    onClose,
    showClose = true,
    confirmLabel = "Ok",
    discardLabel = "",
    size = "small",
    icon = <WarningTriangle size="xxl" color={["danger", "900"]} />,
  } = useSelector(({ globalErrorModal }) => globalErrorModal);

  const handleOnClick = useCallback(onClose);

  return (
    <DSDialog isOpen={isOpen} onClose={handleOnClick} size={size}>
      {showClose && (
        <DSDialogHeader>
          <DSDialogAddon>
            <DSButtonV2 buttonType="icon" onClick={handleOnClick}>
              <Close color={["neutral", "900"]} />
            </DSButtonV2>
          </DSDialogAddon>
        </DSDialogHeader>
      )}

      <DSDialogBody p={24}>
        <DSDialogDefaultLayout>
          {icon}
          {title && (
            <DSDialogPrimaryMessage style={{ textAlign: "center" }}>
              {title}
            </DSDialogPrimaryMessage>
          )}
          {errorMessage && (
            <DSDialogSecondaryMessage>{errorMessage}</DSDialogSecondaryMessage>
          )}
        </DSDialogDefaultLayout>
      </DSDialogBody>

      <DSDialogSeparator />

      <DSDialogFooter>
        {discardLabel && (
          <DSButtonV2 onClick={handleOnClick} buttonType="outline">
            Discard
          </DSButtonV2>
        )}
        {confirmLabel && (
          <DSButtonV2 onClick={handleOnClick}>{confirmLabel}</DSButtonV2>
        )}
      </DSDialogFooter>
    </DSDialog>
  );
};

export default memo(GlobalErrorModal);
