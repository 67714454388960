import api from "utils/api";
import Common from "common/services/Common";
import Session, { IS_LO, JWT_TOKEN } from "services/Session";

export const getGeocodes = async (loanId) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getGeocodesV1({ patToken, originId }, loanId);
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getGeocodesV2({ jwtToken }, loanId);
  }
  return result;
};

export const getLoanLimits = async (loanData) => {
  let result;
  if (Session.get(IS_LO)) {
    const patToken = await Common.getPATToken();
    const originId = await Common.getOriginId();
    result = await api.getLoanLimitsV1({ patToken, originId }, loanData);
  } else {
    const jwtToken = await Session.get(JWT_TOKEN);
    result = await api.getLoanLimitsV2({ jwtToken }, loanData);
  }
  return result;
};

export const getZipLocations = async (zip) => {
  let locations = [];
  if (Session.get(IS_LO)) {
    locations = await api.getWebhookZipLocations(zip);
  } else {
    locations = await api.getZipLocations(zip, {
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: "Bearer",
    });
  }
  return locations;
};

export const getStateCounties = async (stateID) => {
  let counties = [];
  if (Session.get(IS_LO)) {
    counties = await api.getWebhookStateCounties(stateID);
  } else {
    counties = await api.getStateCounties(stateID, {
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: "Bearer",
    });
  }
  return counties;
};

export const getAllLookups = async () => {
  let response = [];
  if (Session.get(IS_LO)) {
    response = await api.getWebhookLookups("all");
  } else {
    response = await api.getAllLookups({
      customToken: { value: Session.get(JWT_TOKEN) },
      tokenType: "Bearer",
    });
  }
  if (response.code) {
    throw new Error(response.code);
  }
  return response;
};
