import styled from "styled-components";

export const StyledDSToolbarWrapper = styled.div`
  .em-ds-toolbar {
    justify-content: space-between;
    padding-bottom: 16px;
    height: auto;
    .toolbar-item {
      align-items: center;
      .em-ds-filter-bar {
        margin-bottom: 0;
      }
    }
  }
  .em-ds-pill {
    margin-bottom: 0px;
  }
`;
