import React from "react";
import { LockUnlockedRateRequested as LockIcon } from "@elliemae/ds-icons";
import CustomHeader from "components/CustomColumnHeader/index";
import Session, { IS_BUYSIDE } from "services/Session";
import {
  dispDiscount,
  dispCurrencyMask,
  dispApr,
  dispCurrency,
  checkLockRateStatus,
} from "view/SearchResult/helpers";
import Rights from "common/services/Rights";
import { StyledPriceLink } from "./styled";

const CellValue = ({ value, isTargetRow, leftAddon = false }) => {
  const fontWeight = isTargetRow ? { fontWeight: "bold" } : {};
  return (
    <div
      style={{
        display: `flex`,
        justifyContent: `flex-end`,
        alignContent: `center`,
        width: `100%`,
      }}
    >
      {leftAddon && (
        <LockIcon
          color={["warning", "600"]}
          title="Rate lock has been requested for this item"
          style={{
            marginRight: `10px`,
            flex: 0.7,
            justifyContent: "flex-start",
          }}
        />
      )}
      <span
        style={{
          paddingRight: "3px",
          flex: leftAddon ? 0.3 : 1,
          textAlign: "right",
          ...fontWeight,
        }}
      >
        {value}
      </span>
    </div>
  );
};

const rateRenderer = (relockRequest = {}, programId, targetRow) => ({
  row: { original: rowData },
}) => {
  const { rate, rateDataID } = rowData;
  const displayLockIcon = checkLockRateStatus(
    relockRequest,
    programId,
    rateDataID
  );
  const value = rate ? `${rate.toFixed(3)}%` : null;
  return (
    <CellValue
      value={value}
      leftAddon={displayLockIcon}
      isTargetRow={targetRow && rowData.rateDataID === targetRow.rateDataID}
    />
  );
};

export const getAllRatesColumns = ({
  relockRequest,
  programId,
  targetRow,
  onClickPrice,
}) => {
  const isBuyside = Session.get(IS_BUYSIDE);
  return [
    {
      Header: () => <CustomHeader label="Rate" />,
      Cell: rateRenderer(relockRequest, programId, targetRow),
    },
    ...(Rights.viewAPR && !isBuyside
      ? [
          {
            Header: () => <CustomHeader label="Estimated APR" />,
            Cell: ({ row: { original: rowData } }) => {
              return (
                <CellValue
                  value={dispApr(rowData.apr)}
                  isTargetRow={
                    targetRow && rowData.rateDataID === targetRow.rateDataID
                  }
                />
              );
            },
          },
        ]
      : []),
    {
      Header: () => <CustomHeader label="Price" />,
      Cell: ({ row: { original: rowData } }) => (
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <StyledPriceLink
            onClick={() => onClickPrice(rowData)}
            isTargetRow={
              targetRow && rowData.rateDataID === targetRow.rateDataID
            }
          >
            {(100 - rowData.price).toFixed(3)}
          </StyledPriceLink>
        </div>
      ),
    },
    ...(!isBuyside
      ? [
          {
            Header: () => <CustomHeader label="Discount/Rebate" />,
            Cell: ({ row: { original: rowData } }) => (
              <CellValue
                value={dispCurrencyMask(rowData.priceVal)}
                isTargetRow={
                  targetRow && rowData.rateDataID === targetRow.rateDataID
                }
              />
            ),
          },
          {
            Header: () => <CustomHeader label="Discount/Rebate Points" />,
            Cell: ({ row: { original: rowData } }) => (
              <CellValue
                value={dispDiscount(-rowData.price)}
                isTargetRow={
                  targetRow && rowData.rateDataID === targetRow.rateDataID
                }
              />
            ),
          },
          {
            Header: () => <CustomHeader label="Payment (P&I)" />,
            Cell: ({ row: { original: rowData } }) => (
              <CellValue
                value={dispCurrency(rowData.pnI)}
                isTargetRow={
                  targetRow && rowData.rateDataID === targetRow.rateDataID
                }
              />
            ),
          },
        ]
      : []),
  ];
};

export const getAllRatesRows = () => [];
