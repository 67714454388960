import React from "react";
import { string, number, node } from "prop-types";
import styled from "styled-components";
import { Grid } from "@elliemae/ds-grid";

export const StyledWrapContainer = styled.div`
  display: flex;
  margin: ${({ m = "0 auto" }) => m};
  flex-wrap: wrap;
  align-items: ${({ alignItems = "baseline" }) => alignItems};
  padding: 0px calc(${({ gap = "16px" }) => gap} / 2);
  padding-left: ${({ pl, gap = "16px" }) => pl || `calc(${gap}/ 2)`};
  padding-right: ${({ pr, gap = "16px" }) => pr || `calc(${gap}/ 2)`};
  padding-bottom: 8px;
  ${(props = {}) => {
    const { children = [], cols = 10, gap = "16px" } = props;
    const filterChildren = children.filter((el) => el);
    return filterChildren.map((elem, index) => {
      if (elem) {
        const { props: { span = 1, ...rest } = {} } = elem;
        const isPaddingTop = rest.paddingTop || `0px`;
        const isPaddingBottom = rest.paddingBottom || `calc(${gap}/2)`;
        const paddingLeft = rest.pl || `calc(${gap}/2)`;
        const paddingRight = rest.pr || `calc(${gap}/2)`;
        return `
          .wrapper-item-epps:nth-child(${index + 1}){
            padding: ${isPaddingTop} ${paddingRight} ${isPaddingBottom} ${paddingLeft};
            width: ${(span * 100) / cols}%;
          }
        `;
      }
      return "";
    });
  }}
`;

export const StyledWrapItem = styled.div`
  margin: ${({ marginAuto }) => (marginAuto ? "auto 0" : "0")};
  & > *:first-child {
    width: 100%;
  }
`;

const StyledLabel = styled.span`
  display: block;
  width: fit-content;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledDiv = styled.div`
  margin-left: ${({ ml = "0px" }) => ml};
  margin-right: ${({ mr = "16px" }) => mr};
  margin-top: ${({ mt = "none" }) => mt};
  display: flex;
  justify-content: flex-start;
`;

export const CheckBoxWrapper = ({ children, ml, mr, mt }) => (
  <StyledDiv ml={ml} mr={mr} mt={mt}>
    {children}
  </StyledDiv>
);

CheckBoxWrapper.defaultProps = {
  ml: "0px",
  mr: "16px",
  mt: "none",
};
CheckBoxWrapper.propTypes = {
  children: node.isRequired,
  ml: string,
  mr: string,
  mt: string,
};

export const TruncatedLabel = ({ label }) => <StyledLabel>{label}</StyledLabel>;

TruncatedLabel.propTypes = { label: string.isRequired };

export const GridItemCheckboxWrapper = ({ children, span, alignItems }) => (
  <Grid span={span} height="100%" alignItems={alignItems} paddingTop="13px">
    {children}
  </Grid>
);

GridItemCheckboxWrapper.propTypes = {
  children: node.isRequired,
  alignItems: string,
  span: number,
};

GridItemCheckboxWrapper.defaultProps = {
  alignItems: "center",
  span: 1,
};
