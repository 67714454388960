import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { useLoanDataFieldSelector, useLookup } from "data/useSelectors";
import { PATH_SEARCH_RESULTS } from "routes/constants";
import { get } from "lodash";
import Session, { IS_BUYSIDE, IS_LOCK_EXTENSION } from "services/Session";
import { HISTORICAL_PRICING_TEXT } from "../HeaderMenu/utils/constants";

function PricingNotificationHeader() {
  const [isOpen, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const { effectiveDate = "" } = useLoanDataFieldSelector("relockRequest", {});
  const { tabId } = useSelector(({ landing }) => landing);
  const clientsettings = useLookup("clientSettings", "");

  useEffect(() => {
    const momentEffectiveDate = moment.tz(
      effectiveDate,
      "YYYY-MM-DDTHH:mm:ss a z",
      "America/New_York"
    );
    setOpen(
      tabId === PATH_SEARCH_RESULTS &&
        !!effectiveDate &&
        momentEffectiveDate.isBefore(moment())
    );
    if (
      Session.get(IS_BUYSIDE) === true &&
      Session.get(IS_LOCK_EXTENSION) === false &&
      get(clientsettings, "[0].options.isBuySideHistorical") === false
    ) {
      setShowMessage(false);
    }
  }, [effectiveDate, tabId, showMessage]);

  const getEffectiveDate = () =>
    moment.tz(effectiveDate, "America/New_York").format("MM-DD-yyyy hh:mm A");

  return (
    <DSBanner
      body={`Effective Date ${getEffectiveDate()} EST`}
      isOpen={showMessage && isOpen}
      label={HISTORICAL_PRICING_TEXT}
      onClose={() => setOpen(false)}
      type={BANNER_TYPES.WARNING}
      data-testid="PricingNotificationHeader-all-components"
    />
  );
}

export default PricingNotificationHeader;
