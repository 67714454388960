const handlePriceChange = (e) => {
  if (parseFloat(e.target.value) < 80 || parseFloat(e.target.value) > 120) {
    return {
      hasError: true,
      errorMessage: "Please enter price between 80 and 120",
    };
  }
  return {
    hasError: false,
    errorMessage: "",
  };
};

const handleRateChange = (e) => {
  if (parseFloat(e.target.value) < 0 || parseFloat(e.target.value) > 20) {
    return {
      hasError: true,
      errorMessage: "Please enter rate between 0 and 20",
    };
  }
  return {
    hasError: false,
    errorMessage: "",
  };
};

const handleDaysChange = (e) => {
  if (parseInt(e.target.value, 10) < 1 || parseInt(e.target.value, 10) > 30) {
    return {
      hasError: true,
      errorMessage: "Please provide expiration between 1 and 30 days",
    };
  }
  return {
    hasError: false,
    errorMessage: "",
  };
};

const handleEmailChange = (e) => {
  if (
    e.value !== undefined &&
    e.value.trim() !== "" &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(e.value)
  ) {
    return {
      hasError: true,
      errorMessage: "Please provide valid email",
    };
  }
  return {
    hasError: false,
    errorMessage: "",
  };
};

export {
  handlePriceChange,
  handleRateChange,
  handleDaysChange,
  handleEmailChange,
};
