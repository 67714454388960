export default function createAction(type) {
  return (payload) => ({
    type,
    payload,
  });
}

// TODO all these functions below are action creators and should be placed elsewhere
export const openLoader = (message) => ({
  type: "@SCREEN_LOADER@",
  payload: { show: true, ...(message && { message }) },
});

export const closeLoader = () => ({
  type: "@SCREEN_LOADER@",
  payload: { show: false },
});

export const opeGlobalError = (title, errorMessage, onClose) => ({
  type: "SWITCH_ERROR_MODAL",
  payload: {
    showErrorModal: true,
    title,
    errorMessage,
    onClose,
  },
});

export const closeGlobalError = () => ({
  type: "SWITCH_ERROR_MODAL",
  payload: {
    showErrorModal: false,
    title: "",
    errorMessage: "",
    onClose: () => {},
  },
});
