export const LIEN_POSITION = [
  {
    path: "loanInformation",
    values: ["lienPosition"],
  },
];

export const DELIVERY_TYPE = [
  {
    path: "loanInformation",
    values: ["commitmentType"],
  },
];

export const EXTRA_SUBORDINATE_HELOC = [
  {
    path: "initialValues",
    values: ["initialOtherHELOCLines", "initialOtherHELOCDraws"],
  },
];

export const PROPERTY_VALUE = [
  {
    path: "loanInformation",
    values: ["loanPurpose", "lienPosition"],
  },
];

export const SECOND_MORTGAGE_AMOUNT = [
  {
    path: "loanInformation",
    values: ["lienPosition", "secondMortgageAmount"],
  },
];

export const OTHER_PAYMENT = [
  {
    path: "loanInformation",
    values: ["lienPosition", "secondMortgageAmount"],
  },
];

export const LOAN_AMOUNT_INITIAL_DRAW = [
  {
    path: "loanInformation",
    values: ["lienPosition", "otherPayment"],
  },
];

export const EXTRA_REFINANCE_TYPE = [
  {
    path: "formValidations",
    values: ["isRefinance"],
  },
];

export const LOAN_PURPOSE = [
  {
    path: "loanInformation",
    values: ["loanPurpose", "lienPosition"],
  },
];

export const EXTRA_SECOND_MORTGAGE_AMOUNT = [
  {
    path: "initialValues",
    values: ["initialSecondMortgageAmount"],
  },
];

export const SECOND_MORTGAGE_BALANCE = [
  {
    path: "loanInformation",
    values: ["lienPosition", "otherPayment"],
  },
];

export const TOTAL_LOAN_AMOUNT = [
  {
    values: ["standardProducts"],
  },
  {
    path: "loanInformation",
    values: ["firstMortgageAmount", "financedAmount", "feeAmountFinanced"],
  },
];

export const EXTRA_IS_HELOC = [
  {
    path: "formValidations",
    values: ["isHELOC"],
  },
];

export const EXTRA_CLTV = [
  {
    path: "formValidations",
    values: ["isHELOC", "isGovie", "isIRRRL", "isVA"],
  },
];

export const CHANNEL_INFO = [
  {
    path: "",
    values: ["channel"],
  },
];

export const LTV = [
  {
    path: "loanInformation",
    values: [
      "financedAmount",
      "firstMortgageAmount",
      "loanPurpose",
      "lienPosition",
      "loanAmountMaxLine",
      "secondMortgageAmount",
      "totalMortgageAmount",
    ],
  },
  {
    path: "property",
    values: ["value"],
  },
  {
    values: ["specialProducts", "standardProducts", "productType"],
  },
];

export const CLTV = [
  {
    path: "loanInformation",
    values: [
      "firstMortgageAmount",
      "loanPurpose",
      "lienPosition",
      "loanAmountMaxLine",
      "secondMortgageAmount",
      "totalMortgageAmount",
      "totSubClosedEndMort",
      "otherHELOCDraws",
      "otherHELOCLines",
    ],
  },
  {
    path: "property",
    values: ["value"],
  },
];

export const HCLTV = [
  {
    path: "loanInformation",
    values: [
      "firstMortgageAmount",
      "lienPosition",
      "loanAmountMaxLine",
      "secondMortgageAmount",
      "totalMortgageAmount",
      "totSubClosedEndMort",
    ],
  },
  {
    path: "property",
    values: ["value"],
  },
  {
    values: ["otherHELOCLines", "otherHELOCDraws"],
  },
];

export const FIRST_MORTGAGE_AMOUNT = [
  {
    path: "loanInformation",
    values: [
      "ltv",
      "financedAmount",
      "firstMortgageAmount",
      "loanPurpose",
      "lienPosition",
      "loanAmountMaxLine",
      "secondMortgageAmount",
    ],
  },
  {
    path: "property",
    values: ["value"],
  },
  {
    values: ["specialProducts", "standardProducts", "productType"],
  },
];

export const LOAN_AMOUNT_MAX_LIEN = [
  {
    path: "loanInformation",
    values: ["ltv", "lienPosition"],
  },
  {
    path: "property",
    values: ["value"],
  },
];

export const UPFRONT_GOVT_FEES_FACTOR = [
  {
    values: ["standardProducts"],
  },
  {
    path: "loanInformation",
    values: ["ltv", "loanPurpose"],
  },
];

export const UPFRONT_GOVT_FEES_AMOUNT = [
  {
    values: ["standardProducts"],
  },
  {
    path: "loanInformation",
    values: [
      "firstMortgageAmount",
      "secondMortgageAmount",
      "lienPosition",
      "loanPurpose",
    ],
  },
];

export const UPFRONT_GOVT_FEES_PAID_IN_CASH = [
  {
    values: ["standardProducts"],
  },
  {
    path: "loanInformation",
    values: ["feeAmount", "feeAmountFinanced"],
  },
];

export const GOVT_FEE_FINANCED_AMOUNT = [
  {
    values: ["standardProducts"],
  },
  {
    path: "loanInformation",
    values: ["feeAmount", "feeAmountPaidinCash"],
  },
];
