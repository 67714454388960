import React, { useState, useEffect, memo, useMemo, Fragment } from "react";
import { shape } from "prop-types";
import { isEmpty, cloneDeep } from "lodash";
import styled from "styled-components";
import { Grid } from "@elliemae/ds-grid";
import { DSHeader } from "@elliemae/ds-header";
import { WarningTriangle } from "@elliemae/ds-icons";
import { DSSeparator } from "@elliemae/ds-separator";
import { DSButton, BUTTON_VARIANT } from "@elliemae/ds-button";
import { DSSingleComboBox } from "@elliemae/ds-form-single-combobox";
import { DSFormLayoutBlockItem } from "@elliemae/ds-form-layout-blocks";
import { CHECKBOX_VARIANT } from "@elliemae/ds-form";
import { getZipLocations, getStateCounties } from "services/searchFormServices";
import { isShallowEqual, zeroPad } from "utils/shared";
import { useSelector, useDispatch } from "react-redux";
import { useOriginalLoanInformationSelector } from "data/useSelectors";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import { openGlobalErrorModalAction } from "data/globalErrorModal/actionCreators";
import { searchFormActions } from "store/searchForm";
import { v4 as uuidv4 } from "uuid";
import {
  ComboBoxForm,
  ControlledCheckboxForm,
  InputMaskForm,
  TextBoxForm,
  ZipCodeSearchForm,
  GridForm,
} from "components/Form";
import {
  useLookupOptions,
  useLoanDataFieldSelector,
  useSearchFormSelector,
} from "components/Form/utils/customHooks";
import { setLoanDataValue } from "store/searchForm/actions";
import { GridItemCheckboxWrapper } from "components/WrapContainer/styled";
import { DSAccordion, DSAccordionItem } from "@elliemae/ds-accordion";
import {
  newOtherRealEstate,
  paths,
  pathValues,
  otherRealEstateFieldsToCheck,
} from "./utils/constants";
import OtherRealState from "./OtherRealState";

const EmptyRealStateMessage = styled.div`
  margin-top: 16px;
  margin-bottom: 60px;
  text-align: center;
`;

const opts = {
  allowDecimal: false,
  integerLimit: 9,
  allowNegative: false,
};

const SubjectPropertyInformationForm = ({ loanInfo: loanData }) => {
  const [options, setOptions] = useState([]);
  const propertyCounty = useLoanDataFieldSelector("property.county");
  const propertyState = useLoanDataFieldSelector("property.state");
  const states = useLookupOptions("states");
  const [countyOptions, setCountyOptions] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [countyPlaceholder, setCountyPlaceholder] = useState("Please select");
  const [active, setActive] = useState(0);
  const [validateForm, setValidateForm] = useState(false);
  const errorFields = useSearchFormSelector("errorForm.errorFields").value;
  const print2003Application = useOriginalLoanInformationSelector(
    "print2003Application",
    ""
  );
  const dispatch = useDispatch();
  const otherRealEstateOwned = useLoanDataFieldSelector([
    paths.OTHER_REAL_ESTATE_OWNED,
  ]);
  const handleSetLoanDataValue = ({ value, pathValue }) => {
    const parseValue = ["", undefined].includes(value) ? null : value;
    dispatch(setLoanDataValue(parseValue, pathValue));
  };

  const { msa, countyCode, stateCode, censusTract } = useLoanDataFieldSelector([
    paths.PROPERTY_MSA,
    paths.PROPERTY_COUNTY_CODE,
    paths.PROPERTY_STATE_CODE,
    paths.PROPERTY_CENSUS_TRACT,
  ]);

  const getGeocodes =
    useSelector(
      ({ eppsData: { lookups = {} } }) => lookups.data.geocodes,
      isShallowEqual
    ) || {};

  const originalRealEstate = useSearchFormSelector([
    paths.IS_OTHER_REAL_ESTATE,
  ]);

  const originalRealEstateLength = useMemo(
    () => originalRealEstate?.initialOtherRealEstateOwned?.length || 0
  );

  const checkDisabledFields = (index) => {
    const disabledFields = [];
    const {
      initialOtherRealEstateOwned: originalRealEstateOwned,
    } = originalRealEstate;
    const estate = originalRealEstateOwned?.[index];
    if (estate) {
      Object.keys(estate).forEach((key) => {
        if (otherRealEstateFieldsToCheck.includes(key)) {
          if (![null, undefined, 0].includes(estate[key])) {
            disabledFields.push(key);
          }
        }
      });
    }
    return disabledFields;
  };

  const isEditable = () => {
    let isAnyEditable = false;
    if (originalRealEstateLength) {
      originalRealEstate.initialOtherRealEstateOwned.forEach(
        (estate, index) => {
          if (!isAnyEditable) {
            const disabledFields = checkDisabledFields(index);
            isAnyEditable = disabledFields.length < 4;
          }
        }
      );
    }
    return isAnyEditable;
  };

  const otherRealEstateArray = useLoanDataFieldSelector(
    pathValues.OTHER_REAL_ESTATE_OWNED
  );

  const addRealEstate = () => {
    const oldRealEstateArray = otherRealEstateArray.value;

    if (oldRealEstateArray && oldRealEstateArray.length < 4) {
      const newRealEstateArray = cloneDeep(oldRealEstateArray);
      newRealEstateArray.push(newOtherRealEstate());

      handleSetLoanDataValue({
        value: newRealEstateArray,
        pathValue: pathValues.OTHER_REAL_ESTATE_OWNED,
      });
    } else {
      dispatch(
        openGlobalErrorModalAction({
          errorMessage:
            "You have reached the limit for max number of real estates",
        })
      );
    }
  };

  const removeRealEstate = (index) => {
    const oldRealEstateArray = otherRealEstateArray.value;

    if (!isEmpty(oldRealEstateArray)) {
      const newRealEstateArray = cloneDeep(oldRealEstateArray);
      newRealEstateArray.splice(index, 1);
      handleSetLoanDataValue({
        value: newRealEstateArray,
        pathValue: pathValues.OTHER_REAL_ESTATE_OWNED,
      });
    }
  };

  const getOtherRealEstateOwned = () => {
    let estates = null;
    const { otherRealEstateOwned: otherRealEstate } =
      otherRealEstateOwned || {};
    if (otherRealEstate && !isEmpty(otherRealEstate)) {
      const editable = isEditable();
      estates = otherRealEstate.map((estate, index) => {
        const disabledFields = checkDisabledFields(index);
        return (
          <Grid cols={1} gutter="16px">
            <Grid span={1}>
              <DSBanner
                body="Any amounts added to the section will need to be manually added to your loan origination system."
                isOpen={
                  (editable ||
                    originalRealEstateLength !== otherRealEstate.length) &&
                  index === 0
                }
                label="Other Real Estate Owned Amounts"
                type={BANNER_TYPES.WARNING}
                showCloseButton={false}
              />
            </Grid>
            <Grid span={1}>
              <OtherRealState
                index={index}
                estate={estate}
                isLastIndex={index === otherRealEstate.length - 1}
                removeRealEstate={removeRealEstate}
                originalRealEstateLength={originalRealEstateLength}
                disabledFields={disabledFields}
              />
            </Grid>
          </Grid>
        );
      });
    } else {
      return (
        <EmptyRealStateMessage>
          <WarningTriangle
            size="m"
            color={["brand-primary", "600"]}
            style={{ margin: "0 auto" }}
          />
          <p>No other properties have been added yet.</p>
        </EmptyRealStateMessage>
      );
    }
    return estates;
  };

  const getZips = async (newZip) => {
    setOptions([{ label: "Loading...", value: "" }]);
    const locations = await getZipLocations(newZip);
    if (locations && Array.isArray(locations) && locations.length > 0) {
      const newOpts = locations.map((loc) => ({
        label: `${loc.city}, ${loc.abbrev} - ${loc.county}`,
        value: newZip,
        state: loc.abbrev,
        county: loc.county,
        city: loc.city,
      }));
      setOptions(newOpts);
    } else {
      setOptions([]);
    }
  };

  const getCounties = async (stateId) => {
    const counties = await getStateCounties(stateId);
    if (counties && Array.isArray(counties) && counties.length > 0) {
      const newOpts = counties.map((county) => ({
        dsId: `counties-${uuidv4()}`,
        type: "option",
        label: county.key,
        value: county.value,
      }));
      const sortedCounties = newOpts.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
      setCountyOptions(sortedCounties);
    } else {
      setCountyOptions([]);
    }
  };

  const validCondos = [5, 6, 7, 12];

  const hideCondos = ({ customDependencies }) =>
    !validCondos.includes(customDependencies.type);
  const hide2003ApplicationFields = ({ customDependencies }) => {
    return !customDependencies.print2003Application === "2020";
  };

  const hideSecondLienPositionFields = ({ customDependencies }) => {
    return customDependencies.lienPosition === 2;
  };

  const showHideCondos = (value) => {
    const isValidCondo = validCondos.includes(value);

    if (!isEmpty(loanData) && !isValidCondo) {
      const { loanInformation } = loanData;
      const setCondotelValue = loanData.condotel
        ? false
        : loanInformation.condotel;
      const setNoWarrantableCondoValue = loanInformation.non_warrantable_Condo
        ? false
        : loanInformation.non_warrantable_Condo;
      handleSetLoanDataValue({
        value: setCondotelValue,
        pathValue: pathValues.CONDOTEL,
      });
      handleSetLoanDataValue({
        value: setNoWarrantableCondoValue,
        pathValue: pathValues.NON_WARRANTABLE_CONDO,
      });
    }
  };

  useEffect(() => {
    if (
      propertyState.value !== null &&
      propertyCounty.value !== null &&
      Array.isArray(states)
    ) {
      const { stateID } = states.find(
        (state) => state.value === propertyState.value
      );
      getCounties(stateID);
    }
    if (propertyState.value === null) {
      setCountyPlaceholder("Select a State");
    } else {
      setCountyPlaceholder("Please select");
    }
  }, [propertyState, propertyCounty]);

  useEffect(() => {
    setSelectedCounty(
      countyOptions.find((county) => county.label === propertyCounty.value)
    );
  }, [countyOptions]);

  useEffect(() => {
    if (!validateForm && errorFields && errorFields.length > 0) {
      setValidateForm(true);
      if (
        [
          "propertyTaxes",
          "insuranceAmount",
          "associationFee",
          "rentalIncome",
        ].some((field) => errorFields.includes(field))
      ) {
        setActive(0);
        dispatch(searchFormActions.setBannerIsOpen(true));
      } else {
        setActive(null);
        dispatch(searchFormActions.setBannerIsOpen(false));
      }
    }
  }, [validateForm, errorFields]);

  return (
    <Fragment>
      <DSHeader
        mb="xxs"
        text="Subject Property Information"
        fontWeight="bold"
        fontSize="16px"
        data-testid="SubjectPropertyInformationForm-all-components"
        style={{ marginTop: 20 }}
      />
      <Grid cols={["16fr", "1fr", "16fr"]}>
        <Grid>
          <Grid
            cols={1}
            span={2}
            gutter="xxs"
            style={{ marginRight: "-150px" }}
          >
            <Grid rows={["1fr", "1fr"]} backgroundColor="neutral-000">
              <Grid cols={1} span={2} gutter="xxs">
                <Grid rows={["fr"]} backgroundColor="neutral-000">
                  <Grid cols={2} gutter="xxs">
                    <Grid cols={1} span={1} gutter="xxs">
                      <GridForm span={1}>
                        <ComboBoxForm
                          floatingLabel
                          isNonClearable
                          label="Property Type"
                          name="propertyTypes"
                          onChangeV2={({ value, pathValue }) => {
                            handleSetLoanDataValue({ value, pathValue });
                            showHideCondos(value);
                          }}
                          pathValue={pathValues.PROPERTY_TYPE}
                          placeholder="Select Property Type"
                          required
                        />
                      </GridForm>
                    </Grid>
                    <Grid cols={1} span={1} gutter="xxs">
                      <GridForm span={1}>
                        <ComboBoxForm
                          floatingLabel
                          label="Occupancy Type"
                          isNonClearable
                          name="propertyUse"
                          onChangeV2={({ value, pathValue }) => {
                            handleSetLoanDataValue({ value, pathValue });
                          }}
                          optionsFilter={(optionsList) =>
                            optionsList?.filter((use) => use.value !== 4)
                          }
                          pathValue={pathValues.PROPERTY_USE}
                          placeholder="Select Occupancy Type"
                          required
                        />
                      </GridForm>
                    </Grid>
                  </Grid>
                  <Grid cols={1} span={2} gutter="xxs">
                    <Grid rows={["fr"]} backgroundColor="neutral-000" />
                    <Grid cols={3} gutter="xxs">
                      <Grid cols={1} span={1} gutter="xxs">
                        <GridForm span={1.2}>
                          <GridItemCheckboxWrapper>
                            <ControlledCheckboxForm
                              label="Rural/Unique"
                              pathValue={pathValues.RURAL}
                              variant={CHECKBOX_VARIANT.DEFAULT}
                              name="rural"
                              onChange={({ value, pathValue }) => {
                                handleSetLoanDataValue({ value, pathValue });
                              }}
                            />
                          </GridItemCheckboxWrapper>
                        </GridForm>
                      </Grid>
                      <Grid cols={1} span={1} gutter="xxs">
                        <GridForm
                          span={1.2}
                          hidden={hideCondos}
                          dependencies={[paths.PROPERTY_TYPE]}
                        >
                          <GridItemCheckboxWrapper>
                            <ControlledCheckboxForm
                              label="Non warrantable condo"
                              pathValue={pathValues.NON_WARRANTABLE_CONDO}
                              variant={CHECKBOX_VARIANT.DEFAULT}
                              name="nonWarrantableCondo"
                              onChange={({ value, pathValue }) => {
                                handleSetLoanDataValue({ value, pathValue });
                              }}
                            />
                          </GridItemCheckboxWrapper>
                        </GridForm>
                      </Grid>
                      <Grid cols={1} span={1} gutter="xxs">
                        <GridForm
                          span={1.2}
                          hidden={hideCondos}
                          dependencies={[paths.PROPERTY_TYPE]}
                        >
                          <GridItemCheckboxWrapper>
                            <ControlledCheckboxForm
                              label="Condotel"
                              pathValue={pathValues.CONDOTEL}
                              variant={CHECKBOX_VARIANT.DEFAULT}
                              name="condotel"
                              onChange={({ value, pathValue }) => {
                                handleSetLoanDataValue({ value, pathValue });
                              }}
                            />
                          </GridItemCheckboxWrapper>
                        </GridForm>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <grid>
          <DSSeparator
            dashed
            margin="s"
            position="initial"
            orientation="vertical"
            lineHeight={1}
            style={{ justifyContent: "center", marginTop: "10px" }}
          />
        </grid>
        <Grid>
          <Grid
            cols={1}
            span={2}
            gutter="xxs"
            style={{
              marginLeft: "0px",
              paddingLeft: "20px",
            }}
          >
            <Grid rows={["1fr", "1fr", "1fr"]} backgroundColor="neutral-000">
              <Grid cols={1} span={2} gutter="xxs">
                <Grid rows={["fr"]} backgroundColor="neutral-000">
                  <Grid cols={1} gutter="xxs">
                    <Grid cols={1} span={1} gutter="xxs">
                      <GridForm span={print2003Application === "2020" ? 2 : 4}>
                        <TextBoxForm
                          label="Street Address"
                          name="streetAddress"
                          onBlur={({ value, pathValue }) => {
                            handleSetLoanDataValue({ value, pathValue });
                          }}
                          pathValue={pathValues.STREET_ADDRESS}
                        />
                      </GridForm>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid cols={1} span={2} gutter="xxs">
                <Grid rows={["fr"]} backgroundColor="neutral-000" />
                <Grid cols={2} gutter="xxs">
                  <Grid cols={1} span={1} gutter="xxs">
                    <GridForm
                      span={2}
                      hidden={hide2003ApplicationFields}
                      originalLoanInfoDependencies={[
                        paths.PRINT_2003_APPLICATION,
                      ]}
                    >
                      <ComboBoxForm
                        floatingLabel
                        label="Unit Type"
                        isNonClearable
                        name="unitTypes"
                        onChangeV2={({ value, pathValue }) => {
                          handleSetLoanDataValue({ value, pathValue });
                        }}
                        pathValue={pathValues.UNIT_TYPE_ID}
                      />
                    </GridForm>
                  </Grid>
                  <Grid cols={1} span={1} gutter="xxxs">
                    <GridForm
                      span={1}
                      hidden={hide2003ApplicationFields}
                      originalLoanInfoDependencies={[
                        paths.PRINT_2003_APPLICATION,
                      ]}
                    >
                      <TextBoxForm
                        label="Unit Number"
                        name="unitNumber"
                        alphanumeric
                        onBlur={({ value, pathValue }) => {
                          handleSetLoanDataValue({ value, pathValue });
                        }}
                        pathValue={pathValues.UNIT_NUMBER}
                      />
                    </GridForm>
                  </Grid>
                </Grid>
              </Grid>
              <Grid cols={1} span={2} gutter="xxs">
                <Grid rows={["fr"]} backgroundColor="neutral-000" />
                <Grid cols={2} gutter="xxs">
                  <Grid cols={1} span={1} gutter="xxs">
                    <Grid rows={["fr"]} backgroundColor="neutral-000">
                      <Grid cols={2} gutter="xxs">
                        <Grid cols={1} span={1} gutter="xxs">
                          <GridForm span={1}>
                            <ZipCodeSearchForm
                              extraInputProps={{
                                options,
                              }}
                              options={options}
                              label="Zip Code"
                              name="zipCode"
                              onSearch={(v) => {
                                if (v.length === 5 || v.length === 10) {
                                  getZips(v);
                                } else {
                                  setOptions([]);
                                }
                              }}
                              onChange={(item) => {
                                if (item.value) {
                                  handleSetLoanDataValue({
                                    value: item.state,
                                    pathValue: pathValues.STATE,
                                  });
                                  handleSetLoanDataValue({
                                    value: item.county,
                                    pathValue: pathValues.COUNTY,
                                  });
                                  handleSetLoanDataValue({
                                    value: item.city,
                                    pathValue: pathValues.CITY,
                                  });
                                }
                              }}
                              dependencies={[
                                paths.PROPERTY_STATE,
                                paths.PROPERTY_CITY,
                                paths.PROPERTY_COUNTY,
                              ]}
                              pathValue={pathValues.ZIP}
                              selectRightOptions="Please select the appropriate City, State and County."
                            />
                          </GridForm>
                        </Grid>
                        <Grid cols={1} span={1} gutter="xxs">
                          <GridForm span={1}>
                            <ComboBoxForm
                              containerProps={{ id: "propertyState-focus" }}
                              extraInputProps={{
                                customMenuItemOptions: {
                                  useTruncatedText: true,
                                },
                              }}
                              required
                              name="states"
                              label="State"
                              isNonClearable
                              pathValue="property.state"
                              placeholder="Please select"
                              onChangeV2={(data) => {
                                if (data.value !== undefined) {
                                  const { stateID } = data.options.find(
                                    (item) => item.value === data.value
                                  );
                                  getCounties(stateID);
                                  setSelectedCounty(null);
                                  handleSetLoanDataValue({
                                    value: null,
                                    pathValue: pathValues.COUNTY,
                                  });
                                  handleSetLoanDataValue({
                                    value: data.value,
                                    pathValue: pathValues.STATE,
                                  });
                                }
                              }}
                              dependencies={[
                                paths.PROPERTY_ZIP,
                                paths.PROPERTY_STATE,
                                paths.PROPERTY_STATE_CODE,
                              ]}
                            />
                          </GridForm>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid cols={1} span={1} gutter="xxs">
                    <GridForm span={2}>
                      <TextBoxForm
                        containerProps={{
                          "data-testid": "epps-field-city",
                        }}
                        label="City"
                        name="city"
                        pathValue={pathValues.CITY}
                        dependencies={[paths.PROPERTY_ZIP, paths.PROPERTY_CITY]}
                        onChange={(item) => {
                          handleSetLoanDataValue({
                            value: item.value,
                            pathValue: pathValues.CITY,
                          });
                        }}
                      />
                    </GridForm>
                  </Grid>
                </Grid>
              </Grid>
              <Grid cols={1} span={2} gutter="xxs">
                <Grid rows={["fr"]} backgroundColor="neutral-000" />
                <Grid cols={2} gutter="xxs">
                  <Grid cols={1} span={1} gutter="xxs">
                    <GridForm
                      span={1}
                      hidden={hide2003ApplicationFields}
                      originalLoanInfoDependencies={[
                        paths.PRINT_2003_APPLICATION,
                      ]}
                    >
                      <DSFormLayoutBlockItem label="County" inputID="County">
                        <DSSingleComboBox
                          name="county"
                          containerProps={{ id: "County" }}
                          dependencies={[
                            paths.PROPERTY_ZIP,
                            paths.PROPERTY_COUNTY,
                          ]}
                          extraInputProps={{
                            customMenuItemOptions: { useTruncatedText: true },
                          }}
                          isNonClearable
                          required
                          allOptions={countyOptions || []}
                          selectedValue={selectedCounty || null}
                          placeholder={countyPlaceholder}
                          onChange={(item) => {
                            setSelectedCounty(item);
                            handleSetLoanDataValue({
                              value: item.label,
                              pathValue: pathValues.COUNTY,
                            });
                          }}
                        />
                      </DSFormLayoutBlockItem>
                    </GridForm>
                  </Grid>
                  <Grid cols={1} span={1} gutter="xxs" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DSAccordion
        activeValue={active}
        onChange={setActive}
        style={{ marginBottom: 100, marginTop: 10 }}
      >
        <DSAccordionItem
          title={active === 0 ? "Hide Detailed Entry" : "Open Detailed Entry"}
          key={0}
          value={0}
        >
          <Grid rows={["1fr"]} backgroundColor="neutral-000">
            <Grid cols={4} gutter="xxs">
              <GridForm
                span={4}
                cols={4}
                gutter="16px"
                hidden={hideSecondLienPositionFields}
                dependencies={[paths.LIEN_POSITION]}
              >
                <GridForm span={1}>
                  <InputMaskForm
                    clearable
                    containerProps={{ id: "propertyTax-focus" }}
                    extraInputProps={{
                      customMenuItemOptions: { useTruncatedText: true },
                    }}
                    maskOpts={opts}
                    label="Annual Property Taxes"
                    name="propertyTaxes"
                    onKeyUp={({ value, pathValue }) => {
                      handleSetLoanDataValue({ value, pathValue });
                    }}
                    pathValue={pathValues.TAX}
                    placeholder="$0"
                    required
                  />
                </GridForm>
                <GridForm span={1}>
                  <InputMaskForm
                    containerProps={{ id: "propertyInsuranceAmount-focus" }}
                    clearable
                    label="Annual Homeowners Insurance"
                    maskOpts={opts}
                    name="insuranceAmount"
                    onKeyUp={({ value, pathValue }) => {
                      handleSetLoanDataValue({ value, pathValue });
                    }}
                    pathValue={pathValues.INSURANCE_AMOUNT}
                    placeholder="$0"
                    required
                  />
                </GridForm>
                <GridForm span={1}>
                  <InputMaskForm
                    containerProps={{ id: "propertyAssociationFee-focus" }}
                    clearable
                    label="Monthly Association Fees"
                    maskOpts={opts}
                    name="associationFee"
                    onKeyUp={({ value, pathValue }) => {
                      handleSetLoanDataValue({ value, pathValue });
                    }}
                    pathValue={pathValues.ASSOCIATION_FEE}
                    placeholder="$0"
                    required
                  />
                </GridForm>
                <Grid span={1}>
                  <InputMaskForm
                    clearable
                    containerProps={{ id: "propertyRentalIncome-focus" }}
                    label="Monthly Rental Income"
                    maskOpts={opts}
                    name="rentalIncome"
                    onKeyUp={({ value, pathValue }) => {
                      handleSetLoanDataValue({ value, pathValue });
                    }}
                    pathValue={pathValues.RENTAL_INCOME}
                    placeholder="$0"
                    required
                  />
                </Grid>
              </GridForm>
            </Grid>
          </Grid>
          <DSSeparator dashed position="initial" />
          <Grid cols={4} gutter="xxs">
            <Grid span={1}>
              <span style={{ display: "block", marginBottom: "10px" }}>
                MSA
              </span>
              <b>{!isEmpty(getGeocodes) ? getGeocodes.msa : msa}</b>
            </Grid>
            <Grid span={1}>
              <span style={{ display: "block", marginBottom: "10px" }}>
                Census Tract
              </span>
              <b>
                {!isEmpty(getGeocodes)
                  ? zeroPad(
                      getGeocodes.censusTract
                        ? String(getGeocodes.censusTract)
                        : ""
                    )
                  : zeroPad(censusTract)}
              </b>
            </Grid>
            <Grid span={1}>
              <span style={{ display: "block", marginBottom: "10px" }}>
                County Code
              </span>
              <b>
                {!isEmpty(getGeocodes) ? getGeocodes.countyCode : countyCode}
              </b>
            </Grid>
            <Grid span={1}>
              <span style={{ display: "block", marginBottom: "10px" }}>
                State Code
              </span>
              <b>{!isEmpty(getGeocodes) ? getGeocodes.stateCode : stateCode}</b>
            </Grid>
          </Grid>
          <DSSeparator dashed position="initial" />
          <Grid cols={8} alignItems="center" style={{ marginBottom: 8 }}>
            <Grid span={7}>
              <DSHeader
                text="Other Real Estate Owned"
                fontSize={12}
                fontWeight="bold"
                lineHeight={1}
              />
            </Grid>
            <Grid span={1}>
              <DSButton
                id="realEstate"
                buttonType="secondary"
                labelText="Add Real Estate"
                size="m"
                variant={BUTTON_VARIANT.DEFAULT}
                onClick={() => addRealEstate()}
              />
            </Grid>
          </Grid>
          {getOtherRealEstateOwned()}
        </DSAccordionItem>
      </DSAccordion>
    </Fragment>
  );
};

SubjectPropertyInformationForm.defaultProps = {
  loanInfo: {},
};

SubjectPropertyInformationForm.propTypes = {
  loanInfo: shape({}),
};

export default memo(SubjectPropertyInformationForm);
