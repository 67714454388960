import React, { useState } from "react";
import { DSButton } from "@elliemae/ds-button";
import { useDispatch } from "react-redux";
import {
  DSTextBox,
  DSFormItemLayout,
  DSInputProtected,
} from "@elliemae/ds-form";
import { DSBanner, BANNER_TYPES } from "@elliemae/ds-banner";
import logo from "common/components/themes/vanilla/images/login-title.jpg";
import getLogin from "./LoginActions";
import * as Styled from "./styled";

function Login() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState();
  const [password, setPassword] = useState();
  const [showBanner, setShowBanner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userNameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleClick = async () => {
    if (!userName) setUsernameError(true);
    if (!password) setPasswordError(true);
    if (userName && password) {
      const result = await dispatch(getLogin({ userName, password }));
      if (!result.token) {
        setShowBanner(true);
        setErrorMessage(result);
      }
    }
  };

  return (
    <Styled.StyledLoginBody data-testid="Login-all-components">
      <Styled.StyledLoginBannerContainer>
        <DSBanner
          body={errorMessage}
          isOpen={showBanner}
          label="Error"
          onClose={() => {}}
          type={BANNER_TYPES.DANGER}
        />
      </Styled.StyledLoginBannerContainer>
      <Styled.StyledLoginContainer>
        <Styled.StyledLoginLogo>
          <img title="EPPS" alt="EPPS" src={logo} />
        </Styled.StyledLoginLogo>
        <Styled.StyledLoginForm>
          <Styled.StyledLoginFormInput>
            <DSFormItemLayout
              ariaLabel="userName"
              required
              inputComponent={DSTextBox}
              labelText="User Name"
              onChange={(e) => {
                const { value } = e.target;
                setUsername(value);
                if (userNameError) setUsernameError(false);
              }}
              name="userName"
              placeholder="Enter userName"
              value={userName}
              hasError={userNameError}
            />
          </Styled.StyledLoginFormInput>
          <Styled.StyledLoginFormInput>
            <DSFormItemLayout
              required
              inputComponent={DSInputProtected}
              labelText="Password"
              onChange={(e) => {
                const { value } = e.target;
                setPassword(value);
                if (passwordError) setPasswordError(false);
              }}
              placeholder="Enter password"
              value={password}
              hasError={passwordError}
            />
          </Styled.StyledLoginFormInput>
          <Styled.StyledLoginFormBotton>
            <DSButton
              id="login"
              buttonType="primary"
              labelText="Log In"
              size="m"
              onClick={handleClick}
            />
          </Styled.StyledLoginFormBotton>
        </Styled.StyledLoginForm>
      </Styled.StyledLoginContainer>
    </Styled.StyledLoginBody>
  );
}

export default Login;
