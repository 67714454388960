import { fork } from "redux-saga/effects";
import searchForm from "./searchForm/sideEffects";
import lookups from "./lookups/sideEffects";

function* rootSaga() {
  yield fork(lookups);
  yield fork(searchForm);
}

export default rootSaga;
