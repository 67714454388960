import React from "react";
import {
  LockUnlockedSolid,
  LockLockedSolid,
  LockCancelled,
} from "@elliemae/ds-icons";
import { useLoanDataSelector } from "data/useSelectors";

export const getLookupValue = (lookup, type, defaultValue) => {
  if (!lookup) return defaultValue;
  const item = lookup.filter((elem) => elem.value === type);
  return item ? item[0]?.key || item[0]?.label : defaultValue;
};

// this logic will change once we get proper information for lock status
export const getLockStatusDetails = () => {
  const { requestLockStatus } = useLoanDataSelector();

  const lockedCommonProps = { style: { paddingTop: "4px" }, size: "m" };

  const unlockedProps = {
    color: ["warning", "600"],
    ...lockedCommonProps,
  };
  const lockedProps = { color: ["brand", "500"], ...lockedCommonProps };
  const lockExpiredProps = { color: ["danger", "900"], ...lockedCommonProps };
  const lockedCancelledProps = {
    color: ["neutral", "200"],
    ...lockedCommonProps,
  };

  switch (requestLockStatus) {
    case 1:
      return {
        lockStatusValue: "Not Locked",
        lockIcon: <LockUnlockedSolid {...unlockedProps} />,
      };

    case 2:
      return {
        lockStatusValue: "Locked",
        lockIcon: <LockLockedSolid {...lockedProps} />,
      };

    case 3:
      return {
        lockStatusValue: "Expired",
        lockIcon: <LockLockedSolid {...lockExpiredProps} />,
      };

    case 4:
      return {
        lockStatusValue: "Lock Cancelled",
        lockIcon: <LockCancelled {...lockedCancelledProps} />,
      };

    default:
      return {
        lockStatusValue: "Voided",
        lockIcon: <LockCancelled {...lockedCancelledProps} />,
      };
  }
};
