import styled from "styled-components";

export const PricingColumnTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PricingColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ isSlide }) => (isSlide ? `0px` : `10px`)};
  width: ${({ isSlide }) => (isSlide ? `auto` : `100%`)};
`;

export const ProgramNameStyled = styled.h4`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 16px;
`;

export const MarginColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ isSlide }) => (isSlide ? `0px` : `10px`)};
  width: ${({ isSlide }) => (isSlide ? `auto` : `100%`)};
`;

export const StyledTileGrids = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
  height: 40px;
  font-size: ${({ theme: { fontSizes = {} } = {} }) => fontSizes.title[600]};
  font-weight: ${({ theme: { fontWeights } = {} }) => fontWeights.semibold};
`;

export const StyledTileMarginGrids = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 5px;
  font-size: ${({ theme: { fontSizes = {} } = {} }) => fontSizes.title[600]};
  font-weight: ${({ theme: { fontWeights } = {} }) => fontWeights.semibold};
`;

export const TableStyled = styled.div`
  display: flex;
  flex-direction: ${({ isSlide }) => (isSlide ? "column" : "row")};
`;
