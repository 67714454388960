import React from "react";
import { LOCKDESK_MODAL_STATUS_TITLES } from "./constant";

export const getLockDeskData = (data, startTime, endTime) => {
  const result = data.map((card) => `${card[startTime]}-${card[endTime]} `);
  const defaultValue = startTime === "onrpStartTime" ? "None" : "Closed";
  return result.length > 0 ? result : defaultValue;
};

export const getWeekDay = () => {
  return new Date().toLocaleString("en-US", {
    timeZone: "America/New_York",
    weekday: "long",
  });
};

const checkOnrpBgColour = (timeToClose, timeInRange) => {
  if (timeToClose && timeInRange.isOnrp) {
    return true;
  }
  return false;
};

const checkLockDeskBgColour = (timeToClose, timeInRange, lockData) => {
  if (lockData === "Closed") {
    return true;
  }
  if (timeToClose && !timeInRange.isOnrp) {
    return true;
  }
  return false;
};

export const getHiddenColumn = (lockData) => {
  const hiddenColumn = ["rateProtection"];
  if (lockData) {
    const isOnrpTrue = lockData.find(
      (lockDesk) => lockDesk.isOnrpTrue === true
    );
    if (isOnrpTrue) {
      return [];
    }
  }
  return hiddenColumn;
};

export const normalizeLockDeskData = (lockDeskTimingsPerDay) => {
  let result = null;
  if (lockDeskTimingsPerDay) {
    result = lockDeskTimingsPerDay.map((card) => ({
      day: card.dayofWeek,
      lockDesk: getLockDeskData(
        card.lockDeskHoursTimings,
        "lockDeskStartTime",
        "lockDeskEndTime"
      ),
      rateProtection: getLockDeskData(
        card.onrpTimings,
        "onrpStartTime",
        "onrpEndTime"
      ),
      isOnrpTrue: card.onrpTimings.length > 0,
    }));
  }
  return result;
};

export const getHeaderData = (timeToClose, timeInRange) => {
  if (timeToClose && !timeInRange.isOnrp) {
    return LOCKDESK_MODAL_STATUS_TITLES.open;
  }
  if (timeToClose && timeInRange.isOnrp) {
    return LOCKDESK_MODAL_STATUS_TITLES.openONRP;
  }
  return LOCKDESK_MODAL_STATUS_TITLES.close;
};

export const styleColumn = {
  display: "flex",
  alignItems: "center",
  height: "36px",
  width: "140px",
  paddingLeft: "5px",
};

export const getLockDeskColumn = (timeToClose, timeInRange) => {
  const customLockDeskColumn = {
    id: "lockDesk",
    Header: "Lock Desk",
    Cell: ({ row }) => {
      const todayDate = getWeekDay();
      let isTimeInLockDesk = false;
      if (todayDate === row.original.day) {
        isTimeInLockDesk = checkLockDeskBgColour(
          timeToClose,
          timeInRange,
          row.original.lockDesk
        );
      }
      return (
        <div
          style={{
            backgroundColor: isTimeInLockDesk && "#EBF6FF",
            ...styleColumn,
          }}
        >
          {row.original.lockDesk}
        </div>
      );
    },
  };
  return customLockDeskColumn;
};
export const getOnrpColumn = (timeToClose, timeInRange) => {
  const customOnrpColumn = {
    id: "rateProtection",
    accessor: "rateProtection",
    Header: "Rate Protection",
    Cell: ({ row }) => {
      const todayDate = getWeekDay();
      let isTimeInOnrp = false;
      if (todayDate === row.original.day) {
        isTimeInOnrp = checkOnrpBgColour(timeToClose, timeInRange);
      }
      return (
        <div
          style={{
            backgroundColor: isTimeInOnrp && "#EBF6FF",
            ...styleColumn,
          }}
        >
          {row.original.rateProtection}
        </div>
      );
    },
  };
  return customOnrpColumn;
};
