import React, { useEffect, useMemo, useState } from "react";
import { arrayOf, bool, func, shape } from "prop-types";
import { DataTable } from "@elliemae/ds-data-table";
import { useSearchFormSelector } from "components/Form/utils/customHooks";
import { columns } from "./utils/constants";

const BorrowersTable = ({
  rows,
  isIntegrationMode,
  handleSetLoanDataValue,
}) => {
  const [error, setError] = useState(false);
  const errorList = useSearchFormSelector("errorForm.errorFields").value || [];

  useEffect(() => {
    // TODO: workaround to re-render rows and display entire feedbackMessage
    if (!error && errorList.length) {
      const skipValidation = errorList.find(
        (el) => el.includes("liquidAsset") || el.includes("anualIncome")
      );
      setError(skipValidation);
    }
  }, [errorList]);

  const memoColumns = useMemo(() => columns, [error]);

  return (
    <DataTable
      autoHeight
      paginated
      data={rows}
      columns={memoColumns}
      colsLayoutStyle="auto"
      cellRendererProps={{
        handleSetLoanDataValue,
        isIntegrationMode,
      }}
      noResultsMessage="No Results Found"
      noResultsSecondaryMessage="You do not have any Programs at this time."
      rowKey="programID"
    />
  );
};

BorrowersTable.defaultProps = {
  rows: [],
  isIntegrationMode: false,
};

BorrowersTable.propTypes = {
  rows: arrayOf(shape({})),
  handleSetLoanDataValue: func.isRequired,
  isIntegrationMode: bool,
};

export default BorrowersTable;
