import { isEmpty } from "utils/shared";
import { v4 as uuidv4 } from "uuid";
import { itemsFormat } from "./constants";

export const optionBody = ({ id, lookup, ...rest }) => ({
  dsId: `${lookup}-${id || "none"}`,
  type: "option",
  ...rest,
});

export const transformOfficeUsers = (officeUsers) => {
  const result = [];
  let currentOffice = null;
  officeUsers.forEach((obj) => {
    if (obj.office !== currentOffice) {
      result.push({
        dsId: `officeUsers-${uuidv4() || "none"}`,
        type: "section",
        label: obj.office,
      });
      currentOffice = obj.office;
    }
    result.push(obj);
  });
  return result;
};

export const generateComboboxOptions = (lookup = "", options = []) => {
  const { props = {}, ...rest } = itemsFormat[lookup] || {};
  const noneItem = { lookup, label: "none", value: "none" };
  const comboboxOptions =
    Array.isArray(options) &&
    options.map((item) => {
      const extraProps = {};
      const label = `${item[props?.label] ?? item.label ?? item.key}`;
      let value = item[props?.value] ?? item.value ?? "none";

      if (
        [
          "bankruptcy",
          "foreclosure",
          "buydownType",
          "prepayPenaltyTerms",
          "buydownContributorType",
          "fee",
        ].includes(lookup) &&
        (item.value === 0 ||
          item.buydownTypeId === 0 ||
          item.buydownContributorTypeId === 0 ||
          item.feeValue === null)
      ) {
        value = 0;
      }

      Object.entries(rest?.extraProps || {}).forEach(([key, path]) => {
        extraProps[key] = item[path];
      });
      const nonFormat = rest?.nonFormat ? item : { label, value, id: uuidv4() };
      return optionBody({ lookup, ...extraProps, ...nonFormat });
    });
  if (rest.noneOption && Array.isArray(comboboxOptions))
    comboboxOptions.unshift(optionBody(noneItem));
  return comboboxOptions || [];
};

export const generateDocTypesComboboxOptions = ({
  key,
  documentationTypes,
  options,
}) => {
  const setOptions = [];
  if (!isEmpty(documentationTypes)) {
    const elements = [
      ...new Set(
        documentationTypes.map((el) => el[key] || (el[key] === 0 && 0))
      ),
    ];
    if (!isEmpty(elements)) {
      elements.map((el) => {
        const isDocumentOption = options[el];
        return isDocumentOption.map((opt) =>
          setOptions.push(optionBody({ id: opt.value, lookup: key, ...opt }))
        );
      });
    }
  }
  return setOptions;
};

export const generateOfficeUsersComboboxOptionsWithItemLayout = (
  lookup = "",
  options = []
) => {
  const { ...rest } = itemsFormat[lookup] || {};
  const noneItem = { lookup, label: "none", value: "none" };

  const comboboxOptions =
    Array.isArray(options) &&
    options.map((item) => {
      const extraProps = {};
      const label = `${item.firstName} ${item.lastName}`;
      const value = item.userId;
      const { login } = item;
      const { office } = item;
      Object.entries(rest?.extraProps || {}).forEach(([key, path]) => {
        extraProps[key] = item[path];
      });
      const nonFormat = rest?.nonFormat
        ? item
        : { label, value, login, office, id: uuidv4() };
      return optionBody({ lookup, ...extraProps, ...nonFormat });
    });
  if (rest.noneOption && Array.isArray(comboboxOptions))
    comboboxOptions.unshift(optionBody(noneItem));
  return transformOfficeUsers(comboboxOptions) || [];
};
