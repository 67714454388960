import React, { useEffect, useState } from "react";
import { arrayOf, string } from "prop-types";
import { DataTable } from "@elliemae/ds-data-table";
import { DSButton } from "@elliemae/ds-button";
import { currency } from "utils/shared";
import { useLoanDataSelector } from "data/useSelectors";
import moment from "moment-timezone";
import { loanLimitsGridColumns } from "./utils/constants";

const ExpandableLoanLimits = (props) => {
  const { data, programID } = props;
  const [rowData, setRowData] = useState([]);
  const { state, county } = useLoanDataSelector("property");
  const labelText = `Loan Limits for ${county}, ${state}`;
  useEffect(() => {
    if (data?.length) {
      const mappingData = data.map((row, index) => ({
        ...row,
        id: `${programID}-${index}`,
        effectiveDate: moment(row.effectiveDate).format("MM/DD/YYYY"),
        limit1: currency(row.limit1),
        limit2: currency(row.limit2),
        limit3: currency(row.limit3),
        limit4: currency(row.limit4),
      }));
      setRowData(mappingData);
    }
  }, []);

  return (
    <div style={{ padding: "33px 16px 44px 16px" }}>
      <DSButton
        buttonType="text"
        labelText={labelText}
        size="s"
        style={{
          textTransform: "none",
          color: "black",
          padding: "8px",
          fontSize: "16px",
          marginBottom: "10px",
        }}
      />
      <DataTable
        autoHeight
        data={rowData}
        colsLayoutStyle="auto"
        columns={loanLimitsGridColumns}
        rowKey="id"
      />
    </div>
  );
};

ExpandableLoanLimits.defaultProps = {
  data: [],
  programID: "",
};

ExpandableLoanLimits.propTypes = {
  data: arrayOf(),
  programID: string,
};

export default ExpandableLoanLimits;
